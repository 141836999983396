import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../interfaces/base';
import { IEditDailyRewardState } from '../../../interfaces/store/daily-rewards-interfaces';
import { ICreateDailyRewardBody, IGetDailyRewardParams, IUpdateDailyRewardBody } from '../../../interfaces/api/daily-rewards';

const initialState: IEditDailyRewardState = {
    dailyReward: null,
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanEditDailyRewardState = (state: IEditDailyRewardState) => {
    return initialState;
};


const _getDailyRewardStart = (state: IEditDailyRewardState, { payload }: PayloadAction<IGetDailyRewardParams>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start'; 
    state.operation = 'read';
}

const _getDailyRewardSuccess = (state: IEditDailyRewardState, { payload }: PayloadAction<any>) => {
    state.dailyReward = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _getDailyRewardFailed = (state: IEditDailyRewardState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const _updateDailyRewardStart = (state: IEditDailyRewardState, { payload }: PayloadAction<IUpdateDailyRewardBody>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'update';
}

const _updateDailyRewardSuccess = (state: IEditDailyRewardState, { payload }: PayloadAction<any>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _updateDailyRewardFailed = (state: IEditDailyRewardState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}


const _createDailyRewardStart = (state: IEditDailyRewardState, { payload }: PayloadAction<ICreateDailyRewardBody>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'create';
}

const _createDailyRewardSuccess = (state: IEditDailyRewardState, { payload }: PayloadAction<any>) => {
    state.dailyReward = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _createDailyRewardFailed = (state: IEditDailyRewardState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}



const EditDailyRewardSlice = createSlice({
    name: 'editDailyReward',
    initialState,
    reducers: {
        cleanEditDailyRewardState: _cleanEditDailyRewardState,
        getDailyRewardStart: _getDailyRewardStart,
        getDailyRewardSuccess: _getDailyRewardSuccess,
        getDailyRewardFailed: _getDailyRewardFailed,
        updateDailyRewardStart: _updateDailyRewardStart,
        updateDailyRewardSuccess: _updateDailyRewardSuccess,
        updateDailyRewardFailed: _updateDailyRewardFailed,
        createDailyRewardStart: _createDailyRewardStart,
        createDailyRewardSuccess: _createDailyRewardSuccess,
        createDailyRewardFailed: _createDailyRewardFailed,
    }
})

const { actions, reducer } = EditDailyRewardSlice;
export const {
    cleanEditDailyRewardState,
    getDailyRewardStart,
    getDailyRewardSuccess,
    getDailyRewardFailed,
    updateDailyRewardStart,
    updateDailyRewardSuccess,
    updateDailyRewardFailed,
    createDailyRewardStart,
    createDailyRewardSuccess,
    createDailyRewardFailed,
} = actions;

export default reducer;
