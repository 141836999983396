import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks';
import { authCheckStart } from '../store/features/auth-user/auth-user-slice';
import { FullPage } from '../components/common/containers';
import LoginNavigation from '../components/login/LoginNavigation';
import NavigationSwitch from '../components/navigation/NavigationSwitch';
import LoadingLogo from '../components/ui/LoadingLogo';
import PageLoader from '../components/ui/loaders/PageLoader';
import '../config';

function App() {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(state => state.auth);

  useEffect(() => {
    dispatch(authCheckStart())
  }, [dispatch]);

  let view = (
    <FullPage>
      <PageLoader />
    </FullPage>
  );

  if (auth.authenticated && !auth.onStartUp && auth.user) {
    view = (
      <Router>
        <NavigationSwitch />
      </Router>
    );
  }
  if (!auth.authenticated && !auth.onStartUp) {
    view = (
      <LoginNavigation />
    );
  }


  return (
    <>
      {view}
    </>
  );
}

export default App;
