import styled from 'styled-components';
import { INavProfileButtonProps } from '../../interfaces/components/navigation/navigation';
import { Avatar, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { NavLink } from 'react-router-dom';

export const TopNavHeight = 55;
export const TopNavVeticalPadding = 0;
export const TopNavFullHeight = TopNavHeight + (TopNavVeticalPadding * 2);

const TopNav = styled.nav`
	top: 0;
	left: 0;
	right: 0;
	z-index: 5;
	display: flex;
	flex-flow: row nowrap;
	padding: ${TopNavVeticalPadding + 'px 27px'};
	height:  ${TopNavHeight + 'px'};
	align-items: center;
	background: ${tokens.colorBrandForeground1};
`;

const NavLogo = styled.img`
	height: 28px;
	padding: 0px 25px;
	white-space: nowrap;
`;

const Container = styled.button`
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	background: transparent;
	border: none;
	padding: 0;
	& > * {
		margin: 0.25rem;
	}
	&:focus {
		outline: none;
	}
`;

const SideNav = styled.nav`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	height: 100%;
	min-width: 270px;
	background: white;
`;

const NavProfileButton = (props: INavProfileButtonProps) => {

	const imgUrl = props.user.avatar;
	return (
		<Container onClick={props.onclick}>
			<Avatar
				name={props.user.fullName}
				size={40}
				image={{
					src: imgUrl,
				}}
			/>
		</Container>
	);
}

const LogoContainer = styled.div`
	flex-grow: 1;
`
const NavBarContainer = styled.div`
	display: flex;
	overflow: hidden;
	transition: all 0.3s ease-in;
	flex-basis: auto;
	flex-grow: 3;
	flex-shrink: 1;
	height: 100%;
	width: 100%;
	padding: 0 3%;
`;

const NavBar = styled.ul`
	width: 100%;
	padding: 0;
    margin: 0;
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	list-style: none;
	height: 100%;
`;

const activeClassName = 'active';

const NavStyledNavLink = styled(NavLink)`
  &.${activeClassName}{
	> span{
		font-weight: 700;
	}
  }
`;

const NavItem = styled.li`
	margin-right: 40px;
	height: calc(100% - 3px); 
    align-items: center; 
    display: flex; 
	${NavStyledNavLink} {
		height: 100%;
    	display: flex;
    	align-items: center;
		padding: 0;
		text-decoration: none;
		color: white;
		font-size: 16px;
		line-height: 16px;
		font-family: InterUI-Medium
	}
	&:hover > ${NavStyledNavLink} {
		color: var(--neutral-30p);
	}
	
	@media(max-width:1360px) {
		margin-right: 22px;
		a {
			font-size: 16px;
		}
	}

`;


const navigation = {
	TopNav,
	NavLogo,
	NavProfileButton,
	SideNav,
	LogoContainer,
	NavBarContainer,
	NavBar,
	NavItem,
	NavStyledNavLink

};

export default navigation;