import React from 'react'
import logo from '../../assets/images/logo.png'
import { FullPage } from '../common/containers';
import LoginForm from './LoginForm';
import { Image } from '@fluentui/react-components';


const Login = () => {

    return (
        <FullPage style={{ minHeight: '100vh' }}>
            <LoginForm />
            <Image alt='logo' src={logo} width={357} height={357}  />
        </FullPage>
    )
}

export default Login;
