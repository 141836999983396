import axiosInstance from '../../config/axios';
import ServerRoutes from './routes';
import { getParamsUrl, handleAxiosError } from './utils/common';
import { IGetLibraryImageParams, IGetLibraryParams } from '../../interfaces/api/library';
import { IFileUpdate } from '../../interfaces/api/files';

const getLibrary = async (params: IGetLibraryParams) => {
    try {
        let url = getParamsUrl(ServerRoutes.files.files, params);
        const { status, data } = await axiosInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}


const getLibraryImageById = async (params: IGetLibraryImageParams) => {
    try {
        let url = getParamsUrl(ServerRoutes.files.getFile, params);
        const { status, data } = await axiosInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 404:
                message = 'File not found.';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}


const deleteLibraryImage = async (params: IGetLibraryImageParams) => {
    try {
        let url = getParamsUrl(ServerRoutes.files.deleteFile, params);
        const { status, data } = await axiosInstance.delete(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 404:
                message = 'File not found.';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}


const updateLibraryImage = async (params: IFileUpdate) => {
    try {
        const { status, data } = await axiosInstance.put(ServerRoutes.files.updateFile, params);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}


const libraryApi = {
    getLibrary, 
    getLibraryImageById,
    deleteLibraryImage,
    updateLibraryImage
}

export default libraryApi;