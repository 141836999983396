import { IWithPagination, IWithSort } from "./common";
import { Option } from "../../components/ui/Select";

export type ScenesSort = 'name' | 'createdAt';

export interface IGetScenesParams extends IWithPagination, IWithSort<ScenesSort> {
    search?: string;
}

export interface IGetSceneParams {
    sceneId: string;
}

export interface IScenesSortOption extends IWithSort<ScenesSort> { }


export const scenesSortOptions: Option<IScenesSortOption>[] = [
    { id: '1', label: 'Created At - Ascending', value: { sortBy: 'createdAt', sortDir: 'asc' } },
    { id: '2', label: 'Created At - Descending', value: { sortBy: 'createdAt', sortDir: 'desc' } },
    { id: '3', label: 'Name - Ascending', value: { sortBy: 'name', sortDir: 'asc' } },
    { id: '4', label: 'Name - Descending', value: { sortBy: 'name', sortDir: 'desc' } }
]

export interface ICreateSceneBody {
    name: string;
    serialNumber: number;
    imageBackgroundPath: string;
    imageCompletedPath: string;
    imageThumbnailPath: string;
    requiredDeviceUses: number;
}

export interface IUpdateSceneBody {
    id: string;
    data: {
        name: string;
        serialNumber: number;
        imageBackgroundPath: string;
        imageCompletedPath: string;
        imageThumbnailPath: string;
        requiredDeviceUses: number;
    };
}

//scene item

export interface IGetSceneItemParams {
    sceneItemId: string;
}

export interface IGetSceneItemsParams {
    sceneId: string;
}

export interface IRemoveSceneItemsParams {
    sceneId: string;
    sceneItemId: string;
}

export interface ICreateSceneItemBody {
    sceneId: string,
    sceneItem: {
        sceneId: string,
        name: string,
        price: number,
        serialNumber: number,
        imagePath: string,
        thumbnailPath: string,
        dependsOn: number,
        deliveryTime: number,
        // coordX?: number,
        // coordY?: number,
        // percentX?: number,
        // percentY?: number
    }
}

export interface IUpdateSceneItemBody {
    id: string;
    data: {
        sceneId: string,
        name: string,
        price: number,
        serialNumber: number,
        imagePath: string,
        thumbnailPath: string,
        dependsOn: number,
        deliveryTime: number,
        // coordX?: number,
        // coordY?: number,
        // percentX?: number,
        // percentY?: number
    };
}
