import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../interfaces/base';
import { IDevicesState } from '../../../interfaces/store/devices-interfaces';
import { IGetDevicesParams } from '../../../interfaces/api/devices';

const initialState: IDevicesState = {
    devices: [],
    metadata: {
        page: 1,
        total: 0
    },
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanDevicesState = (state: IDevicesState) => {
    return initialState;
};

const _fetchDevicesStart = (state: IDevicesState, { payload }: PayloadAction<{ params: IGetDevicesParams }>) => {
    state.metadata = {
        page: payload.params.page as number,
        total: state.metadata.total 
    };
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'read';
}

const _fetchDevicesSuccess = (state: IDevicesState, { payload }: PayloadAction<any>) => {
    state.devices = payload.data;
    state.metadata = { total: payload.metadata.total, page: state.metadata.page };
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _fetchDevicesFailed = (state: IDevicesState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const DevicesSlice = createSlice({
    name: 'devices',
    initialState,
    reducers: {
        cleanDevicesState: _cleanDevicesState,
        fetchDevicesStart: _fetchDevicesStart,
        fetchDevicesSuccess: _fetchDevicesSuccess,
        fetchDevicesFailed: _fetchDevicesFailed
    }
})

const { actions, reducer } = DevicesSlice;
export const {
    cleanDevicesState,
    fetchDevicesStart,
    fetchDevicesSuccess,
    fetchDevicesFailed
} = actions;

export default reducer;
