

export const getTimeFormat = (data: Date) => {
    try {
        return data.toTimeString().slice(0, 5)
    } catch (error) {
        return ''
    }
}
export const dateFormat = (date: Date, options?: { withTime?: boolean }) => {
    try {
        let res = date.toLocaleDateString('en-GB').replaceAll('/', '.');
        if (options?.withTime) {
            res += `\t${getTimeFormat(date)}`;
        }
        return res;
    } catch (error) {
        return ''
    }
}

export const formatDateTime = (dt: Date) => {
    try {
        const m = formatdigits(dt.getMonth() + 1);
        const d = formatdigits(dt.getDate());
        const y = formatdigits(dt.getFullYear());
        const date = `${m}/${d}/${y}`;
        const h = formatdigits(dt.getHours() > 12 ? dt.getHours() - 12 : dt.getHours());
        const min = formatdigits(dt.getMinutes());
        const s = formatdigits(dt.getSeconds());
        const time = `${h}:${min}:${s}`;
        const ampm = dt.getHours() > 11 ? "PM" : "AM";
        return `${date} ${time} ${ampm}`;
    } catch (error) {
        return ''
    }
}
const formatdigits = (val: string | number | any[]) => {
    val = val.toString();
    return val.length == 1 ? "0" + val : val;
}