import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../interfaces/base';
import { IEditBoxPrizeState } from '../../../interfaces/store/boxes-interfaces';
import { ICreateBoxPrizeBody, IGetBoxPrizeParams, IRemoveBoxPrizeParams } from '../../../interfaces/api/boxes';

const initialState: IEditBoxPrizeState = {
    prize: null,
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanEditBoxPrizeState = (state: IEditBoxPrizeState) => {
    return initialState;
};


const _getBoxPrizeStart = (state: IEditBoxPrizeState, { payload }: PayloadAction<IGetBoxPrizeParams>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'read';
}

const _getBoxPrizeSuccess = (state: IEditBoxPrizeState, { payload }: PayloadAction<any>) => {
    state.prize = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _getBoxPrizeFailed = (state: IEditBoxPrizeState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const _updateBoxPrizeStart = (state: IEditBoxPrizeState, { payload }: PayloadAction<any>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'update';
}

const _updateBoxPrizeSuccess = (state: IEditBoxPrizeState, { payload }: PayloadAction<any>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _updateBoxPrizeFailed = (state: IEditBoxPrizeState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}


const _createBoxPrizeStart = (state: IEditBoxPrizeState, { payload }: PayloadAction<ICreateBoxPrizeBody>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'create';
}

const _createBoxPrizeSuccess = (state: IEditBoxPrizeState, { payload }: PayloadAction<any>) => {
    state.prize = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _createBoxPrizeFailed = (state: IEditBoxPrizeState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}


const _deleteBoxPrizeStart = (state: IEditBoxPrizeState, { payload }: PayloadAction<IRemoveBoxPrizeParams>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'delete';
}

const _deleteBoxPrizeSuccess = (state: IEditBoxPrizeState, { payload }: PayloadAction<any>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _deleteBoxPrizeFailed = (state: IEditBoxPrizeState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const EditBoxPrizeSlice = createSlice({
    name: 'editBoxPrize',
    initialState,
    reducers: {
        cleanEditBoxPrizeState: _cleanEditBoxPrizeState,
        getBoxPrizeStart: _getBoxPrizeStart,
        getBoxPrizeSuccess: _getBoxPrizeSuccess,
        getBoxPrizeFailed: _getBoxPrizeFailed,
        updateBoxPrizeStart: _updateBoxPrizeStart,
        updateBoxPrizeSuccess: _updateBoxPrizeSuccess,
        updateBoxPrizeFailed: _updateBoxPrizeFailed,
        createBoxPrizeStart: _createBoxPrizeStart,
        createBoxPrizeSuccess: _createBoxPrizeSuccess,
        createBoxPrizeFailed: _createBoxPrizeFailed,
        deleteBoxPrizeStart: _deleteBoxPrizeStart,
        deleteBoxPrizeSuccess: _deleteBoxPrizeSuccess,
        deleteBoxPrizeFailed: _deleteBoxPrizeFailed,
    }
})

const { actions, reducer } = EditBoxPrizeSlice;
export const {
    cleanEditBoxPrizeState,
    getBoxPrizeStart,
    getBoxPrizeSuccess,
    getBoxPrizeFailed,
    updateBoxPrizeStart, 
    updateBoxPrizeSuccess, 
    updateBoxPrizeFailed, 
    createBoxPrizeStart, 
    createBoxPrizeSuccess, 
    createBoxPrizeFailed, 
    deleteBoxPrizeStart, 
    deleteBoxPrizeSuccess, 
    deleteBoxPrizeFailed,
} = actions;

export default reducer;
