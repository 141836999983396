import React, { FC } from 'react';
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Button,
} from "@fluentui/react-components";
import { useButtonStyles, useModalStyles } from '../../hooks/useStyles';


export interface IModalPopupProps {
    title: string;
    open: boolean;
    onOpenChange: Function;
    onActionClick: any;
    actionTitle: string;
    modalContent: any;
    disabledAction?: boolean;

};

const AlertPopup = (props: IModalPopupProps) => {

    const buttonClasses = useButtonStyles();
    const modalClasses = useModalStyles();

    return (
        <Dialog open={props.open} onOpenChange={(event, data) => props.onOpenChange(data.open)}>
            <DialogSurface className={modalClasses.wideModal}>
                <DialogBody className={modalClasses.container}>
                    <DialogTitle className={modalClasses.title}>{props.title}</DialogTitle>
                    <DialogContent>
                        {props.modalContent}
                    </DialogContent>
                    <DialogActions className={modalClasses.actions}>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Close</Button>
                        </DialogTrigger>
                        <Button appearance="primary" onClick={props.onActionClick} disabled={props.disabledAction || false}>{props.actionTitle}</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default AlertPopup;

