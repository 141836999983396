import axiosInstance from '../../config/axios';
import ServerRoutes from './routes';
import { getParamsUrl, handleAxiosError } from './utils/common';
import {
    ICreateDailyRewardBody,
    IGetDailyRewardParams,
    IUpdateDailyRewardBody,
} from '../../interfaces/api/daily-rewards';

const getDailyRewards = async () => {
    try {
        let url = ServerRoutes.dailyRewards.dailyRewards;
        const { status, data } = await axiosInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}


const createDailyReward = async (dailyRewardInfo: ICreateDailyRewardBody) => {
    try {
        const { status, data } = await axiosInstance.post(ServerRoutes.dailyRewards.createDailyReward, dailyRewardInfo);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const updateDailyReward = async (dailyRewardInfo: IUpdateDailyRewardBody) => {
    try {
        const { status, data } = await axiosInstance.put(ServerRoutes.dailyRewards.updateDailyReward, dailyRewardInfo);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const getDailyReward = async (params: IGetDailyRewardParams) => {
    try {
        let url = getParamsUrl(ServerRoutes.dailyRewards.getDailyReward, params);
        const { status, data } = await axiosInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}


const dailyRewardsApi = {
    getDailyRewards,
    createDailyReward,
    getDailyReward,
    updateDailyReward,
}

export default dailyRewardsApi;