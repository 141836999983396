import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../interfaces/base';
import { IDailyRewardsState } from '../../../interfaces/store/daily-rewards-interfaces';

const initialState: IDailyRewardsState = {
    dailyRewards: [],
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanDailyRewardsState = (state: IDailyRewardsState) => {
    return initialState;
};

const _fetchDailyRewardsStart = (state: IDailyRewardsState) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'read';
}

const _fetchDailyRewardsSuccess = (state: IDailyRewardsState, { payload }: PayloadAction<any>) => {
    state.dailyRewards = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _fetchDailyRewardsFailed = (state: IDailyRewardsState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const DailyRewardsSlice = createSlice({
    name: 'dailyRewards',
    initialState,
    reducers: {
        cleanDailyRewardsState: _cleanDailyRewardsState,
        fetchDailyRewardsStart: _fetchDailyRewardsStart,
        fetchDailyRewardsSuccess: _fetchDailyRewardsSuccess,
        fetchDailyRewardsFailed: _fetchDailyRewardsFailed
    }
})

const { actions, reducer } = DailyRewardsSlice;
export const {
    cleanDailyRewardsState,
    fetchDailyRewardsStart,
    fetchDailyRewardsSuccess,
    fetchDailyRewardsFailed
} = actions;

export default reducer;
