import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchDevicesFailed, fetchDevicesStart, fetchDevicesSuccess } from './devices-slice';
import devicesApi from '../../api/devices-api';
import { IGetDevicesParams } from '../../../interfaces/api/devices';

function* fetchDevices(action: PayloadAction<{ params: IGetDevicesParams; }>): any {
    try {
        const { params } = action.payload;
        delete params.page;
        const { data, status, error } = yield call(devicesApi.getDevices, params); 
        
        if (error) {
            yield put(fetchDevicesFailed(error));
            return;
        }
        
        yield put(fetchDevicesSuccess(data));
    } catch (error: any) {
        yield put(fetchDevicesFailed(error));
    }
}

function* devicesSaga() {
    yield takeLatest(fetchDevicesStart.type, fetchDevices);
}

export default devicesSaga;