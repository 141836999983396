import React from 'react';
import styled from 'styled-components';
import { IClickableText, ITypographyProps } from '../../interfaces/components/ui/Typography';
import Theme from '../../theme/theme';

export const Typography = styled.p<ITypographyProps>((props: ITypographyProps) => {
    const varient = props.varient || 'h1';
    return `
        pointer-events: none;
        cursor:${props.onClick && !props.disabled ? 'pointer' : 'default'};
        margin: 0;
        color: ${(props.color && props.theme.palette.typography[props.color]) || props.theme.typography.varient[varient].color};
        font-family:  ${props.theme.typography.varient[varient].fontFamily};
        font-size: ${props.theme.typography.varient[varient].fontSize};
        font-weight: ${props.theme.typography.varient[varient].fontWeight};
    `
});

export const TypographyColorOverride = styled.p<ITypographyProps>((props: ITypographyProps) => {
    const varient = props.varient || 'h1';
    return `
        cursor: default;
        pointer-events: none;
        margin: 0;
        color: ${(props.color && props.theme.palette.typography[props.color])};
        font-size: ${props.theme.typography.varient[varient].fontSize};
        font-weight: ${props.theme.typography.varient[varient].fontWeight};
    `
});

const ClickableTextStyled = styled.p<ITypographyProps>((props: ITypographyProps) => {
    const varient = props.varient || 'h3';
    return `
        margin: 0;
        color: ${(props.color ? props.theme.palette.typography[props.color] : Theme.palette.typography.info)};
        font-family:  ${props.theme.typography.varient[varient].fontFamily};
        font-size: ${props.theme.typography.varient[varient].fontSize};
        font-weight: ${props.theme.typography.varient[varient].fontWeight};

        text-decoration: underline;
        cursor: pointer;
        text-decoration-thickness: 1.5px;
        text-underline-offset: 4px;

        &:hover  {
            color: ${Theme.palette.primary.main};
            font-family: ${props.theme.fonts.fontFamily.bold};
        }

        &.disabled {
            color: ${Theme.palette.buttons.background.disabled};
            cursor: default;
            pointer-events: none;
        }

    `
});

export const StyledLink = styled.a<ITypographyProps>((props: ITypographyProps) => {
    const varient = props.varient || 'h3';
    return `
        margin: 0;
        color: ${(props.color ? props.theme.palette.typography[props.color] : Theme.palette.typography.info)};
        font-family:  ${props.theme.typography.varient[varient].fontFamily};
        font-size: ${props.theme.typography.varient[varient].fontSize};
        font-weight: ${props.theme.typography.varient[varient].fontWeight};
        text-decoration: underline;
        cursor: pointer;
        text-decoration-thickness: 1.5px;
        text-underline-offset: 4px;

    `
});


export const ClickableText = (props: IClickableText) => {

    return (
        <ClickableTextStyled
            disabled={props.disabled}
            className={props.disabled ? 'disabled' : ''}
            dir={props.dir || 'auto'}
            color={props.color}
            varient={props.varient || 'h3'}
            style={props.style}
            onClick={props.onClick}>
            {props.children}
        </ClickableTextStyled>
    )
}