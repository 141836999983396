import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../interfaces/base';
import { IEditLevelState } from '../../../interfaces/store/levels-interfaces';
import { ICreateLevelBody, IGetLevelParams, IUpdateLevelBody } from '../../../interfaces/api/levels';

const initialState: IEditLevelState = {
    level: null,
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanEditLevelState = (state: IEditLevelState) => {
    return initialState;
};


const _getLevelStart = (state: IEditLevelState, { payload }: PayloadAction<IGetLevelParams>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start'; 
    state.operation = 'read';
}

const _getLevelSuccess = (state: IEditLevelState, { payload }: PayloadAction<any>) => {
    state.level = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _getLevelFailed = (state: IEditLevelState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const _updateLevelStart = (state: IEditLevelState, { payload }: PayloadAction<IUpdateLevelBody>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'update';
}

const _updateLevelSuccess = (state: IEditLevelState, { payload }: PayloadAction<any>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _updateLevelFailed = (state: IEditLevelState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}


const _createLevelStart = (state: IEditLevelState, { payload }: PayloadAction<ICreateLevelBody>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'create';
}

const _createLevelSuccess = (state: IEditLevelState, { payload }: PayloadAction<any>) => {
    state.level = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _createLevelFailed = (state: IEditLevelState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}



const EditLevelSlice = createSlice({
    name: 'editLevel',
    initialState,
    reducers: {
        cleanEditLevelState: _cleanEditLevelState,
        getLevelStart: _getLevelStart,
        getLevelSuccess: _getLevelSuccess,
        getLevelFailed: _getLevelFailed,
        updateLevelStart: _updateLevelStart,
        updateLevelSuccess: _updateLevelSuccess,
        updateLevelFailed: _updateLevelFailed,
        createLevelStart: _createLevelStart,
        createLevelSuccess: _createLevelSuccess,
        createLevelFailed: _createLevelFailed,
    }
})

const { actions, reducer } = EditLevelSlice;
export const {
    cleanEditLevelState,
    getLevelStart,
    getLevelSuccess,
    getLevelFailed,
    updateLevelStart,
    updateLevelSuccess,
    updateLevelFailed,
    createLevelStart,
    createLevelSuccess,
    createLevelFailed,
} = actions;

export default reducer;
