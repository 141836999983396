
export const AuthRoutes = {
    login: '/login',
    logout: '/logout',
    resetPassword: '/resetPassword',

}

export const DashboardRoutes = {
    home: '/',
    accounts: '/accounts',
    library: '/library',
    scenes: '/scenes',
    boxes: '/boxes',
    levels: '/levels',
    dailyRewards: '/dailyRewards',
    devices: '/devices',
    breathingCycles: '/breathingCycles',
    gameConfig: '/settings',
    fota: '/fota'
}

export const AccountRoutes = {
    accountPage: '/accounts/:id',
}

export const LibraryRoutes = {
    imagePage: '/library/:id',
}

export const SceneRoutes = {
    scenePage: '/scenes/:id',
    sceneItemPage: '/scenes/sceneItem/:id',
}

export const BoxRoutes = {
    boxPage: '/boxes/:id',
    boxPrizePage: '/boxes/boxPrize/:id',
}

export const BreathingCyclesRoutes = {
    breathingCyclePage: '/breathingCycles/:id',
}

export const LevelRoutes = {
    levelPage: '/levels/:id',
}

export const DailyRewardRoutes = {
    dailyRewardPage: '/dailyRewards/:id',
}

const Routes = {
    Auth: AuthRoutes,
    Dashboard: DashboardRoutes,
    Account: AccountRoutes,
    Library: LibraryRoutes,
    Scene: SceneRoutes,
    Box: BoxRoutes,
    Level: LevelRoutes,
    DailyReward: DailyRewardRoutes,
    BreathingCycle: BreathingCyclesRoutes, 
}

export default Routes;