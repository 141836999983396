import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../interfaces/base';
import { IEditLibraryImageState } from '../../../interfaces/store/library-image-interfaces';
import { IGetLibraryImageParams } from '../../../interfaces/api/library';
import { IFileUpdate } from '../../../interfaces/api/files';

const initialState: IEditLibraryImageState = {
    libraryImage: null,
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanEditLibraryImageState = (state: IEditLibraryImageState) => {
    return initialState;
};


const _getLibraryImageStart = (state: IEditLibraryImageState, { payload }: PayloadAction<IGetLibraryImageParams>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'read';
}

const _getLibraryImageSuccess = (state: IEditLibraryImageState, { payload }: PayloadAction<any>) => {
    state.libraryImage = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _getLibraryImageFailed = (state: IEditLibraryImageState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const _updateLibraryImageStart = (state: IEditLibraryImageState, { payload }: PayloadAction<IFileUpdate>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'update';
}

const _updateLibraryImageSuccess = (state: IEditLibraryImageState, { payload }: PayloadAction<any>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _updateLibraryImageFailed = (state: IEditLibraryImageState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}


const _createLibraryImageStart = (state: IEditLibraryImageState, { payload }: PayloadAction<{ data: any }>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'create';
}

const _createLibraryImageSuccess = (state: IEditLibraryImageState, { payload }: PayloadAction<any>) => {
    state.libraryImage = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _createLibraryImageFailed = (state: IEditLibraryImageState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}


const _deleteLibraryImageStart = (state: IEditLibraryImageState, { payload }: PayloadAction<IGetLibraryImageParams>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'delete';
}

const _deleteLibraryImageSuccess = (state: IEditLibraryImageState, { payload }: PayloadAction<any>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _deleteLibraryImageFailed = (state: IEditLibraryImageState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const EditLibraryImageSlice = createSlice({
    name: 'editLibraryImage',
    initialState,
    reducers: {
        getLibraryImageStart: _getLibraryImageStart,
        getLibraryImageSuccess: _getLibraryImageSuccess,
        getLibraryImageFailed: _getLibraryImageFailed,
        updateLibraryImageStart: _updateLibraryImageStart,
        updateLibraryImageSuccess: _updateLibraryImageSuccess,
        updateLibraryImageFailed: _updateLibraryImageFailed,
        createLibraryImageStart: _createLibraryImageStart,
        createLibraryImageSuccess: _createLibraryImageSuccess,
        createLibraryImageFailed: _createLibraryImageFailed,
        deleteLibraryImageStart: _deleteLibraryImageStart,
        deleteLibraryImageSuccess: _deleteLibraryImageSuccess,
        deleteLibraryImageFailed: _deleteLibraryImageFailed,
        cleanEditLibraryImageState: _cleanEditLibraryImageState,
    }
})

const { actions, reducer } = EditLibraryImageSlice;
export const {
    getLibraryImageStart,
    getLibraryImageSuccess,
    getLibraryImageFailed,
    updateLibraryImageStart,
    updateLibraryImageSuccess,
    updateLibraryImageFailed,
    createLibraryImageStart,
    createLibraryImageSuccess,
    createLibraryImageFailed,
    deleteLibraryImageStart,
    deleteLibraryImageSuccess,
    deleteLibraryImageFailed,
    cleanEditLibraryImageState

} = actions;

export default reducer;
