import React from 'react';
import styled from 'styled-components';

import { ReactComponent as LoadingIcon } from '../../assets/icons/loader.svg';

const LoadingContainer = styled.div`
    @keyframes heartbeat {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.25);
    }

    100% {
        transform: scale(1);
    }
    }
    animation: heartbeat 1s infinite;
    opacity: 1;
    transition: opacity 500ms;
`

const LoadingLogo = (props: { darkLogo?: boolean }) => {

    return (
        <LoadingContainer style={{zIndex: '1'}}>
            <LoadingIcon width={150} height={150} />
        </LoadingContainer>
    );
}

export default LoadingLogo;
