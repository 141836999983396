import React from 'react';
import { FlexColumn, FlexRow, FlexRowWrapped, FormInputContainer } from '../../common/containers';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { Divider, Label, LargeTitle, Subtitle2Stronger } from '@fluentui/react-components';
import { BreathingCycleDataRules } from '../../navigation/FormsDefinitions';

const BreathingCycleData = () => {

    const breathingCycles = useAppSelector(state => state.editBreathingCycle.breathingCycle?.breathingCycles || []);

    function formatDateString(input: string) {
        let formattedDateString;
        const date = new Date(input);
        formattedDateString = date.toLocaleDateString("en-GB");
        return formattedDateString;
    }

    return (
        <>
            {
                breathingCycles.length > 0 ?
                    <>
                        <FlexColumn style={{ margin: '30px 0 40px' }}>
                            <Divider />
                        </FlexColumn>
                        <FlexColumn>
                            <LargeTitle >{'Breathing Cycles'}</LargeTitle>
                            {
                                breathingCycles.map((cycle, index) => (
                                    <FlexColumn key={index}>
                                        <Subtitle2Stronger style={{ fontSize: '22px', margin: '20px 0' }}>Breathing Cycle {index + 1}</Subtitle2Stronger>
                                        <FlexRowWrapped style={{ gap: '40px' }}>
                                            {Object.entries(cycle).map(([key, value]) => (
                                                <>
                                                    {BreathingCycleDataRules[key] ?
                                                        <FormInputContainer key={key}>
                                                            <FlexRow style={{ position: 'relative' }}>
                                                                <Label>

                                                                    {BreathingCycleDataRules[key].fieldName}:
                                                                </Label>
                                                            </FlexRow>
                                                            <FlexRow style={{ marginTop: '5px', fontWeight: '800' }} >
                                                                {BreathingCycleDataRules[key].type == 'dateString' ? formatDateString(value) : value === 0 ? '0' : value || ''}
                                                            </FlexRow>
                                                        </FormInputContainer>
                                                        : null
                                                    }
                                                </>
                                            ))}
                                        </FlexRowWrapped>
                                        {
                                            index == breathingCycles.length - 1 ? null :
                                                <FlexColumn style={{ margin: '30px 0' }}>
                                                    <Divider />
                                                </FlexColumn>
                                        }
                                    </FlexColumn>
                                ))
                            }
                        </FlexColumn >
                    </>
                    : null
            }
        </>
    )
}

export default BreathingCycleData;
