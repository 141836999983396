import { INotificationManagerState, INotifiction } from './../../../interfaces/store/notifications-manager';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';


const initialState: INotificationManagerState = {
    notification: null
}

const _cleanNotificationManagerState = (state: INotificationManagerState) => {
    return initialState;
}
const _setNotification = (state: INotificationManagerState, { payload }: PayloadAction<INotifiction>) => {
    state.notification = payload;
}

const _alertSuccess = (state: INotificationManagerState, { payload }: PayloadAction<string>) => {
    state.notification = { type: 'success', message: payload };
}
const _alertInfo = (state: INotificationManagerState, { payload }: PayloadAction<string>) => {
    state.notification = { type: 'info', message: payload };
}
const _alertError = (state: INotificationManagerState, { payload }: PayloadAction<string>) => {
    state.notification = { type: 'error', message: payload };
}


const NotificationsManagerSlice = createSlice({
    name: 'notificationsManager',
    initialState,
    reducers: {
        cleanNotificationManagerState: _cleanNotificationManagerState,
        alert: _setNotification,
        alertSuccess: _alertSuccess,
        alertInfo: _alertInfo,
        alertError: _alertError
    }
});

const { actions, reducer } = NotificationsManagerSlice;
export const {
    cleanNotificationManagerState,
    alert,
    alertSuccess,
    alertInfo,
    alertError
} = actions;

export default reducer;