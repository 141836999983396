import React from 'react';
import styled from 'styled-components';

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export const FlexRowAligned = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const FlexRowSpaced = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const FlexRowCentered = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const FlexRowWrapped = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FlexColumnCentered = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const RelativeContainer = styled.div`
    position: relative;
`

export const FlexRelativeContainer = styled.div`
    display: flex;
    position: relative;
`

export const FullDarkPage = styled(FlexRowCentered)`
    background-color: var(--background-dark); 
    height: inherit;
    justify-content: space-around;
`

export const FullPage = styled(FlexRowCentered)`
    background-color: var(--background-light); 
    height: inherit;
    /* justify-content: space-around; */
    gap: 10%;
    align-items: center;
    
`

export const GridRow = styled.div<{ numOfColums: number }>`
    display: grid;
    grid-template-columns:  repeat(${props => props.numOfColums || 'auto-fill'}, auto);
`


export const TitleContainer = styled(FlexRowCentered)`
    justify-content: flex-start;
    margin-bottom: 20px;
    position: relative;
`

export const FormInputContainer = styled(FlexColumn)`
    margin-top: 5px;
    max-width:350px;    
    min-width:250px;
    width: 50%;

`;
export const FormContainer = styled(FlexColumn)`
    gap: 40px;
    margin: 10px 0 40px ;
    flex-wrap: wrap;
    max-width: 1270px;
`;