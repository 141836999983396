import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchScenesFailed, fetchScenesStart, fetchScenesSuccess } from './scenes-slice';
import { IGetScenesParams } from '../../../interfaces/api/scenes';
import scenesApi from '../../api/scenes-api';

function* fetchScenes(action: PayloadAction<{ params: IGetScenesParams; }>): any {
    try {
        const { params } = action.payload;
        delete params.page;
        const { data, status, error } = yield call(scenesApi.getScenes, params);
        
        if (error) {
            yield put(fetchScenesFailed(error));
            return;
        }
        
        yield put(fetchScenesSuccess(data));
    } catch (error: any) {
        yield put(fetchScenesFailed(error));
    }
}

function* scenesSaga() {
    yield takeLatest(fetchScenesStart.type, fetchScenes);
}

export default scenesSaga;