import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useStyles = makeStyles({
    root: {

    },
});


export const useButtonStyles = makeStyles({
    primary: {
        ...shorthands.padding('10px', '30px')
    },
    fullWidth: {
        width: '100%',
    },
    secondary: {
        ...shorthands.padding('5px', '10px'),
        height: '35px',
        width: 'fit-content',
        backgroundColor: tokens.colorBrandForegroundInvertedHover,
        ':hover': {
            backgroundColor: tokens.colorBrandBackgroundInvertedPressed
        },
    },



    delete: {
        backgroundColor: tokens.colorPaletteRedBackground3,
        ':hover': {
            backgroundColor: tokens.colorPaletteRedForeground1
        },
    },

});

export const useModalStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    wideModal: {
        maxWidth:'90%',
    },
    title: {
        textAlign: 'center',
    },
    actions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        ...shorthands.gap('35px')
    },
});


export const useTypographyStyles = makeStyles({
    root: {
        color: tokens.colorNeutralBackground5,
        ...shorthands.padding('10px', '30px')
    },
    error: {
        color: tokens.colorPaletteCranberryBorderActive,
        ...shorthands.margin('20px', 'auto'),

    }
});

export const useIconsStyles = makeStyles({
    loginArrow: {
        position: 'absolute',
        cursor: 'pointer',
        top: '17px',
        left: '-30px'
    },
    clickableIcon: {
        cursor: 'pointer',
    }
});


export const useTableStyles = makeStyles({
    primary: {
        width: '100%',
    },
    tableHeader: {
        weight: tokens.fontWeightBold,
        ...shorthands.padding('10px', '0px'),
        ...shorthands.borderBottom('solid 33px black'),
    }
});

