import React, { useRef, useEffect } from 'react';

interface IProps {
    children: any;
    callback: Function;
};

const OutsideClick = (props: IProps) => {
    const wrappedRef = useRef<any>(null);
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    const handleClickOutside = (event: { target: any; }) => {
        if (wrappedRef.current && !wrappedRef?.current.contains(event.target)) {
            props.callback();
        }
    }

    return (
        <div style={{}} ref={wrappedRef} > {props.children}</div >
    );
}

export default OutsideClick;