import React from 'react'
import { BrowserRouter as Router, Routes as Switch, Route, Navigate } from 'react-router-dom';
import Routes from '../../routes';
import Login from './Login';
import ResetPassword from './ResetPassword';

const LoginNavigation = () => {
    return (
        <Router>
            <Switch>
                <Route path={Routes.Auth.login} element={<Login />}></Route>
                <Route path={Routes.Auth.resetPassword} element={<ResetPassword />}></Route>
                <Route
                    path="*"
                    element={<Navigate to={Routes.Auth.login} replace />}
                />
            </Switch>
        </Router>
    )
}

export default LoginNavigation;
