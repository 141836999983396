import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    publishFirmwareFailed,
    publishFirmwareSuccess,
    publishFirmwareStart,
    fetchFirmwaresStart,
    fetchFirmwaresFailed,
    fetchFirmwaresSuccess,
    deleteFirmwareFailed,
    deleteFirmwareSuccess,
    deleteFirmwareStart,
    createFirmwareStart,
    createFirmwareSuccess,
    createFirmwareFailed
} from './fota-slice';
import fotaApi from '../../api/fota-api';
import { alertError } from '../notifications-manager/notification-manager';
import { ICreateFirmwareBody, IDeleteFirmwareParams, IGetFirmwaresParams, IPublishFirmwareBody } from '../../../interfaces/api/fota';


function* publishFirmware(action: PayloadAction<IPublishFirmwareBody>): any {
    try {
        const params = action.payload;
        const { data, status, error } = yield call(fotaApi.publishFirmware, params);

        if (error) {
            throw error;
        }

        yield put(publishFirmwareSuccess(data));

        let fetchParams: IGetFirmwaresParams = {
            skip: 0,
            limit: 8,
            page: 1,
            sortDir: 'desc'
        }

        yield put(fetchFirmwaresStart({ params: fetchParams }));

    } catch (error: any) {
        yield put(publishFirmwareFailed(error));
        yield put(alertError(error.message))
    }
}


function* deleteFirmware(action: PayloadAction<IDeleteFirmwareParams>): any {

    try {
        const params = action.payload;
        const { data, status, error } = yield call(fotaApi.deleteFirmware, params);

        if (error) {
            yield put(deleteFirmwareFailed(error));
            return;
        }
        yield put(deleteFirmwareSuccess(data));

        let fetchParams: IGetFirmwaresParams = {
            skip: 0,
            limit: 8,
            page: 1,
            sortDir: 'desc'
        }

        yield put(fetchFirmwaresStart({ params: fetchParams }));

    } catch (error: any) {
        yield put(deleteFirmwareFailed(error));
        yield put(alertError(error.message))
    }
}

function* fetchFirmwares(action: PayloadAction<{ params: IGetFirmwaresParams; }>): any {
    try {
        const { params } = action.payload;
        delete params.page;
        const { data, status, error } = yield call(fotaApi.getFirmwares, params);

        if (error) {
            yield put(fetchFirmwaresFailed(error));
            return;
        }

        yield put(fetchFirmwaresSuccess(data));
    } catch (error: any) {
        yield put(fetchFirmwaresFailed(error));
    }
}

function* createFirmware(action: PayloadAction<ICreateFirmwareBody>): any {

    try {
        const params = action.payload;
        const { data, status, error } = yield call(fotaApi.createFirmware, params);

        if (error) {
            yield put(createFirmwareFailed(error));
            return;
        }
        yield put(createFirmwareSuccess(data));

    } catch (error: any) {
        yield put(createFirmwareFailed(error));
        yield put(alertError(error.message))

    }
}

function* fotaSaga() {
    yield takeLatest(publishFirmwareStart.type, publishFirmware);
    yield takeLatest(createFirmwareStart.type, createFirmware);
    yield takeLatest(fetchFirmwaresStart.type, fetchFirmwares);
    yield takeLatest(deleteFirmwareStart.type, deleteFirmware);
}

export default fotaSaga;