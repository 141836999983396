import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchLevelsFailed, fetchLevelsStart, fetchLevelsSuccess } from './levels-slice';
import { IGetLevelsParams } from '../../../interfaces/api/levels';
import levelsApi from '../../api/levels-api';

function* fetchLevels(action: PayloadAction<{ params: IGetLevelsParams; }>): any {
    try {
        const { params } = action.payload;
        delete params.page;
        const { data, status, error } = yield call(levelsApi.getLevels, params); 
        
        if (error) {
            yield put(fetchLevelsFailed(error));
            return;
        }
        
        yield put(fetchLevelsSuccess(data));
    } catch (error: any) {
        yield put(fetchLevelsFailed(error));
    }
}

function* levelsSaga() {
    yield takeLatest(fetchLevelsStart.type, fetchLevels);
}

export default levelsSaga;