import React, { useCallback } from 'react';
import Table, { columnFields } from '../table/Table';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { fetchDevicesStart } from '../../store/features/devices/devices-slice';
import { Device } from '../../interfaces/models/device';
import { IGetDevicesParams } from '../../interfaces/api/devices';


const columns: columnFields[] = [
    {
        fieldName: "deviceId",
        columnName: "Device Id",
    },
    {
        fieldName: "batteryStatus",
        columnName: "Battery Status",
    },
    {
        fieldName: "lastSessionBeginning",
        columnName: "Last Session Beginning",
        type: "date",
    },
    {
        fieldName: "lastSessionCompletion",
        columnName: "Last Session Completion",
        type: "date",
    },
    {
        fieldName: "totalBreathingCycles",
        columnName: "Total Breathing Cycles",
    },
    {
        fieldName: "totalPeriodicalRecords",
        columnName: "Total Periodical Records",
    },
    {
        fieldName: "lastTimeUsed",
        columnName: "last Time Used",
        type: "date",
    },
]

const Devices = () => {

    const devices = useAppSelector(state => state.devices.devices);
    const loading = useAppSelector(state => state.devices.loading);
    const limit = 20;
    const total = useAppSelector(state => state.devices.metadata.total);
    const page = useAppSelector(state => state.devices.metadata.page);
    const dispatch = useAppDispatch();
   
    const getDevices = useCallback((page: number, search?: string) => {

        let params: IGetDevicesParams = {
            skip: ((page - 1) * limit),
            limit: limit,
            page: page
        }

        dispatch(fetchDevicesStart({ params }))

    }, []);

    return (
        <Table<Device>
            title={'Devices'}
            list={devices}
            columns={columns}
            getItems={getDevices}
            limit={limit}
            page={page}
            total={total}
            withAddNew={false}
            loading={loading}
            withSearch={false}
        />
    );
};

export default Devices;
