import { IWithPagination, IWithSort } from "./common";
import { Option } from "../../components/ui/Select";
import { IBoxesTypeOption } from "../models/box";

export type BoxesSort = 'name' | 'createdAt';

export interface IGetBoxesParams extends IWithPagination, IWithSort<BoxesSort> {
    search?: string;
}

export interface IGetBoxParams {
    giftBoxId: string;
}

export interface IBoxesSortOption extends IWithSort<BoxesSort> { }


export const boxesSortOptions: Option<IBoxesSortOption>[] = [
    { id: '1', label: 'Created At - Ascending', value: { sortBy: 'createdAt', sortDir: 'asc' } },
    { id: '2', label: 'Created At - Descending', value: { sortBy: 'createdAt', sortDir: 'desc' } },
    { id: '3', label: 'Name - Ascending', value: { sortBy: 'name', sortDir: 'asc' } },
    { id: '4', label: 'Name - Descending', value: { sortBy: 'name', sortDir: 'desc' } }
]

export interface ICreateBoxBody {
    name: string,
    price: number,
    boxType: IBoxesTypeOption,
}

export interface IUpdateBoxBody {
    id: string;
    data: {
        name: string,
        price: number,
        boxType: IBoxesTypeOption
    };
}

//Box prize

export interface IGetBoxPrizeParams {
    giftBoxPrizeId: string;
}

export interface IGetBoxPrizesParams {
    giftBoxId: string;
}

export interface IRemoveBoxPrizeParams {
    giftBoxPrizeId: string,
    giftBoxId: string
}

export interface ICreateBoxPrizeBody {
    giftBoxId: string,
    prize: {
        name: string,
        imagePath: string,
        amount: number,
        giftBoxId: string,
        prizeType: string,
        chance: number,
        timesCanBeWon: number
    }
}

export interface IUpdateBoxPrizeBody {
    id: string;
    data: {
        name: string,
        imagePath: string,
        amount: number,
        giftBoxId: string,
        prizeType: string,
        chance: number,
        timesCanBeWon: number
    };
}

