import React, { useCallback } from "react";
import Switch from "../common/Switch";
import { FlexColumn, FlexRow } from "../common/containers";
import styled from "styled-components";


export interface ISwitchContainer {
  checked: boolean;
  withUnpublish: boolean;
}

export const SwitchContainer = styled(FlexColumn)`
    opacity: ${(props: ISwitchContainer) => props.checked && !props.withUnpublish ? 0.5 : 1} ;
    pointer-events: ${(props: ISwitchContainer) => props.checked && !props.withUnpublish ? "none" : "auto"} ;
`;


const PublishSwitch = (props: {
  disabledSwitch?: boolean,
  checked: boolean,
  setChecked: any,
  withAlert: boolean,
  withUnpublish: boolean,
  onChange?: any,
}) => {

  const checkedString = props.checked ? "Published" : "";

  const onChange = useCallback((ev: any) => {
    props.setChecked(ev.currentTarget.checked);
  }, [props.setChecked]);


  return (
    <SwitchContainer checked={props.checked} withUnpublish={props.withUnpublish} style={{position:'relative'}}>
      <Switch
        label={``}
        checked={props.checked}
        disabledSwitch={props.disabledSwitch}
        setChecked={props.setChecked}
        onChange={props.onChange || onChange} />

      <FlexRow style={{position:'absolute', bottom:'-17px'}}>
        {`${checkedString}`}
      </FlexRow>
    </SwitchContainer>
  );
};
export default PublishSwitch;