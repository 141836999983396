import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { alertError } from '../notifications-manager/notification-manager';
import {
    createBoxFailed,
    createBoxStart,
    createBoxSuccess,
    fetchBoxPrizesFailed,
    fetchBoxPrizesStart,
    fetchBoxPrizesSuccess,
    getBoxFailed,
    getBoxStart,
    getBoxSuccess,
    publishBoxFailed,
    publishBoxStart,
    publishBoxSuccess,
    updateBoxFailed,
    updateBoxStart,
    updateBoxSuccess
} from './edit-box-slice';
import { ICreateBoxBody, IGetBoxPrizesParams, IGetBoxParams, IUpdateBoxBody } from '../../../interfaces/api/boxes';
import boxesApi from '../../api/boxes-api';


function* createBox(action: PayloadAction<ICreateBoxBody>): any {
    try {
        const boxData = action.payload;
        const { data, status, error } = yield call(boxesApi.createBox, boxData);
        if (error) {
            yield put(createBoxFailed(error));
            return;
        }
        yield put(createBoxSuccess(data));
    } catch (error: any) {
        yield put(createBoxFailed(error));
        yield put(alertError(error.message))
    }
}


function* updateBox(action: PayloadAction<IUpdateBoxBody>): any {
    try {
        const params = action.payload;
        const { data, status, error } = yield call(boxesApi.updateBox, params);
        if (error) {
            yield put(updateBoxFailed(error));
            return;
        }
        yield put(updateBoxSuccess(data));
    } catch (error: any) {
        yield put(updateBoxFailed(error));
        yield put(alertError(error.message))

    }
}

function* getBox(action: PayloadAction<IGetBoxParams>): any {
    try {
        const { giftBoxId } = action.payload;
        const { data, status, error } = yield call(boxesApi.getBox, { giftBoxId });
        if (error) {
            throw error;
        }
        yield put(getBoxSuccess(data));
    } catch (error: any) {
        yield put(getBoxFailed(error));
    }
}

function* getBoxPrizes(action: PayloadAction<{ params: IGetBoxPrizesParams; }>): any {
    try {
        const { params } = action.payload;
        const { data, status, error } = yield call(boxesApi.getBoxPrizes, params);
        if (error) {
            throw error;
        }
        yield put(fetchBoxPrizesSuccess(data));
    } catch (error: any) {
        yield put(fetchBoxPrizesFailed(error));
    }
}

function* publishBox(action: PayloadAction<{params: { giftBoxId: string, publish: boolean }}>): any {
    try {
        const params = action.payload.params;

        const { data, status, error } = yield call(boxesApi.publishBox, params );
        if (error) {
            throw error;
        }
        yield put(publishBoxSuccess(data));
        // yield put(getBoxStart({ giftBoxId }));

    } catch (error: any) {
        yield put(publishBoxFailed(error));
        yield put(alertError(error.message))
    }
}

function* editBoxSaga() {
    yield takeLatest(updateBoxStart.type, updateBox);
    yield takeLatest(createBoxStart.type, createBox);
    yield takeLatest(getBoxStart.type, getBox);
    yield takeLatest(fetchBoxPrizesStart.type, getBoxPrizes);
    yield takeLatest(publishBoxStart.type, publishBox);
}

export default editBoxSaga;