import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    getBreathingCycleFailed,
    getBreathingCycleStart,
    getBreathingCycleSuccess,
} from './edit-breathing-cycle-slice';
import { IGetBreathingCycleParams } from '../../../interfaces/api/breathing-cycles';
import breathingCyclesApi from '../../api/breathing-cycles-api';

function* getBreathingCycle(action: PayloadAction<IGetBreathingCycleParams>): any {
    try {
        const { breathingCycleSessionId } = action.payload;
        const { data, status, error } = yield call(breathingCyclesApi.getBreathingCycle, { breathingCycleSessionId });
        if (error) {
            throw error;
        }
        yield put(getBreathingCycleSuccess(data));
    } catch (error: any) {
        yield put(getBreathingCycleFailed(error));
    }
}

function* editBreathingCycleSaga() {
    yield takeLatest(getBreathingCycleStart.type, getBreathingCycle);
}

export default editBreathingCycleSaga;