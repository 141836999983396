import { IGetAccountParams } from '../../../interfaces/api/accounts';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../interfaces/base';
import { IEditAccountState } from '../../../interfaces/store/accounts-interfaces'

const initialState: IEditAccountState = {
    account: null,
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanEditAccountState = (state: IEditAccountState) => {
    return initialState;
};
const _resetEditAccountRequestState = (state: IEditAccountState) => {
    state.requestState = 'prepare';
    state.operation = null;
};

const _getAccountStart = (state: IEditAccountState, { payload }: PayloadAction<IGetAccountParams>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'read';
}

const _getAccountSuccess = (state: IEditAccountState, { payload }: PayloadAction<any>) => {
    state.account = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _getAccountFailed = (state: IEditAccountState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

// const _deleteAccountStart = (state: IEditAccountState, { payload }: PayloadAction<IGetAccountParams>) => {
//     state.loading = true;
//     state.error = null;
//     state.requestState = 'start';
//     state.operation = 'delete';
// }

// const _deleteAccountSuccess = (state: IEditAccountState, { payload }: PayloadAction<any>) => {
//     state.loading = false;
//     state.error = null;
//     state.requestState = 'finish';
// }

// const _deleteAccountFailed = (state: IEditAccountState, { payload }: PayloadAction<IError>) => {
//     state.loading = false;
//     state.error = payload;
//     state.requestState = 'error';
// }

const AccountsSlice = createSlice({
    name: 'editAccount',
    initialState,
    reducers: {
        cleanEditAccountState: _cleanEditAccountState,
        getAccountStart: _getAccountStart,
        getAccountSuccess: _getAccountSuccess,
        getAccountFailed: _getAccountFailed,
        // deleteAccountStart: _deleteAccountStart,
        // deleteAccountSuccess: _deleteAccountSuccess,
        // deleteAccountFailed: _deleteAccountFailed,
        resetEditAccountRequestState: _resetEditAccountRequestState
    }
})

const { actions, reducer } = AccountsSlice;
export const {
    cleanEditAccountState,
    getAccountStart,
    getAccountSuccess,
    getAccountFailed,
    // deleteAccountStart,
    // deleteAccountSuccess,
    // deleteAccountFailed,
    resetEditAccountRequestState
} = actions;

export default reducer;
