import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Cropper, { Area } from 'react-easy-crop';
import styled from 'styled-components';
import Theme from '../../../theme/theme';
import { FlexColumn, FlexColumnCentered, FlexRow, FlexRowCentered } from '../containers';
import { Subtitle2, tokens, Button, Subtitle1, Spinner, Image } from '@fluentui/react-components';
import { ReactComponent as FileIcon } from '../../../assets/icons/file-icon.svg';
import { ReactComponent as Icon } from '../../../assets/icons/img-placeholder-icon.svg';

export interface IFileToUpload {
    blobFile: Blob;
    fileName: string;
    filePath: string;
    fileType: 'image' | 'file'
}

const ZoomSlider = styled.input`
    appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;

    &:hover {
        opacity: 1;
    }
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: ${Theme.palette.primary.main};
        cursor: pointer;
    }

    &::-moz-range-thumb {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: ${Theme.palette.primary.main};
        cursor: pointer;
    }
`;


const CropComponent = ({ onUpload, onCancel, oldCrop, oldZoom, aspect, file, ...props }: any) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [cropOptions, setCropOptions] = useState<any>(null);

    const onCropChange = (newCrop: any) => {
        setCrop(newCrop);
    }

    const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
        setCropOptions(croppedAreaPixels);
    }

    const onZoomChange = (newZoom: any) => {
        setZoom(newZoom);
    }

    return (
        <FlexColumn style={{
            height: props.previewHeight,
        }}>
            <div style={{
                width: props.previewWidth,
                height: props.previewHeight,
                position: 'relative'
            }}>
                <Cropper
                    style={{
                        containerStyle: { backgroundColor: 'white' }
                    }}
                    image={file.preview}
                    crop={crop}
                    zoom={zoom}
                    objectFit={'auto-cover'}
                    aspect={aspect}
                    onCropChange={onCropChange}
                    onCropComplete={onCropComplete}
                    onZoomChange={onZoomChange}
                />
            </div>
            <FlexColumn style={{ width: '90%', alignSelf: 'center', margin: '20px 0' }}>
                <ZoomSlider
                    type="range"
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e: any) => {
                        setZoom(e.target.value)
                    }}
                />
            </FlexColumn>
            <FlexRow style={{ justifyContent: 'space-around' }}>
                <Button
                    onClick={() => {
                        onUpload(file, cropOptions)
                    }}>
                    Save
                </Button>
                <Button
                    onClick={onCancel}>
                    Cancel
                </Button>
            </FlexRow>
        </FlexColumn>
    )
}

const PreviewComponent = ({ image, setMode, file, isOriginalImage, ...props }: any) => {
    return (
        <FlexColumnCentered>
            <FlexColumn style={{ marginBottom: '30px', width: '100%' }}>
                <Subtitle1>
                    File Added
                </Subtitle1>
                <Subtitle1
                    onClick={() => setMode('upload')}
                    style={{
                        textDecoration: 'underline',
                        fontWeight: '700',
                        cursor: 'pointer',
                    }} >
                    Change File
                </Subtitle1>
            </FlexColumn>
            <FlexColumn style={{ maxHeight: '100%', maxWidth: '100%' }}>
                {
                    isOriginalImage || file.type.includes("image") ?
                        <Image
                            style={{ maxHeight: '100%', maxWidth: '100%' }}
                            src={image}
                        />
                        :
                        <FlexColumnCentered style={{ padding: '35px' }}>
                            <FileIcon width={58} height={73} />
                            <Subtitle2>
                                {file.name}
                            </Subtitle2>
                        </FlexColumnCentered>
                }

            </FlexColumn>
        </FlexColumnCentered>
    )
}

const Wrapper = styled.div((props: { mode: any, height: number | undefined, width: number | undefined }) => {
    return `
        cursor: ${props.mode !== 'upload' ? 'default' : 'pointer'};
        overflow: auto;
        min-height: 160px;
        min-width: 230px;
        height: ${props.height ? props.height + 'px' : 'unset'};
        width: ${props.width ? props.width + 'px' : 'unset'};
        border-style: dashed;
        border-color: ${tokens.colorNeutralForeground3};
        border-radius: 4px;  
        margin: 20px 0 ;
        padding: 20px 20px 10px 20px;
    `
})

interface IDropzoneProps {
    originalImage?: string | null;
    // multiFiles?: boolean;
    title?: string;
    canvasWidth?: number;
    canvasHeight?: number;
    onUploadFileComplete: (file: IFileToUpload) => void
    wrapperWidth?: number;
    wrapperHeight?: number;
    withCrop?: boolean;
    aspect?: number;
    fileTypes?: FileTypes[];
    // maxFiles?: number;
    dropzoneMode?: 'upload' | 'loading' | 'hover' | 'crop' | 'preview' | null;
}

interface IFileBlob extends Blob {
    preview: string,
    name: string
}
interface IFileMediaSource extends MediaSource {
    preview: string,
    name: string
}

export type FileTypes = 'pdf' | 'xml' | 'image' | 'bin';

const fileTypesToAccept: { [key: string]: any } = {
    'pdf': { 'application/pdf': [] },
    'xml': { 'application/xml': [], 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [] },
    'image': { 'image/png': [], 'image/jpeg': [] }
};


const getFileTypeToUplaod = (types: FileTypes[]) => {
    let res = {};
    for (const type of types) {
        if (fileTypesToAccept[type]) {
            res = {
                ...res,
                ...fileTypesToAccept[type]
            }
        }
    }
    return res;
}

const convertFileToBlob = async (file: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const blob = new Blob([reader.result || ''], { type: file.type });
            resolve(blob);
        };

        reader.onerror = reject;
        reader.readAsArrayBuffer(file);

    });
}
const onSaveWithoutCrop = async (file: any, onUploadFileComplete: any) => {
    convertFileToBlob(file)
        .then((blob) => {
            const fileToUpload: IFileToUpload = {
                fileName: file.name.toLowerCase(),
                filePath: file.name,
                fileType: file.type === 'image' ? 'image' : 'file',
                blobFile: blob as IFileBlob
            }
            onUploadFileComplete(fileToUpload);
        })
        .catch((error) => {
            console.error("Error converting file to Blob:", error);
        });
}

const Dropzone = (props: IDropzoneProps) => {
    const [files, setFiles] = useState<IFileBlob[] | IFileMediaSource[]>([]);
    const [croppedImage, setCroppedImage] = useState<IFileBlob | null>(null)
    const [mode, setMode] = useState<'upload' | 'loading' | 'hover' | 'crop' | 'preview'>(props.originalImage ? 'preview' : 'upload');

    useEffect(() => {
        if (props.dropzoneMode) {
            setMode(props.dropzoneMode);
        }
    }, [props.dropzoneMode]);
    
    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (!acceptedFiles.length) {
            setMode('upload');
            return;
        }
        setMode('loading')
        setFiles(acceptedFiles.map((file: File) => Object.assign(file, {
            preview: URL.createObjectURL(file),
        })));

        if (!props.withCrop) {
            const currFile = acceptedFiles[0];
            onSaveWithoutCrop(currFile, props.onUploadFileComplete);
        }
        props.withCrop && acceptedFiles[0].type.includes("image") ? setMode('crop') : setMode('preview');

    }, []);

    const onDragLeave = useCallback(() => {
        setMode('upload');
    }, [])

    const onDragEnter = useCallback(() => {
        setMode('hover');
    }, [])

    const { getRootProps } = useDropzone({
        onDrop,
        onDragLeave: onDragLeave,
        onDragEnter: onDragEnter,
        maxFiles: 1,
        accept: getFileTypeToUplaod(props.fileTypes || [])
    });

    const onCancel = () => {
        setFiles([]);
        setMode('upload');
    }

    const onSave = async (file: any, cropOptions: any) => {
        const res = await getCroppedImg(file.preview, cropOptions, props.canvasWidth || 200, props.canvasHeight || 200);
        setCroppedImage(res as IFileBlob);
        const fileToUpload: IFileToUpload = {
            fileName: file.name.toLowerCase(),
            filePath: file.name,
            fileType: file.type === 'image' ? 'image' : 'file',
            blobFile: res as IFileBlob
        }
        props.onUploadFileComplete(fileToUpload);
        setMode('preview');
    }

    let view = (
        <FlexColumnCentered style={{ height: '188px' }}>
            <Spinner />
        </FlexColumnCentered>
    )

    if (mode === 'upload' || mode === 'hover') {
        view = (
            <FlexColumn
                style={{
                    opacity: mode === 'hover' ? 0.2 : 1,
                    height: '100%'
                }}
                {...getRootProps({ className: 'dropzone' })}>
                {
                    props.title ?
                        <Subtitle1>{props.title} </Subtitle1> : null
                }

                <FlexColumnCentered style={{ height: '100%' }}>
                    <Icon />
                    <FlexRowCentered style={{ paddingTop: '20px', height: '44px' }}>
                        <Subtitle1>
                            Drag File, or
                        </Subtitle1>
                        <Subtitle1
                            style={{
                                textDecoration: 'underline',
                                padding: '0 5px',
                                fontWeight: '700',
                            }}
                        >
                            Click here
                        </Subtitle1>
                    </FlexRowCentered>
                </FlexColumnCentered>
            </FlexColumn>
        )
    }
    if (mode === 'crop' && files[0]) {
        view = <CropComponent
            onUpload={onSave}
            onCancel={onCancel}
            previewHeight={'100%'}
            previewWidth={'100%'}
            file={files[0]}
            aspect={props.aspect}
        />
    }
    if (mode === 'preview' && files[0]) {
        view = <PreviewComponent
            setMode={setMode}
            file={files[0]}
            isOriginalImage={props.originalImage ? true : false}
            image={croppedImage ? URL.createObjectURL(croppedImage) : files.length > 0 ? files[0].preview : props.originalImage}
        />
    }
    return (
        <Wrapper mode={mode} width={props.wrapperWidth} height={props.wrapperHeight} >
            {view}
        </Wrapper >
    )
}

export default Dropzone;

const createImage = (url: string) =>
    new Promise((resolve, reject) => {
        const image = new window.Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    });

async function getCroppedImg(imageSrc: any, pixelCrop: { x: number; y: number; width: number; height: number; }, canvasWidth: number, canvasHeight: number) {
    const image = await createImage(imageSrc) as CanvasImageSource;
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d');
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    if (ctx) {
        ctx.drawImage(image, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, canvasWidth, canvasHeight);

        // As a blob
        return new Promise(resolve => {
            canvas.toBlob(file => {
                resolve(file)
            }, 'image/jpeg')
        })
    }
}