import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IError } from '../../../interfaces/base';
import { ILoginForm } from '../../../interfaces/components/forms/login';
import { IAuthUserState } from '../../../interfaces/store/auth-user-interfaces'

const initialState: IAuthUserState = {
    user: null,
    role: '',
    loginForm: null,
    authenticated: false,
    loading: false,
    verifyCode: false,
    verificationToken: null,
    onStartUp: true,
    error: null,
    requestState: 'prepare',
    operation: null
}


const _resetVerifyCode = (state: IAuthUserState) => {
    state.verifyCode = false;
};

const _loadStart = (state: IAuthUserState) => {
    state.loading = true;
    state.error = null;
};

const _failed = (state: IAuthUserState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
};

const _success = (state: IAuthUserState) => {
    state.loading = false;
};

const _claenState = (state: IAuthUserState) => {
    return initialState;
};

const _authCheckStart = (state: IAuthUserState) => {
    state.user = null;
    state.authenticated = false;
    state.loading = true;
    state.error = null;
}

const _authCheckSuccess = (state: IAuthUserState, { payload }: PayloadAction<any>) => {
    state.user = payload.user;
    state.onStartUp = false;
    state.authenticated = true;
    state.loading = false;
    state.error = null;
}

const _setRole = (state: IAuthUserState, { payload }: PayloadAction<{role: string}>) => {
    state.role = payload.role;
}

const _authCheckFailed = (state: IAuthUserState, { payload }: PayloadAction<any>) => {
    state.authenticated = false;
    state.onStartUp = false;
    state.loading = false;
    state.error = payload;
}

const _loginStart = (state: IAuthUserState, { payload }: PayloadAction<ILoginForm>) => {
    state.loginForm = payload;
    state.user = null;
    state.authenticated = false;
    state.loading = true;
    state.error = null;
}

const _loginSuccess = (state: IAuthUserState, { payload }: PayloadAction<{ access_token: string, two_factor?: boolean, user?:any}>) => {
    state.user = payload.user || null;
    state.verifyCode = payload.two_factor || false;
    state.verificationToken = payload.access_token;
    state.loading = false;
    state.error = null;
}

const _loginFailed = (state: IAuthUserState, { payload }: PayloadAction<IError>) => {
    state.authenticated = false;
    state.loading = false;
    state.error = payload;
}

const _verifyCodeStart = (state: IAuthUserState, { payload }: PayloadAction<{ code: string, token: string }>) => {
    state.user = null;
    state.authenticated = false;
    state.loading = true;
    state.error = null;
}

const _verifyCodeSuccess = (state: IAuthUserState, { payload }: PayloadAction<any>) => {
    console.log(payload);
    
    state.user = payload.user;
    state.authenticated = true;
    state.verificationToken = null;
    state.verifyCode = false;
    state.loading = false;
    state.error = null;
}

const _verifyCodeFailed = (state: IAuthUserState, { payload }: PayloadAction<IError>) => {
    state.authenticated = false;
    state.loading = false;
    state.error = payload;
}

const _authLogoutStart = (state: IAuthUserState) => {
    state.error = null;
    state.loading = true;
};

const _authLogoutSuccess = (state: IAuthUserState) => {
    state.user = null;
    state.authenticated = false;
    state.error = null;
    state.loading = false;
    state.onStartUp = false;
    state.role = '';
};

const _resendCodeStart = (state: IAuthUserState, { payload }: PayloadAction<{ token: string }>) => {
    state.loading = true;
};

const _scheduleRefresh = (state: IAuthUserState, { payload }: PayloadAction<any>) => { };


const _forgotPasswordStart = (state: IAuthUserState, { payload }: PayloadAction<any>) => {
    state.loading = true;
    state.operation = 'forgotPassword';

}

const _forgotPasswordSuccess = (state: IAuthUserState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}


const _forgotPasswordFailed = (state: IAuthUserState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
}


const _resetPasswordStart = (state: IAuthUserState, { payload }: PayloadAction<any>) => {
    state.loading = true;
    state.operation = 'resetPassword';
}

const _resetPasswordSuccess = (state: IAuthUserState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}


const _resetPasswordFailed = (state: IAuthUserState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
}


const AuthUserSlice = createSlice({
    name: 'auth-user',
    initialState,
    reducers: {
        claenState: _claenState,
        authCheckStart: _authCheckStart,
        authCheckSuccess: _authCheckSuccess,
        setRole: _setRole,
        authCheckFailed: _authCheckFailed,
        loginStart: _loginStart,
        loginSuccess: _loginSuccess,
        loginFailed: _loginFailed,
        verifyCodeStart: _verifyCodeStart,
        verifyCodeSuccess: _verifyCodeSuccess,
        verifyCodeFailed: _verifyCodeFailed,
        authLogoutStart: _authLogoutStart,
        authLogoutSuccess: _authLogoutSuccess,
        loadStart: _loadStart,
        failed: _failed,
        success: _success,
        scheduleRefresh: _scheduleRefresh,
        resetVerifyCode: _resetVerifyCode,
        resendCodeStart: _resendCodeStart,
        forgotPasswordStart: _forgotPasswordStart,
        forgotPasswordSuccess: _forgotPasswordSuccess,
        forgotPasswordFailed: _forgotPasswordFailed,
        resetPasswordStart: _resetPasswordStart,
        resetPasswordSuccess: _resetPasswordSuccess,
        resetPasswordFailed: _resetPasswordFailed,
    }
})

const { actions, reducer } = AuthUserSlice;
export const {
    claenState,
    loadStart,
    failed,
    success,
    authCheckStart,
    authCheckSuccess,
    setRole,
    authCheckFailed,
    loginStart,
    loginSuccess,
    loginFailed,
    verifyCodeStart,
    verifyCodeSuccess,
    verifyCodeFailed,
    authLogoutStart,
    authLogoutSuccess,
    scheduleRefresh,
    resetVerifyCode,
    resendCodeStart,
    forgotPasswordStart,
    forgotPasswordSuccess,
    forgotPasswordFailed,
    resetPasswordStart,
    resetPasswordSuccess,
    resetPasswordFailed,
} = actions;

export default reducer;
