import styled from 'styled-components';
import SideBar from '../navigation/SideBar';
import { TopNavFullHeight } from '../navigation/StyledComponents';
import TopNavigation from '../navigation/TopNavigation';
import PageLayout from './PageLayout';

export const MainAppContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: fixed;
    top: ${TopNavFullHeight + 'px'};
    width: 100%;
    height: ${`calc(100% - ${TopNavFullHeight + 'px'})`};
`;

const MainAppLayout = ({ children, Bar }: { children: any; Bar: typeof SideBar | null }) => {
    return (
        <>
            <TopNavigation />
            <MainAppContainer>
                <PageLayout>
                    {children}
                </PageLayout>

                {/* <Bar /> */}
            </MainAppContainer>
        </>
    )
}

export default MainAppLayout;