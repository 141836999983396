import React, { useCallback, useEffect } from 'react';
import Table, { columnFields } from '../table/Table';
import { useNavigate } from 'react-router-dom';
import Routes from '../../routes';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { IGetBreathingCyclesParams, breathingCyclesSortOptions } from '../../interfaces/api/breathing-cycles';
import { fetchBreathingCyclesStart } from '../../store/features/breathing-cycles/breathing-cycles-slice';
import { cleanEditBreathingCycleState } from '../../store/features/edit-breathing-cycle/edit-breathing-cycle-slice';
import { IBreathingCycle } from '../../interfaces/models/breathing-cycle';


const columns: columnFields[] = [
    {
        fieldName: "deviceId",
        columnName: "Device Id",
    },
    {
        fieldName: "deviceActivation",
        columnName: "Device Activation",
        type: "date",
    },
    {
        fieldName: "deviceSettings",
        columnName: "Device Settings",
    },
    {
        fieldName: "batteryVoltage",
        columnName: "Battery Voltage",
    },
    {
        fieldName: "batteryStatus",
        columnName: "Battery Status",
    },
]

const BreathingCycles = () => {

    const breathingCycles = useAppSelector(state => state.breathingCycles.breathingCycles);
    const loading = useAppSelector(state => state.breathingCycles.loading);
    const total = useAppSelector(state => state.breathingCycles.metadata.total);
    const page = useAppSelector(state => state.breathingCycles.metadata.page);
    const limit = 20;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [selectedSortOptions, setSelectedSortOptions] = React.useState<any[]>([]);
    const [sortValue, setSortValue] = React.useState<any>("");

    const onEditClick = (breathingCycle: IBreathingCycle) => { navigate(Routes.BreathingCycle.breathingCyclePage.replace(':id', breathingCycle._id)) }
    const onAddNewClick = () => { navigate(Routes.BreathingCycle.breathingCyclePage.replace(':id', 'add-new')) }

    useEffect(() => {
        dispatch(cleanEditBreathingCycleState());
    }, []);

    const getbreathingCycles = useCallback((page: number, search?: string) => {

        const sortVal = breathingCyclesSortOptions.find((item) => item.id === selectedSortOptions[0])?.value;

        let params: IGetBreathingCyclesParams = {
            sortBy: sortVal?.sortBy || 'createdAt',
            sortDir: sortVal?.sortDir || 'desc',
            skip: ((page - 1) * limit),
            limit: limit,
            page: page
        }

        if (search && search.trim().length > 0) {
            params['search'] = search;
        }
        dispatch(fetchBreathingCyclesStart({ params }))

    }, [selectedSortOptions]);

    return (
        <Table<IBreathingCycle>
            title={'Breathing Cycles'}
            list={breathingCycles}
            columns={columns}
            getItems={getbreathingCycles}
            limit={limit}
            page={page}
            total={total}
            onEditClick={onEditClick}
            withAddNew={true}
            onAddNewClick={onAddNewClick}
            loading={loading}
            sortOptions={breathingCyclesSortOptions}
            selectedSortOptions={selectedSortOptions}
            setSelectedSortOptions={setSelectedSortOptions}
            sortValue={sortValue}
            setSortValue={setSortValue}
            withViewRow={true}
            withoutEditItems={true}
        />
    );
};

export default BreathingCycles;
