import React from "react";
import { Routes as Switch, Route, Navigate } from 'react-router-dom';
import Routes from "../../routes";
import AccountPage from "../accounts/AccountPage";
import Accounts from "../accounts/Accounts";
import Home from "../home/Home";
import Logout from "../logout/Logout";
import MainAppLayout from "../hoc/MainAppLayout";
import {
  AccountFormRules,
  UpdateBoxFormRules,
  UpdateBoxPrizeFormRules,
  UpdateDailyRewardFormRules,
  UpdateGameConfigFormRules,
  UpdateLevelFormRules,
  UpdateLibraryImageFormRules,
  UpdateSceneFormRules,
  UpdateSceneItemFormRules,
  UploadBoxFormRules,
  UploadBoxPrizeFormRules,
  UploadDailyRewardFormRules,
  UploadLevelFormRules,
  UploadLibraryImageFormRules,
  UploadSceneFormRules,
  UploadSceneItemFormRules,
  UpdateBreathingCycleFormRules,
  UploadFirmwareFormRules,
} from "./FormsDefinitions";
import Library from "../img-library/Library";
import LibraryImagePage from "../img-library/LibraryImagePage";
import Scenes from "../scenes/Scenes";
import ScenePage from "../scenes/ScenePage";
import SceneItemPage from "../scenes/items/ItemPage";
import Boxes from "../boxes/Boxes";
import BoxPage from "../boxes/BoxPage";
import PrizePage from "../boxes/prizes/PrizePage";
import Levels from "../levels/Levels";
import LevelPage from "../levels/LevelPage";
import DailyRewards from "../daily-rewards/DailyRewards";
import DailyRewardPage from "../daily-rewards/DailyRewardPage";
import GameConfig from "../game-config/GameConfig";
import Fota from "../fota/Fota";
import Devices from "../devices/Devices";
import BreathingCycles from "../breathing-cycle/BreathingCycles";
import BreathingCyclePage from "../breathing-cycle/BreathingCyclePage";


const routes = [
  { path: Routes.Dashboard.home, mainComponent: Library, metadata: null },
  // { path: Routes.Dashboard.accounts, mainComponent: Accounts, metadata: null },
  // { path: Routes.Account.accountPage, mainComponent: AccountPage, metadata: { editForm: AccountFormRules } },
  { path: Routes.Dashboard.library, mainComponent: Library, metadata: null },
  { path: Routes.Library.imagePage, mainComponent: LibraryImagePage, metadata: { editForm: UpdateLibraryImageFormRules, createForm: UploadLibraryImageFormRules } },
  { path: Routes.Dashboard.scenes, mainComponent: Scenes, metadata: null },
  { path: Routes.Scene.scenePage, mainComponent: ScenePage, metadata: { editForm: UpdateSceneFormRules, createForm: UploadSceneFormRules } },
  { path: Routes.Scene.sceneItemPage, mainComponent: SceneItemPage, metadata: { editForm: UpdateSceneItemFormRules, createForm: UploadSceneItemFormRules } },
  { path: Routes.Dashboard.boxes, mainComponent: Boxes, metadata: null },
  { path: Routes.Box.boxPage, mainComponent: BoxPage, metadata: { editForm: UpdateBoxFormRules, createForm: UploadBoxFormRules } },
  { path: Routes.Box.boxPrizePage, mainComponent: PrizePage, metadata: { editForm: UpdateBoxPrizeFormRules, createForm: UploadBoxPrizeFormRules } },
  { path: Routes.Dashboard.levels, mainComponent: Levels, metadata: null },
  { path: Routes.Level.levelPage, mainComponent: LevelPage, metadata: { editForm: UpdateLevelFormRules, createForm: UploadLevelFormRules } },
  { path: Routes.Dashboard.dailyRewards, mainComponent: DailyRewards, metadata: null },
  { path: Routes.DailyReward.dailyRewardPage, mainComponent: DailyRewardPage, metadata: { editForm: UpdateDailyRewardFormRules, createForm: UploadDailyRewardFormRules } },
  { path: Routes.Dashboard.devices, mainComponent: Devices, metadata: null },
  { path: Routes.Dashboard.gameConfig, mainComponent: GameConfig, metadata: { editForm: UpdateGameConfigFormRules} },
  { path: Routes.Dashboard.fota, mainComponent: Fota, metadata: { editForm: UploadFirmwareFormRules} },
  { path: Routes.Dashboard.breathingCycles, mainComponent: BreathingCycles, metadata: null },
  { path: Routes.BreathingCycle.breathingCyclePage, mainComponent: BreathingCyclePage, metadata: { editForm: UpdateBreathingCycleFormRules} },
]

const NavigationSwitch = () => {
  return (
    <Switch >
      {
        routes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <MainAppLayout Bar={null}>
                  {<route.mainComponent metadata={route.metadata} />}
                </MainAppLayout>}
            ></Route>

          )
        })
      }

      <Route path={Routes.Auth.logout} element={<Logout />} ></Route>
      <Route
        path="*"
        element={<Navigate to={Routes.Dashboard.library} replace />}
      />
    </Switch>
  )
}

export default NavigationSwitch;

