import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../interfaces/base';
import { IEditSceneState } from '../../../interfaces/store/scenes-interfaces';
import { ICreateSceneBody, IGetSceneItemsParams, IGetSceneParams, IUpdateSceneBody } from '../../../interfaces/api/scenes';

const initialState: IEditSceneState = {
    scene: null,
    sceneItems: [],
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanEditSceneState = (state: IEditSceneState) => {
    return initialState;
};


const _getSceneStart = (state: IEditSceneState, { payload }: PayloadAction<IGetSceneParams>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'read';
}

const _getSceneSuccess = (state: IEditSceneState, { payload }: PayloadAction<any>) => {
    state.scene = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _getSceneFailed = (state: IEditSceneState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const _updateSceneStart = (state: IEditSceneState, { payload }: PayloadAction<IUpdateSceneBody>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'update';
}

const _updateSceneSuccess = (state: IEditSceneState, { payload }: PayloadAction<any>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _updateSceneFailed = (state: IEditSceneState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}


const _createSceneStart = (state: IEditSceneState, { payload }: PayloadAction<ICreateSceneBody>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'create';
}

const _createSceneSuccess = (state: IEditSceneState, { payload }: PayloadAction<any>) => {
    state.scene = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _createSceneFailed = (state: IEditSceneState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}


const _deleteSceneStart = (state: IEditSceneState, { payload }: PayloadAction<IGetSceneParams>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'delete';
}

const _deleteSceneSuccess = (state: IEditSceneState, { payload }: PayloadAction<any>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _deleteSceneFailed = (state: IEditSceneState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const _fetchSceneItemsStart = (state: IEditSceneState, { payload }: PayloadAction<{ params: IGetSceneItemsParams }>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'read';
}

const _fetchSceneItemsSuccess = (state: IEditSceneState, { payload }: PayloadAction<any>) => {
    state.sceneItems = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _fetchSceneItemsFailed = (state: IEditSceneState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const _publishSceneStart = (state: IEditSceneState, { payload }: PayloadAction<IGetSceneParams>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
}

const _publishSceneSuccess = (state: IEditSceneState, { payload }: PayloadAction<any>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _publishSceneFailed = (state: IEditSceneState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}


const EditSceneSlice = createSlice({
    name: 'editScene',
    initialState,
    reducers: {
        cleanEditSceneState: _cleanEditSceneState,
        getSceneStart: _getSceneStart,
        getSceneSuccess: _getSceneSuccess,
        getSceneFailed: _getSceneFailed,
        updateSceneStart: _updateSceneStart,
        updateSceneSuccess: _updateSceneSuccess,
        updateSceneFailed: _updateSceneFailed,
        createSceneStart: _createSceneStart,
        createSceneSuccess: _createSceneSuccess,
        createSceneFailed: _createSceneFailed,
        deleteSceneStart: _deleteSceneStart,
        deleteSceneSuccess: _deleteSceneSuccess,
        deleteSceneFailed: _deleteSceneFailed,
        fetchSceneItemsStart: _fetchSceneItemsStart,
        fetchSceneItemsSuccess: _fetchSceneItemsSuccess,
        fetchSceneItemsFailed: _fetchSceneItemsFailed, 
        publishSceneStart: _publishSceneStart,
        publishSceneSuccess: _publishSceneSuccess,
        publishSceneFailed: _publishSceneFailed,
    
    }
})

const { actions, reducer } = EditSceneSlice;
export const {
    cleanEditSceneState,
    getSceneStart,
    getSceneSuccess,
    getSceneFailed,
    updateSceneStart,
    updateSceneSuccess,
    updateSceneFailed,
    createSceneStart,
    createSceneSuccess,
    createSceneFailed,
    deleteSceneStart,
    deleteSceneSuccess,
    deleteSceneFailed,
    fetchSceneItemsStart,
    fetchSceneItemsSuccess,
    fetchSceneItemsFailed,
    publishSceneStart,
    publishSceneSuccess,
    publishSceneFailed,

} = actions;

export default reducer;
