import axiosInstance from '../../config/axios';
import { IGetDevicesParams } from '../../interfaces/api/devices';
import ServerRoutes from './routes';
import { getParamsUrl, handleAxiosError } from './utils/common';


const getDevices = async (params: IGetDevicesParams) => {
    try {
        let url = getParamsUrl(ServerRoutes.devices.devices, params);
        const { status, data } = await axiosInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const devicesApi = {
    getDevices,
}

export default devicesApi;