import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../interfaces/base';
import { IEditSceneItemState } from '../../../interfaces/store/scenes-interfaces';
import { ICreateSceneItemBody, IGetSceneItemParams, IRemoveSceneItemsParams } from '../../../interfaces/api/scenes';

const initialState: IEditSceneItemState = {
    item: null,
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanEditSceneItemState = (state: IEditSceneItemState) => {
    return initialState;
};


const _getSceneItemStart = (state: IEditSceneItemState, { payload }: PayloadAction<IGetSceneItemParams>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'read';
}

const _getSceneItemSuccess = (state: IEditSceneItemState, { payload }: PayloadAction<any>) => {
    state.item = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _getSceneItemFailed = (state: IEditSceneItemState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const _updateSceneItemStart = (state: IEditSceneItemState, { payload }: PayloadAction<any>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'update';
}

const _updateSceneItemSuccess = (state: IEditSceneItemState, { payload }: PayloadAction<any>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _updateSceneItemFailed = (state: IEditSceneItemState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}


const _createSceneItemStart = (state: IEditSceneItemState, { payload }: PayloadAction<ICreateSceneItemBody>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'create';
}

const _createSceneItemSuccess = (state: IEditSceneItemState, { payload }: PayloadAction<any>) => {
    state.item = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _createSceneItemFailed = (state: IEditSceneItemState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}


const _deleteSceneItemStart = (state: IEditSceneItemState, { payload }: PayloadAction<IRemoveSceneItemsParams>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'delete';
}

const _deleteSceneItemSuccess = (state: IEditSceneItemState, { payload }: PayloadAction<any>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _deleteSceneItemFailed = (state: IEditSceneItemState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const EditSceneItemSlice = createSlice({
    name: 'editSceneItem',
    initialState,
    reducers: {
        cleanEditSceneItemState: _cleanEditSceneItemState,
        getSceneItemStart: _getSceneItemStart,
        getSceneItemSuccess: _getSceneItemSuccess,
        getSceneItemFailed: _getSceneItemFailed,
        updateSceneItemStart: _updateSceneItemStart,
        updateSceneItemSuccess: _updateSceneItemSuccess,
        updateSceneItemFailed: _updateSceneItemFailed,
        createSceneItemStart: _createSceneItemStart,
        createSceneItemSuccess: _createSceneItemSuccess,
        createSceneItemFailed: _createSceneItemFailed,
        deleteSceneItemStart: _deleteSceneItemStart,
        deleteSceneItemSuccess: _deleteSceneItemSuccess,
        deleteSceneItemFailed: _deleteSceneItemFailed,
    }
})

const { actions, reducer } = EditSceneItemSlice;
export const {
    cleanEditSceneItemState,
    getSceneItemStart,
    getSceneItemSuccess,
    getSceneItemFailed,
    updateSceneItemStart, 
    updateSceneItemSuccess, 
    updateSceneItemFailed, 
    createSceneItemStart, 
    createSceneItemSuccess, 
    createSceneItemFailed, 
    deleteSceneItemStart, 
    deleteSceneItemSuccess, 
    deleteSceneItemFailed,
} = actions;

export default reducer;
