import { IDeleteFromS3, IGetFilesParams } from './../../interfaces/api/files';
import axios from 'axios';

import axiosInstance from '../../config/axios';
import ServerRoutes from './routes';
import { getParamsUrl, handleAxiosError } from './utils/common';
import { IGetPersignedUrlParams, IUploadToS3 } from '../../interfaces/api/files';

import env from '../../config';

const getPresignedUrl = async (params: IGetPersignedUrlParams) => {
    try {
        let url = getParamsUrl(ServerRoutes.files.presignedUrl, params);
        const { status, data } = await axiosInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const uplaodFile = async (params: IUploadToS3) => {
    try {

        let formData = new FormData();
        formData.append("file", params.file);

        let { url, file, name, description } = params;

        let headers: any = {
            "Content-Type": file.type,
            'x-goog-meta-fileName': name || ''
        };

        if (description && description !== '') {
            headers = { ...headers, 'x-goog-meta-description': description }
        }
        headers = { ...headers, 'x-goog-meta-env': env.env };
        const { status, data } = await axios.put(url, file, { headers: headers });

        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const deleteFile = async (params: IDeleteFromS3) => {
    try {
        let url = getParamsUrl(ServerRoutes.files.files, params);
        const { status, data } = await axiosInstance.delete(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 400:
                message = 'File not found';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const getFiles = async (params: IGetFilesParams) => {
    try {
        let url = getParamsUrl(ServerRoutes.files.files, params);
        const { status, data } = await axiosInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const filesApi = {
    getPresignedUrl,
    uplaodFile,
    deleteFile,
    getFiles
}

export default filesApi;