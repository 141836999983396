import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUpdateGameConfigBody } from '../../../interfaces/api/game-config';
import { IError } from '../../../interfaces/base';
import { IGameConfigState } from '../../../interfaces/store/game-config-interfaces';

const initialState: IGameConfigState = {
    gameConfig: null,
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanGameConfigState = (state: IGameConfigState) => {
    return initialState;
};

const _getGameConfigStart = (state: IGameConfigState) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'read';
}

const _getGameConfigSuccess = (state: IGameConfigState, { payload }: PayloadAction<any>) => {
    state.gameConfig = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _getGameConfigFailed = (state: IGameConfigState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const _updateGameConfigStart = (state: IGameConfigState, { payload }: PayloadAction<IUpdateGameConfigBody>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'update';
}

const _updateGameConfigSuccess = (state: IGameConfigState, { payload }: PayloadAction<any>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _updateGameConfigFailed = (state: IGameConfigState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}


const GameConfigSlice = createSlice({
    name: 'gameConfig',
    initialState,
    reducers: {
        cleanGameConfigState: _cleanGameConfigState,
        getGameConfigStart: _getGameConfigStart,
        getGameConfigSuccess: _getGameConfigSuccess,
        getGameConfigFailed: _getGameConfigFailed,
        updateGameConfigStart: _updateGameConfigStart,
        updateGameConfigSuccess: _updateGameConfigSuccess,
        updateGameConfigFailed: _updateGameConfigFailed,
    }
})

const { actions, reducer } = GameConfigSlice;
export const {
    cleanGameConfigState,
    getGameConfigStart,
    getGameConfigSuccess,
    getGameConfigFailed,
    updateGameConfigStart,
    updateGameConfigSuccess,
    updateGameConfigFailed,
} = actions;

export default reducer;
