
const auth = {
    userToken: '/api/v1/auth/token',
    verifyCode: '/api/v1/auth/code',
    resendCode: '/api/v1/auth/code/resend',
    userinfo: '/api/v1/auth/userinfo',
    logout: '/api/v1/auth/logout',
    updatePassword: '/api/v1/auth/updatePassword',
    forgotPassord: '/api/v1/auth/forgot',
    forgotPassword: '/api/v1/auth/forgot',
    resetPassword: '/api/v1/auth/reset',
}

const accounts = {
    accounts: '/api/v1/account/getAll',
    getAccount: '/api/v1/account/get',
}

const files = {
    files: '/api/v1/files/paginate',
    presignedUrl: '/api/v1/files/presigned-url',
    getFile: '/api/v1/files/get',
    deleteFile: '/api/v1/files/delete',
    updateFile: '/api/v1/files/update'
}

const scenes = {
    scenes: '/api/v1/scene/getAll',
    createScene: '/api/v1/scene/create',
    getScene: '/api/v1/scene/get',
    updateScene: '/api/v1/scene/update',
    addSceneItem: '/api/v1/scene/addItem',
    removeSceneItem: '/api/v1/scene/removeItem',
    publishScene: '/api/v1/scene/publish',
}

const sceneItem = {
    updateSceneItem: '/api/v1/SceneItem/update',
    getSceneItem: '/api/v1/SceneItem/get',
    getSceneItemsByScene: '/api/v1/SceneItem/getByScene',
}


const boxes = {
    boxes: '/api/v1/giftBox/getAll', 
    createBox: '/api/v1/giftBox/create', 
    getBox: '/api/v1/giftBox/get', 
    updateBox: '/api/v1/giftBox/update', 
    addBoxPrize: '/api/v1/giftBox/addPrize', 
    removeBoxPrize: '/api/v1/giftBox/removePrize', 
    publishBox: '/api/v1/giftBox/publish',
    unpublishBox: '/api/v1/giftBox/unpublish',
    deleteBox: '/api/v1/giftBox/delete',
}

const boxPrize = {
    updateBoxPrize: '/api/v1/giftBoxPrize/update',
    getBoxPrize: '/api/v1/giftBoxPrize/get',
    getBoxPrizesByBox: '/api/v1/giftBoxPrize/getByGIftBox',
}

const levels = {
    levels: '/api/v1/levelReward/getAll', 
    createLevel: '/api/v1/levelReward/create', 
    getLevel: '/api/v1/levelReward/get',
    updateLevel: '/api/v1/levelReward/update', 
}

const dailyRewards = {
    dailyRewards: '/api/v1/dailyReward/getAll',  
    createDailyReward: '/api/v1/dailyReward/create', 
    getDailyReward: '/api/v1/dailyReward/get', 
    updateDailyReward: '/api/v1/dailyReward/update',  
}

const gameConfig = {
    getGameConfig: '/api/v1/gameConfig/get', 
    updateGameConfig: '/api/v1/gameConfig/update',  
}

const devices = {
    devices: '/api/v1/device/getAll',  
}

const breathingCycles = {
    breathingCycles: '/api/v1/breathingCycleSession/getAll', 
    getBreathingCycle: '/api/v1/breathingCycleSession/get',
}

const fota = {
    getFirmwares: '/api/v1/firmware/getAll', 
    publishFirmware: '/api/v1/firmware/publish',  
    createFirmware: '/api/v1/firmware/create', 
    deleteFirmware: '/api/v1/firmware/delete', 
}

const ServerRoutes = {
    auth,
    accounts,
    files,
    scenes,
    sceneItem,
    boxes,
    boxPrize,
    levels,
    dailyRewards,
    gameConfig,
    fota,
    devices,
    breathingCycles
}

export default ServerRoutes;