import axiosInstance from '../../config/axios';
import ServerRoutes from './routes';
import { getParamsUrl, handleAxiosError } from './utils/common';
import {
    ICreateSceneBody,
    ICreateSceneItemBody,
    IGetSceneItemParams,
    IGetSceneItemsParams,
    IGetSceneParams,
    IGetScenesParams,
    IRemoveSceneItemsParams,
    IUpdateSceneBody,
    IUpdateSceneItemBody
} from '../../interfaces/api/scenes';

const getScenes = async (params: IGetScenesParams) => {
    try {
        let url = getParamsUrl(ServerRoutes.scenes.scenes, params);
        const { status, data } = await axiosInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}


const createScene = async (sceneInfo: ICreateSceneBody) => {
    try {
        const { status, data } = await axiosInstance.post(ServerRoutes.scenes.createScene, sceneInfo);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const updateScene = async (sceneInfo: IUpdateSceneBody) => {
    try {
        const { status, data } = await axiosInstance.put(ServerRoutes.scenes.updateScene, sceneInfo);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const getScene = async (params: IGetSceneParams) => {
    try {
        let url = getParamsUrl(ServerRoutes.scenes.getScene, params);
        const { status, data } = await axiosInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}


const createSceneItem = async (sceneItemInfo: ICreateSceneItemBody) => {
    try {
        const { status, data } = await axiosInstance.put(ServerRoutes.scenes.addSceneItem, sceneItemInfo);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const getSceneItems = async (params: IGetSceneItemsParams) => {
    try {
        let url = getParamsUrl(ServerRoutes.sceneItem.getSceneItemsByScene, params);
        const { status, data } = await axiosInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const getSceneItem = async (params: IGetSceneItemParams) => {
    try {
        let url = getParamsUrl(ServerRoutes.sceneItem.getSceneItem, params);
        const { status, data } = await axiosInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const removeSceneItem = async (sceneItemInfo: IRemoveSceneItemsParams) => {
    try {
        const { status, data } = await axiosInstance.put(ServerRoutes.scenes.removeSceneItem, sceneItemInfo);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const publishScene = async (sceneId: IGetSceneParams) => {
    try {
        const { status, data } = await axiosInstance.put(ServerRoutes.scenes.publishScene, sceneId);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}


const updateSceneItem = async (sceneItemInfo: IUpdateSceneItemBody) => {
    try {
        const { status, data } = await axiosInstance.put(ServerRoutes.sceneItem.updateSceneItem, sceneItemInfo);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const scenesApi = {
    getScenes,
    createScene,
    updateScene,
    getScene,
    createSceneItem,
    getSceneItems,
    getSceneItem,
    removeSceneItem,
    publishScene,
    updateSceneItem
}

export default scenesApi;