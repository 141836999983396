import { v4 as uuidv4 } from 'uuid';
import jwt_decode from "jwt-decode";

export interface DecodedData {
    exp: number;
    [key: string]: any;
};
export const getTokenRefreshTime = (token: string): number => {
    const fiveMinutes = 300000;
    const decoded: DecodedData = jwt_decode(token);
    const expiration = new Date(decoded.exp * 1000);
    let tokenTimeLeft = expiration.getTime() - new Date().getTime() - fiveMinutes;
    if (tokenTimeLeft <= 0) {
        return 1;
    }
    return tokenTimeLeft;
};

export const initialsByName = (name: string) => {
    if (!name) {
        return '';
    }
    const names = name ? name.split(' ') : '';
    let initials = names.length === 1 ? names[0].substring(0, 2).toUpperCase() :
        names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

export const isNumber = (str: string): boolean => {
    if (typeof str !== 'string') {
        return false;
    }

    if (str.trim() === '') {
        return false;
    }

    return !Number.isNaN(Number(str));
}

export const isStringEmpty = (str: string): boolean => {
    try {
        if (!str) {
            return true;
        }
        if (str.trim().length === 0) {
            return true;
        }
        return false;
    } catch (error: any) {
        return true;
    }
}


export const getId = () => {
    return uuidv4()
}

export const getWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

export const RemoveArrayDuplicates = (array: any[], key: any) => {
    return array.reduce((arr, item) => {
        const removed = arr.filter((i: { [x: string]: any; }) => i[key] !== item[key]);
        return [...removed, item];
    }, []);
};

export const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
        await navigator.clipboard.writeText(text);
    }
}

export const delayedFunction = async (fn: any, ms: number) => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(fn()), ms)
    })
}