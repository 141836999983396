import axiosInstance, { updateToken2fa } from '../../config/axios';
import { IForgotPassword } from '../../interfaces/components/forms/login';
import ServerRoutes from './routes';
import { handleAxiosError } from './utils/common';

const login = async (creds: { email: string, password: string }) => {
    try {
        const { status, data } = await axiosInstance.post(ServerRoutes.auth.userToken, { ...creds, grant_type: 'password' });
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 404:
                message = 'User not found';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const verify2faCode = async (code: string, accesToken: string) => {
    try {
        updateToken2fa(accesToken);
        const { status, data } = await axiosInstance.post(ServerRoutes.auth.verifyCode, { code });
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 400:
                message = 'Wrong code';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const resendCode = async (accesToken: string) => {
    try {
        updateToken2fa(accesToken);
        const { status, data } = await axiosInstance.get(ServerRoutes.auth.resendCode);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 400:
                message = 'Code is expired';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const refreshTokenLogin = async (refresh_token: string) => {
    try {
        const { status, data } = await axiosInstance.post(ServerRoutes.auth.userToken, { refresh_token, grant_type: 'refresh_token' });
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 400:
                message = 'Token is expired';
                break;
            case 401:
                message = 'Invalid refresh token';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const logout = async (token: string) => {
    try {
        const url = ServerRoutes.auth.logout + '?refresh_token_hint=' + token;
        const { status, data } = await axiosInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const forgotPassword = async (body: IForgotPassword) => {

    try {
        const { status, data } = await axiosInstance.post(ServerRoutes.auth.forgotPassword, body);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}


const resetPassword = async (body: { password: string }) => {

    try {
        const { status, data } = await axiosInstance.post(ServerRoutes.auth.resetPassword, body);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}


const authUserApi = {
    login,
    verify2faCode,
    refreshTokenLogin,
    logout,
    resendCode,
    forgotPassword,
    resetPassword,
}

export default authUserApi;