import axiosInstance from '../../config/axios';
import { ICreateFirmwareBody, IDeleteFirmwareParams, IGetFirmwaresParams, IPublishFirmwareBody } from '../../interfaces/api/fota';
import ServerRoutes from './routes';
import { getParamsUrl, handleAxiosError } from './utils/common';


const createFirmware = async (firmware: ICreateFirmwareBody) => {
    try {
        const { status, data } = await axiosInstance.post(ServerRoutes.fota.createFirmware, firmware);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const deleteFirmware = async (params: IDeleteFirmwareParams) => {
    try {
        let url = getParamsUrl(ServerRoutes.fota.deleteFirmware, params);
        const { status, data } = await axiosInstance.delete(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 404:
                message = 'Firmware not found.';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const publishFirmware = async (fotaInfo: IPublishFirmwareBody) => {
    try {
        if (fotaInfo.publish) {
            const { status, data } = await axiosInstance.put(ServerRoutes.fota.publishFirmware, { firmwareId: fotaInfo.firmwareId });
            return { status, data };
        }
        else { ////////////// nned to change to unpublish 
            const { status, data } = await axiosInstance.put(ServerRoutes.fota.publishFirmware, { firmwareId: fotaInfo.firmwareId });
            return { status, data };
        }
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const getFirmwares = async (params: IGetFirmwaresParams) => {
    try {
        let url = getParamsUrl(ServerRoutes.fota.getFirmwares, params);
        const { status, data } = await axiosInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}


const fotaApi = {
    createFirmware,
    publishFirmware,
    getFirmwares,
    deleteFirmware,
}

export default fotaApi;