import React, { useCallback, useState } from "react";
import AlertPopup from "../common/AlertPopup";
import Switch from "../common/Switch";
import { FlexColumn } from "../common/containers";
import usePermission from "../../hooks/usePermission";

export const SwitchInput = (props: { disabledSwitch?: boolean, checked: boolean, setChecked: any, withAlert: boolean, permission?:string }) => {

  const [showPublishModal, setShowPublishModal] = useState(false);
  const checkedString = props.checked ? "Published" : "Publish";

  const openPublishPopup = () => {
    setShowPublishModal(true)
  }

  const onChange = useCallback((ev: any) => {
    props.setChecked(true);
    setShowPublishModal(false);
  }, [props.setChecked]);


  return (
    <FlexColumn style={{ opacity: props.checked ? 0.5 : 1, pointerEvents: props.checked ? "none" : "auto" }}>
      <AlertPopup
        title={"Are you sure you want to publish?"}
        subText={"Once you publish you can not undo it"}
        open={showPublishModal}
        onOpenChange={setShowPublishModal}
        onActionClick={onChange}
        actionTitle={"Publish"} />

      <Switch
        label={`${checkedString}`}
        checked={props.checked}
        disabledSwitch={props.disabledSwitch}
        setChecked={props.setChecked}
        permission={props.permission}
        onChange={openPublishPopup} />
    </FlexColumn>
  );
};
export default SwitchInput;