import React, { useCallback, useEffect } from 'react';
import Table, { columnFields } from '../table/Table';
import { useNavigate } from 'react-router-dom';
import Routes from '../../routes';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { Level, levelTypeOptions } from '../../interfaces/models/level';
import { IGetLevelsParams, levelsSortOptions } from '../../interfaces/api/levels';
import { fetchLevelsStart } from '../../store/features/levels/levels-slice';
import { cleanEditLevelState } from '../../store/features/edit-level/edit-level-slice';


const columns: columnFields[] = [
    {
        fieldName: "level",
        columnName: "Level",
    },
    {
        fieldName: "coins",
        columnName: "Coins",
    },
    {
        fieldName: "other",
        columnName: "Other",
        type: 'dropdownVal'
    },
    {
        fieldName: "otherAmount",
        columnName: "Other Amount",
    },
    {
        fieldName: "createdAt",
        columnName: "Created At",
        type: "date",
    },
]

const Levels = () => {

    const levels = useAppSelector(state => state.levels.levels);
    const loading = useAppSelector(state => state.levels.loading);
    const total = useAppSelector(state => state.levels.metadata.total);
    const page = useAppSelector(state => state.levels.metadata.page);
    const limit = 20;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [selectedSortOptions, setSelectedSortOptions] = React.useState<any[]>([]);
    const [sortValue, setSortValue] = React.useState<any>("");

    const onEditClick = (level: Level) => { navigate(Routes.Level.levelPage.replace(':id', level._id)) }
    const onAddNewClick = () => { navigate(Routes.Level.levelPage.replace(':id', 'add-new')) }

    useEffect(() => {
        dispatch(cleanEditLevelState());
    }, []);

    const getlevels = useCallback((page: number, search?: string) => {

        const sortVal = levelsSortOptions.find((item) => item.id === selectedSortOptions[0])?.value;

        let params: IGetLevelsParams = {
            sortBy: sortVal?.sortBy || 'createdAt',
            sortDir: sortVal?.sortDir || 'desc',
            skip: ((page - 1) * limit),
            limit: limit,
            page: page
        }

        if (search && search.trim().length > 0) {
            params['search'] = search;
        }
        dispatch(fetchLevelsStart({ params }))

    }, [selectedSortOptions]);

    return (
        <Table<Level>
            title={'Levels'}
            list={levels}
            columns={columns}
            getItems={getlevels}
            limit={limit}
            page={page}
            total={total}
            onEditClick={onEditClick}
            withAddNew={true}
            onAddNewClick={onAddNewClick}
            loading={loading}
            sortOptions={levelsSortOptions}
            selectedSortOptions={selectedSortOptions}
            setSelectedSortOptions={setSelectedSortOptions}
            sortValue={sortValue}
            setSortValue={setSortValue}
            withSearch={true}
            dropdownOptions={{other: levelTypeOptions}}
        />
    );
};

export default Levels;
