import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../interfaces/base';
import { IEditBoxState } from '../../../interfaces/store/boxes-interfaces';
import { ICreateBoxBody, IGetBoxParams, IGetBoxPrizesParams, IUpdateBoxBody } from '../../../interfaces/api/boxes';

const initialState: IEditBoxState = {
    box: null,
    boxPrizes: [],
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanEditBoxState = (state: IEditBoxState) => {
    return initialState;
};


const _getBoxStart = (state: IEditBoxState, { payload }: PayloadAction<IGetBoxParams>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start'; 
    state.operation = 'read';
}

const _getBoxSuccess = (state: IEditBoxState, { payload }: PayloadAction<any>) => {
    state.box = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _getBoxFailed = (state: IEditBoxState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const _updateBoxStart = (state: IEditBoxState, { payload }: PayloadAction<IUpdateBoxBody>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'update';
}

const _updateBoxSuccess = (state: IEditBoxState, { payload }: PayloadAction<any>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _updateBoxFailed = (state: IEditBoxState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}


const _createBoxStart = (state: IEditBoxState, { payload }: PayloadAction<ICreateBoxBody>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'create';
}

const _createBoxSuccess = (state: IEditBoxState, { payload }: PayloadAction<any>) => {
    state.box = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _createBoxFailed = (state: IEditBoxState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}


const _deleteBoxStart = (state: IEditBoxState, { payload }: PayloadAction<IGetBoxParams>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'delete';
}

const _deleteBoxSuccess = (state: IEditBoxState, { payload }: PayloadAction<any>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _deleteBoxFailed = (state: IEditBoxState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const _fetchBoxPrizesStart = (state: IEditBoxState, { payload }: PayloadAction<{ params: IGetBoxPrizesParams }>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'read';
}

const _fetchBoxPrizesSuccess = (state: IEditBoxState, { payload }: PayloadAction<any>) => {
    state.boxPrizes = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _fetchBoxPrizesFailed = (state: IEditBoxState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const _publishBoxStart = (state: IEditBoxState, { payload }: PayloadAction<{params: { giftBoxId: string, publish: boolean }}>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
}

const _publishBoxSuccess = (state: IEditBoxState, { payload }: PayloadAction<any>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _publishBoxFailed = (state: IEditBoxState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}


const EditBoxSlice = createSlice({
    name: 'editBox',
    initialState,
    reducers: {
        cleanEditBoxState: _cleanEditBoxState,
        getBoxStart: _getBoxStart,
        getBoxSuccess: _getBoxSuccess,
        getBoxFailed: _getBoxFailed,
        updateBoxStart: _updateBoxStart,
        updateBoxSuccess: _updateBoxSuccess,
        updateBoxFailed: _updateBoxFailed,
        createBoxStart: _createBoxStart,
        createBoxSuccess: _createBoxSuccess,
        createBoxFailed: _createBoxFailed,
        deleteBoxStart: _deleteBoxStart,
        deleteBoxSuccess: _deleteBoxSuccess,
        deleteBoxFailed: _deleteBoxFailed,
        fetchBoxPrizesStart: _fetchBoxPrizesStart,
        fetchBoxPrizesSuccess: _fetchBoxPrizesSuccess,
        fetchBoxPrizesFailed: _fetchBoxPrizesFailed, 
        publishBoxStart: _publishBoxStart,
        publishBoxSuccess: _publishBoxSuccess,
        publishBoxFailed: _publishBoxFailed,
    }
})

const { actions, reducer } = EditBoxSlice;
export const {
    cleanEditBoxState,
    getBoxStart,
    getBoxSuccess,
    getBoxFailed,
    updateBoxStart,
    updateBoxSuccess,
    updateBoxFailed,
    createBoxStart,
    createBoxSuccess,
    createBoxFailed,
    deleteBoxStart,
    deleteBoxSuccess,
    deleteBoxFailed,
    fetchBoxPrizesStart,
    fetchBoxPrizesSuccess,
    fetchBoxPrizesFailed,
    publishBoxStart,
    publishBoxSuccess,
    publishBoxFailed,

} = actions;

export default reducer;
