import axiosInstance from '../../config/axios';
import ServerRoutes from './routes';
import { getParamsUrl, handleAxiosError } from './utils/common';
import {
    ICreateLevelBody,
    IGetLevelParams,
    IGetLevelsParams,
    IUpdateLevelBody,
} from '../../interfaces/api/levels';

const getLevels = async (params: IGetLevelsParams) => {
    try {
        let url = getParamsUrl(ServerRoutes.levels.levels, params);
        const { status, data } = await axiosInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}


const createLevel = async (levelInfo: ICreateLevelBody) => {
    try {
        const { status, data } = await axiosInstance.post(ServerRoutes.levels.createLevel, levelInfo);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const updateLevel = async (levelInfo: IUpdateLevelBody) => {
    try {
        const { status, data } = await axiosInstance.put(ServerRoutes.levels.updateLevel, levelInfo);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const getLevel = async (params: IGetLevelParams) => {
    try {
        let url = getParamsUrl(ServerRoutes.levels.getLevel, params);
        const { status, data } = await axiosInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}


const levelsApi = {
    getLevels,
    createLevel,
    getLevel,
    updateLevel,
}

export default levelsApi;