import React from 'react';
import Routes from '../../routes';
import Nav from './StyledComponents';
import { Text } from "@fluentui/react-components";

const NavRoutes = () => {
    return (
        <Nav.NavBarContainer >
            <Nav.NavBar>
                <Nav.NavItem>
                    <Nav.NavStyledNavLink to={Routes.Dashboard.library}>
                        <Text> Library</Text>
                    </Nav.NavStyledNavLink>
                </Nav.NavItem>
                <Nav.NavItem>
                    <Nav.NavStyledNavLink to={Routes.Dashboard.scenes}>
                        <Text> Scenes</Text>
                    </Nav.NavStyledNavLink>
                </Nav.NavItem>
                <Nav.NavItem>
                    <Nav.NavStyledNavLink to={Routes.Dashboard.boxes}>
                        <Text> Boxes</Text>
                    </Nav.NavStyledNavLink>
                </Nav.NavItem>
                <Nav.NavItem>
                    <Nav.NavStyledNavLink to={Routes.Dashboard.levels}>
                        <Text> Levels</Text>
                    </Nav.NavStyledNavLink>
                </Nav.NavItem>
                <Nav.NavItem>
                    <Nav.NavStyledNavLink to={Routes.Dashboard.dailyRewards}>
                        <Text> Daily Rewards </Text>
                    </Nav.NavStyledNavLink>
                </Nav.NavItem>
                <Nav.NavItem>
                    <Nav.NavStyledNavLink to={Routes.Dashboard.devices}>
                        <Text> Devices </Text>
                    </Nav.NavStyledNavLink>
                </Nav.NavItem>
                <Nav.NavItem>
                    <Nav.NavStyledNavLink to={Routes.Dashboard.breathingCycles}>
                        <Text> Breathing Cycles </Text>
                    </Nav.NavStyledNavLink>
                </Nav.NavItem>
                <Nav.NavItem>
                    <Nav.NavStyledNavLink to={Routes.Dashboard.gameConfig}>
                        <Text> Settings </Text>
                    </Nav.NavStyledNavLink>
                </Nav.NavItem>
                <Nav.NavItem>
                    <Nav.NavStyledNavLink to={Routes.Dashboard.fota}>
                        <Text> Fota </Text>
                    </Nav.NavStyledNavLink>
                </Nav.NavItem>
            </Nav.NavBar>
        </Nav.NavBarContainer>
    );
};

export default NavRoutes;
