import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../interfaces/base';
import { IEditBreathingCycleState } from '../../../interfaces/store/breathing-cycles-interfaces';
import { IGetBreathingCycleParams } from '../../../interfaces/api/breathing-cycles';

const initialState: IEditBreathingCycleState = {
    breathingCycle: null,
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanEditBreathingCycleState = (state: IEditBreathingCycleState) => {
    return initialState;
};


const _getBreathingCycleStart = (state: IEditBreathingCycleState, { payload }: PayloadAction<IGetBreathingCycleParams>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start'; 
    state.operation = 'read';
}

const _getBreathingCycleSuccess = (state: IEditBreathingCycleState, { payload }: PayloadAction<any>) => {
    state.breathingCycle = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _getBreathingCycleFailed = (state: IEditBreathingCycleState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}


const EditBreathingCycleSlice = createSlice({
    name: 'editBreathingCycle',
    initialState,
    reducers: {
        cleanEditBreathingCycleState: _cleanEditBreathingCycleState,
        getBreathingCycleStart: _getBreathingCycleStart,
        getBreathingCycleSuccess: _getBreathingCycleSuccess,
        getBreathingCycleFailed: _getBreathingCycleFailed,
    }
})

const { actions, reducer } = EditBreathingCycleSlice;
export const {
    cleanEditBreathingCycleState,
    getBreathingCycleStart,
    getBreathingCycleSuccess,
    getBreathingCycleFailed,
} = actions;

export default reducer;
