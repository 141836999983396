import { dailyRewardTypeOptions, giftBoxTypeOptions } from "../../interfaces/models/daily-reward"


export const AccountFormRules = {
    fullName: {
        fieldName: 'Full Name',
        required: false,
        editable: false,
    },
    email: {
        fieldName: 'Email',
        required: false,
        editable: false,
    },
    phone: {
        fieldName: 'Phone',
        required: false,
        editable: false,
    },
    age: {
        fieldName: 'Age',
        required: false,
        editable: false,
    },
    gender: {
        fieldName: 'Gender',
        required: false,
        editable: false,
    },
    dob: {
        fieldName: 'Date of Birth',
        required: false,
        editable: false,
    },
    subStart: {
        fieldName: 'Sub Start',
        required: false,
        editable: false,
    },
    subEnd: {
        fieldName: 'Sub End',
        required: false,
        editable: false,
    }
}

// --- library
export const UploadLibraryImageFormRules = {
    image: {
        fieldName: 'Image',
        required: true,
        editable: true,
        type: 'image',
    },
    name: {
        fieldName: 'Name',
        required: true,
        editable: true,
    },
    description: {
        fieldName: 'Description',
        required: false,
        editable: true,
        type: 'textarea',
    },

}

export const UpdateLibraryImageFormRules = {
    mediaLink: {
        fieldName: 'Image',
        required: false,
        editable: false,
        type: 'image',
    },
    name: {
        fieldName: 'Name',
        required: true,
        editable: true,
    },
    description: {
        fieldName: 'Description',
        required: false,
        editable: true,
        type: 'textarea',
    },
}

// --- scenes
export const UploadSceneFormRules = {
    name: {
        fieldName: 'Name',
        required: true,
        editable: true,
    },
    serialNumber: {
        fieldName: 'Serial Number',
        required: true,
        editable: true,
        type: 'number'
    },
    requiredDeviceUses: {
        fieldName: 'Required Device Uses',
        required: true,
        editable: true,
        type: 'number'
    },
    imageBackgroundPath: {
        fieldName: 'Background Image',
        required: true,
        editable: true,
        type: 'image',
    },
    imageCompletedPath: {
        fieldName: 'Completed Image',
        required: true,
        editable: true,
        type: 'image',
    },
    imageThumbnailPath: {
        fieldName: 'Thumbnail Image',
        required: true,
        editable: true,
        type: 'image',
    },

}

export const UpdateSceneFormRules = {
    name: {
        fieldName: 'Name',
        required: true,
        editable: true,
    },
    serialNumber: {
        fieldName: 'Serial Number',
        required: true,
        editable: true,
        type: 'number'
    },
    requiredDeviceUses: {
        fieldName: 'Required Device Uses',
        required: true,
        editable: true,
        type: 'number'
    },
    imageBackgroundPath: {
        fieldName: 'Background Image',
        required: true,
        editable: true,
        type: 'image',
    },
    imageCompletedPath: {
        fieldName: 'Completed Image',
        required: true,
        editable: true,
        type: 'image',
    },
    imageThumbnailPath: {
        fieldName: 'Thumbnail Image',
        required: true,
        editable: true,
        type: 'image',
    },

}

// --- items
export const UploadSceneItemFormRules = {
    name: {
        fieldName: 'Name',
        required: true,
        editable: true,
    },
    serialNumber: {
        fieldName: 'Serial Number',
        required: true,
        editable: true,
        type: 'number'
    },
    price: {
        fieldName: 'Price',
        required: true,
        editable: true,
        type: 'number'
    },
    imagePath: {
        fieldName: 'Image',
        required: true,
        editable: true,
        type: 'image',
    },
    thumbnailPath: {
        fieldName: 'Thumbnail Image',
        required: true,
        editable: true,
        type: 'image',
    },
    dependsOn: {
        fieldName: 'Depends On',
        required: true,
        editable: true,
        type: 'number'
    },
    deliveryTime: {
        fieldName: 'Delivery Time (minutes)',
        required: true,
        editable: true,
        type: 'number'
    },
    // coordX: {
    //     fieldName: 'Coord X',
    //     required: false,
    //     editable: true,
    //     type: 'number'
    // },
    // coordY: {
    //     fieldName: 'Coord Y',
    //     required: false,
    //     editable: true,
    //     type: 'number'
    // },
    // percentX: {
    //     fieldName: 'Percent X',
    //     required: false,
    //     editable: true,
    //     type: 'number'
    // },
    // percentY: {
    //     fieldName: 'Percent Y',
    //     required: false,
    //     editable: true,
    //     type: 'number'
    // },
}

export const UpdateSceneItemFormRules = {
    name: {
        fieldName: 'Name',
        required: true,
        editable: true,
    },
    serialNumber: {
        fieldName: 'Serial Number',
        required: true,
        editable: true,
        type: 'number'
    },
    price: {
        fieldName: 'Price',
        required: true,
        editable: true,
        type: 'number'
    },
    imagePath: {
        fieldName: 'Image',
        required: true,
        editable: true,
        type: 'image',
    },
    thumbnailPath: {
        fieldName: 'Thumbnail Image',
        required: true,
        editable: true,
        type: 'image',
    },
    dependsOn: {
        fieldName: 'Depends On',
        required: true,
        editable: true,
        type: 'number'
    },
    deliveryTime: {
        fieldName: 'Delivery Time (minutes)',
        required: true,
        editable: true,
        type: 'number'
    },
    // coordX: {
    //     fieldName: 'Coord X',
    //     required: false,
    //     editable: true,
    //     type: 'number'
    // },
    // coordY: {
    //     fieldName: 'Coord Y',
    //     required: false,
    //     editable: true,
    //     type: 'number'
    // },
    // percentX: {
    //     fieldName: 'Percent X',
    //     required: false,
    //     editable: true,
    //     type: 'number'
    // },
    // percentY: {
    //     fieldName: 'Percent Y',
    //     required: false,
    //     editable: true,
    //     type: 'number'
    // },
}

// --- boxes
export const UploadBoxFormRules = {
    name: {
        fieldName: 'Name',
        required: true,
        editable: true,
    },
    price: {
        fieldName: 'Price',
        required: true,
        editable: true,
        type: 'number'
    },
    boxType: {
        fieldName: 'Type',
        required: true,
        editable: true,
        type: 'dropdown'
    },

}

export const UpdateBoxFormRules = {
    name: {
        fieldName: 'Name',
        required: true,
        editable: true,
    },
    price: {
        fieldName: 'Price',
        required: true,
        editable: true,
        type: 'number'
    },
    boxType: {
        fieldName: 'Type',
        required: true,
        editable: true,
        type: 'dropdown'
    },

}

// --- box prize
export const UploadBoxPrizeFormRules = {
    name: {
        fieldName: 'Name',
        required: true,
        editable: true,
    },
    amount: {
        fieldName: 'Amount',
        required: true,
        editable: true,
        type: 'number'
    },
    chance: {
        fieldName: 'Chance (%)',
        required: true,
        editable: true,
        type: 'number'
    },
    timesCanBeWon: {
        fieldName: 'Times Can Be Won',
        required: false,
        editable: true,
        type: 'number'
    },
    prizeType: {
        fieldName: 'Prize Type',
        required: true,
        editable: true,
        type: 'dropdown'
    },
    imagePath: {
        fieldName: 'Image',
        required: false,
        editable: true,
        type: 'image',
    },
}

export const UpdateBoxPrizeFormRules = {
    name: {
        fieldName: 'Name',
        required: true,
        editable: true,
    },
    amount: {
        fieldName: 'Amount',
        required: true,
        editable: true,
        type: 'number'
    },
    chance: {
        fieldName: 'Chance (%)',
        required: true,
        editable: true,
        type: 'number'
    },
    timesCanBeWon: {
        fieldName: 'Times Can Be Won',
        required: false,
        editable: true,
        type: 'number'
    },
    prizeType: {
        fieldName: 'Prize Type',
        required: true,
        editable: true,
        type: 'dropdown'
    },
    imagePath: {
        fieldName: 'Image',
        required: false,
        editable: true,
        type: 'image',
    },
}

// --- levels
export const UploadLevelFormRules = {
    level: {
        fieldName: 'Level',
        required: true,
        editable: true,
        type: 'number'
    },
    coins: {
        fieldName: 'Coins',
        required: true,
        editable: true,
        type: 'number'
    },
    other: {
        fieldName: 'Other',
        required: false,
        editable: true,
        type: 'dropdown',
        withSwitch: true,
    },
    otherAmount: {
        fieldName: 'Other Amount',
        required: false,
        editable: true,
        type: 'number'
    },
}

export const UpdateLevelFormRules = {
    level: {
        fieldName: 'Level',
        required: true,
        editable: true,
        type: 'number'
    },
    coins: {
        fieldName: 'Coins',
        required: true,
        editable: true,
        type: 'number'
    },
    other: {
        fieldName: 'Other',
        required: false,
        editable: true,
        type: 'dropdown',
        withSwitch: true,
    },
    otherAmount: {
        fieldName: 'Other Amount',
        required: false,
        editable: true,
        type: 'number'
    },
}

// --- daily reward
export const UploadDailyRewardFormRules = {
    day: {
        fieldName: 'Day',
        required: true,
        editable: true,
        type: 'number'
    },
    prizeName: {
        fieldName: 'Prize Name',
        required: true,
        editable: true,
    },
    prize: {
        fieldName: 'Prize',
        required: true,
        editable: true,
        type: 'dropdown',
        withSwitch: false,
        dropdownVals: dailyRewardTypeOptions
    },
    amount: {
        fieldName: 'Amount',
        required: true,
        editable: true,
        type: 'number'
    },
    giftBoxType: {
        fieldName: 'Gift Box Type',
        required: false,
        editable: true,
        type: 'dropdown',
        withSwitch: true,
        dropdownVals: giftBoxTypeOptions
    },
}

export const UpdateDailyRewardFormRules = {
    day: {
        fieldName: 'Day',
        required: true,
        editable: true,
        type: 'number'
    },
    prizeName: {
        fieldName: 'Prize Name',
        required: true,
        editable: true,
    },
    prize: {
        fieldName: 'Prize',
        required: true,
        editable: true,
        type: 'dropdown',
        withSwitch: false,
        dropdownVals: dailyRewardTypeOptions
    },
    amount: {
        fieldName: 'Amount',
        required: true,
        editable: true,
        type: 'number'
    },
    giftBoxType: {
        fieldName: 'Gift Box Type',
        required: false,
        editable: true,
        type: 'dropdown',
        withSwitch: true,
        dropdownVals: giftBoxTypeOptions
    },
}

// --- game config
export const UpdateGameConfigFormRules = {
    keysPerDeviceUse: {
        fieldName: 'Keys Per Device Use',
        required: false,
        editable: true,
        type: 'number'
    },
    keySlotsDefault: {
        fieldName: 'Key Slots Default',
        required: false,
        editable: true,
        type: 'number'
    },
    divider1: {
        type: 'divider',
        fieldName: 'Tutorial Config'
    },
    tutorialConfig: {
        conditionsList: {
            fieldName: 'Conditions List',
            required: false,
            editable: true,
            type: 'boolean'
        },
        amountOfCoinsReceivedFromBox: {
            fieldName: 'Amount Of Coins Received From Box',
            required: false,
            editable: true,
            type: 'number'
        },
        breathingCyclesNeeded: {
            fieldName: 'Breathing Cycles Needed',
            required: false,
            editable: true,
            type: 'number'
        },
        numberOfLongExhalations: {
            fieldName: 'Number Of Long Exhalations',
            required: false,
            editable: true,
            type: 'number'
        },
        numberOfMinimalExhalations: {
            fieldName: 'Number Of Minimal Exhalations',
            required: false,
            editable: true,
            type: 'number'
        },
        numberOfLightsInMinExhalations: {
            fieldName: 'Number Of Lights In Min Exhalations',
            required: false,
            editable: true,
            type: 'number'
        },
        guidedSessionTimeout: {
            fieldName: 'Guided Session Timeout',
            required: false,
            editable: true,
            type: 'number'
        },
    },
    divider2: {
        type: 'divider',
        fieldName: 'Devices Config'
    },
    devicesConfig: {
        timeForTimer: {
            fieldName: 'Time For Timer (min):',
            required: false,
            editable: true,
            type: 'number'
        },
        qualifiedMinTime: {
            fieldName: 'Qualified Min Time (sec):',
            required: false,
            editable: true,
            type: 'number'
        },
        qualifiedMinBreathingCycles: {
            fieldName: 'Qualified Min Breathing Cycles',
            required: false,
            editable: true,
            type: 'number'
        },
        deviceSearchInterval: {
            fieldName: 'Device Search Interval (millis):',
            required: false,
            editable: true,
            type: 'number'
        },
        unlockDuration: {
            fieldName: 'Unlock Duration (days):',
            required: false,
            editable: true,
            type: 'number'
        },
    },
    divider3: {
        type: 'divider',
        fieldName: 'Devices Notifications Config'
    },
    devicesNotificationsConfig: {
        notification1: {
            fieldName: 'Notification 1',
            text: {
                fieldName: 'Text',
                required: false,
                editable: true,
            },
            daysBeforeLock: {
                fieldName: 'Days Before Lock',
                required: false,
                editable: true,
                type: 'number'
            },
            active: {
                fieldName: 'Active',
                required: false,
                editable: true,
                type: 'boolean'
            },
        },
        notification2: {
            fieldName: 'Notification 2',
            text: {
                fieldName: 'Text',
                required: false,
                editable: true,
            },
            daysBeforeLock: {
                fieldName: 'Days Before Lock',
                required: false,
                editable: true,
                type: 'number'
            },
            active: {
                fieldName: 'Active',
                required: false,
                editable: true,
                type: 'boolean'
            },
        },
        notification3: {
            fieldName: 'Notification 3',

            text: {
                fieldName: 'Text',
                required: false,
                editable: true,
            },
            daysBeforeLock: {
                fieldName: 'Days Before Lock',
                required: false,
                editable: true,
                type: 'number'
            },
            active: {
                fieldName: 'Active',
                required: false,
                editable: true,
                type: 'boolean'
            },
        },
        notification4: {
            fieldName: 'Notification 4',

            text: {
                fieldName: 'Text',
                required: false,
                editable: true,
            },
            daysBeforeLock: {
                fieldName: 'Days Before Lock',
                required: false,
                editable: true,
                type: 'number'
            },
            active: {
                fieldName: 'Active',
                required: false,
                editable: true,
                type: 'boolean'
            },
        },
        notification5: {
            fieldName: 'Notification 5',

            text: {
                fieldName: 'Text',
                required: false,
                editable: true,
            },
            daysBeforeLock: {
                fieldName: 'Days Before Lock',
                required: false,
                editable: true,
                type: 'number'
            },
            active: {
                fieldName: 'Active',
                required: false,
                editable: true,
                type: 'boolean'
            },
        },
    },
}


// breathing cycle
export const UpdateBreathingCycleFormRules = {
    deviceId: {
        fieldName: 'Device Id',
        required: false,
        editable: false,
    },
    deviceActivation: {
        fieldName: 'Device Activation',
        required: false,
        editable: false,
        type: 'dateString'
    },
    deviceSettings: {
        fieldName: 'Device Settings',
        required: false,
        editable: false,
    },
    batteryVoltage: {
        fieldName: 'Battery Voltage',
        required: false,
        editable: false,
        type: 'number'
    },
    batteryStatus: {
        fieldName: 'Battery Status',
        required: false,
        editable: false,
        type: 'number'
    },
    sessionStart: {
        fieldName: 'Session Start',
        required: false,
        editable: false,
        type: 'dateString'
    },
    sessionComplete: {
        fieldName: 'Session Complete',
        required: false,
        editable: false,
        type: 'dateString'
    },
    deviceDeactivation: {
        fieldName: 'Device Deactivation',
        required: false,
        editable: false,
        type: 'dateString'
    },
    deviceDeactivationStatus: {
        fieldName: 'Device Deactivation Status',
        required: false,
        editable: false,
    },
    breathingCyclesCount: {
        fieldName: 'Breathing Cycles Count',
        required: false,
        editable: false,
        type: 'number'
    },
    sensorSessionsCount: {
        fieldName: 'Sensor Sessions Count',
        required: false,
        editable: false,
        type: 'number'
    },
    additionalData: {
        fieldName: 'Additional Data',
        required: false,
        editable: false,
    },
    rawData: {
        fieldName: 'Raw Data',
        required: false,
        editable: false,
        type:'textarea'
    },
}


export const BreathingCycleDataRules : any= {
    exhalationStart: {
        fieldName: 'Exhalation Start',
        type: 'dateString'
    },
    actualExhalationDuration: {
        fieldName: 'Actual Exhalation Duration',
        type: 'number',
    },
    breathingCycleStartIndicators: {
        fieldName: 'Breathing Cycle Start Indicators',
    },
    breathingCycleEndIndicators: {
        fieldName: 'Breathing Cycle End Indicators',
    },
    additionalData: {
        fieldName: 'Additional Data',
    },
    breathingCyclesCount: {
        fieldName: 'Breathing Cycles Count',
        type: 'number'
    },
    rawData: {
        fieldName: 'Raw Data',
    },
    createdAt: {
        fieldName: 'Created At',
        type: 'dateString'
    },
    updatedAt: {
        fieldName: 'Updated At',
        type: 'dateString'
    },
}


// --- fota
export const UploadFirmwareFormRules = {
    binFile: {
        fieldName: 'Bin File',
        required: true,
        editable: true,
        type: 'binUpload'
    },
    name: {
        fieldName: 'Name',
        required: true,
        editable: true,
    },
    version: {
        fieldName: 'Version',
        required: true,
        editable: true,
        type: 'version',
    },
    changelog: {
        fieldName: 'Change Log',
        required: true,
        editable: true,
        type: 'textarea',
    },
}