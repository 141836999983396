import createSagaMiddleware from 'redux-saga';
import { configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import rootReducer, { RootState } from './rootReducer';
import authUserSaga from './features/auth-user/auth-user-saga';
import editAccountSaga from './features/edit-account/edit-account-saga';
import accountsSaga from './features/accounts/accounts-saga';
import editLibraryImageSaga from './features/edit-library-image/edit-library-image-saga';
import librarySaga from './features/images-library/library-saga';
import scenesSaga from './features/scenes/scenes-saga';
import editSceneSaga from './features/edit-scene/edit-scene-saga';
import editSceneItemSaga from './features/edit-scene-item/edit-scene-item-saga';
import boxesSaga from './features/boxes/boxes-saga';
import editBoxSaga from './features/edit-box/edit-box-saga';
import editBoxPrizeSaga from './features/edit-box-prize/edit-box-prize-saga';
import levelsSaga from './features/levels/levels-saga';
import editLevelSaga from './features/edit-level/edit-level-saga';
import dailyRewardsSaga from './features/daily-rewards/daily-rewards-saga';
import editDailyRewardSaga from './features/edit-daily-reward/edit-daily-reward-saga';
import gameConfigSaga from './features/game-config/game-config-saga';
import fotaSaga from './features/fota/fota-saga';
import devicesSaga from './features/devices/devices-saga';
import breathingCyclesSaga from './features/breathing-cycles/breathing-cycles-saga';
import editBreathingCycleSaga from './features/edit-breathing-cycle/edit-breathing-cycle-saga';

const saga = createSagaMiddleware();
const store = configureStore({
    reducer: rootReducer,
    middleware: [saga]
})
saga.run(authUserSaga);
saga.run(accountsSaga);
saga.run(editAccountSaga);
saga.run(editLibraryImageSaga);
saga.run(librarySaga);
saga.run(scenesSaga);
saga.run(editSceneSaga);
saga.run(editSceneItemSaga);
saga.run(boxesSaga);
saga.run(editBoxSaga);
saga.run(editBoxPrizeSaga);
saga.run(levelsSaga);
saga.run(editLevelSaga);
saga.run(dailyRewardsSaga);
saga.run(editDailyRewardSaga);
saga.run(gameConfigSaga);
saga.run(devicesSaga);
saga.run(breathingCyclesSaga);
saga.run(editBreathingCycleSaga);
saga.run(fotaSaga);

export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>
export default store