import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from '../../hooks/useForm';
import { useButtonStyles } from '../../hooks/useStyles';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import usePermission from '../../hooks/usePermission';
import { IFirmware } from '../../interfaces/models/fota';
import { IGetPersignedUrlParams } from '../../interfaces/api/files';
import { IGetFirmwaresParams } from '../../interfaces/api/fota';
import { getValidationsObj } from '../../utils/forms-utils';
import FormInput, { FormTextArea } from '../ui/inputs/FormInput';
import Pagination from '../ui/Pagination';
import LoaderContainer from '../ui/loaders/LoaderContainer';
import Dropzone from '../common/upload-file/Dropzone';
import AlertPopup from '../common/AlertPopup';
import { FormContainer, FormInputContainer, TitleContainer, FlexColumn, FlexRowSpaced, FlexRow, FlexRowAligned } from '../common/containers';
import filesApi from '../../store/api/files-api';
import { createFirmwareFailed, createFirmwareStart, deleteFirmwareStart, fetchFirmwaresStart, publishFirmwareStart, startLoader } from '../../store/features/fota/fota-slice';
import { Delete24Regular } from "@fluentui/react-icons";
import { Button, LargeTitle, Label, Divider, Subtitle2, tokens, } from '@fluentui/react-components';
import PublishSwitch from './PublishSwitch';
import { getNumOfPages } from '../table/Table';
import config from '../../config';


const HistoryColumnStyle = {
    width: '30%',
    height: '100%',
    padding: ' 0 20px',
    borderLeft: '1px solid #e0e0e0'
}

const FotaPage = (props: { metadata: any }) => {

    const { loading } = useAppSelector(state => state.fota);

    return (
        <FlexRow style={{ opacity: loading ? '0.3' : '1', padding: ' 0 3rem', height: '100%' }}>
            {loading ? <LoaderContainer /> : null}
            <FlexColumn style={{ width: '70%', height: '100%' }}>
                <FlexRowSpaced >
                    <TitleContainer>
                        <LargeTitle> Fota </LargeTitle>
                    </TitleContainer>
                </FlexRowSpaced>
                <FotaForm metadata={props.metadata.editForm} />
            </FlexColumn>
            <FlexColumn style={HistoryColumnStyle}>
                <LargeTitle> History </LargeTitle>
                <FotaHistory></FotaHistory>
            </FlexColumn>
        </FlexRow>
    )
}

const FotaForm = (props: { metadata: any }) => {

    const {
        metadata,
    } = props;

    const dispatch = useAppDispatch();
    const buttonClasses = useButtonStyles();
    const adminAndStronger = usePermission('admin:>=');
    const { operation, requestState } = useAppSelector(state => state.fota);
    const [dropzoneMode, setDropzoneMode] = useState<'upload' | 'loading' | 'hover' | 'crop' | 'preview' | null>(null);

    const { handleSubmit, handleChange, handleCustomChange, data: editFota, errors, setData } = useForm<any>({
        validations: getValidationsObj(metadata),
        onSubmit: () => {
            createFirmware();
        },
    });

    const onCreateClick = () => {
        handleSubmit();
    }

    const createFirmware = useCallback(async () => {
        dispatch(startLoader());
        setDropzoneMode(null);
        try {
            if (!editFota.binFile) {
                return;
            }

            const params: IGetPersignedUrlParams = {
                fileName: editFota.name,
                description: editFota.changelog || '',
                extension: editFota.binFile.fileName.slice(editFota.binFile.fileName.lastIndexOf('.') + 1)
            }

            const presignedUrlRes = await filesApi.getPresignedUrl(params);

            if (presignedUrlRes.error) {
                throw presignedUrlRes.error;
            }

            const url = presignedUrlRes.data.data;
            const uploadRes = await filesApi.uplaodFile({ url, file: editFota.binFile.blobFile, name: editFota.name, description: editFota.changelog || '' });
            
            const fileName = url.replace(config.apiGateway.FILES_STORAGE_URL, '').split('?')[0];
            if (uploadRes.error) {
                throw uploadRes.error
            } else {
                setTimeout(() => {
                    dispatch(createFirmwareStart({
                        name: editFota.name,
                        filePath: fileName,
                        version: editFota.version,
                        changelog: editFota.changelog || ''
                    }))
                }, 1500);
            };
        } catch (error: any) {
            dispatch(createFirmwareFailed({ message: 'Upload failed' }))
        }

    }, [dispatch, editFota])


    const sanitizeFn = (inputValue: string) => {
        const sanitizedValue = inputValue.replace(/[^0-9.]/g, '');
        return sanitizedValue;
    }

    useEffect(() => {

        if (requestState === 'finish' && operation === 'create') {
            setData({});
            setDropzoneMode('upload');

            let fetchParams: IGetFirmwaresParams = {
                skip: 0,
                limit: 8,
                page: 1,
                sortDir: 'desc'
            }
            dispatch(fetchFirmwaresStart({ params: fetchParams }));
        }
    }, [operation, requestState]);

    return (
        <FlexColumn >
            <FormContainer >
                {metadata != null ?
                    <>
                        {Object.keys(metadata).map((key, index) => {
                            return (
                                metadata[key] ?
                                    <FormInputContainer key={index}>
                                        <Label>
                                            {metadata[key].fieldName}:
                                            {metadata[key].required ?
                                                <>&nbsp; <Subtitle2 style={{ color: tokens.colorPaletteCranberryBorderActive }}>*</Subtitle2> </>
                                                : null}
                                        </Label>
                                        {
                                            metadata[key].type === 'textarea' ?
                                                <FormTextArea
                                                    value={editFota[key] || ''}
                                                    style={{ marginTop: '5px', pointerEvents: metadata[key].editable ? 'unset' : 'none' }}
                                                    placeholder={metadata[key].fieldName}
                                                    onChange={handleChange(key)}
                                                    errorMessage={errors[key]}
                                                /> :
                                                metadata[key].type === 'binUpload' ?
                                                    <Dropzone
                                                        title='Upload Bin File'
                                                        wrapperWidth={350}
                                                        wrapperHeight={350}
                                                        fileTypes={['bin']}
                                                        onUploadFileComplete={(file) => handleCustomChange(key, file)}
                                                        dropzoneMode={dropzoneMode}
                                                    />
                                                    :
                                                    <FormInput
                                                        value={editFota[key] || ''}
                                                        style={{ marginTop: '5px', pointerEvents: metadata[key].editable ? 'unset' : 'none' }}
                                                        placeholder={metadata[key].fieldName}
                                                        onChange={handleChange(key, metadata[key].type === 'version' ? sanitizeFn : undefined)}
                                                        errorMessage={errors[key]}
                                                    />
                                        }

                                    </FormInputContainer> : null
                            );
                        })}
                    </>
                    : null
                }
            </FormContainer>

            {adminAndStronger ? <Button
                className={buttonClasses.primary}
                appearance="primary"
                style={{ width: "250px", margin: '20px 0' }}
                onClick={onCreateClick}>
                Create
            </Button> : null}
        </FlexColumn>
    )
}

export default FotaPage;


const FotaHistory = () => {

    const dispatch = useAppDispatch();
    const limit = 8;
    const { page, total } = useAppSelector(state => state.fota.firmwares.metadata);
    const firmwares = useAppSelector(state => state.fota.firmwares.firmwaresArr);

    const getFirmwares = useCallback((page: number) => {

        let params: IGetFirmwaresParams = {
            skip: ((page - 1) * limit),
            limit: limit,
            page: page,
            sortDir: 'desc'
        }
        dispatch(fetchFirmwaresStart({ params }))

    }, [dispatch]);

    useEffect(() => {
        getFirmwares(page);
    }, []);

    return (
        <FlexColumn style={{ margin: '40px 0' }}>
            {firmwares.map((firmware: IFirmware, index: number) => {
                return (
                    <FotaHistoryItem key={index} firmware={firmware} />
                )
            })}

            {getNumOfPages(total, limit) > 1 ? <Pagination
                onPageChange={(page: number) => { getFirmwares(page) }}
                pageIndex={page || 1}
                pageCount={getNumOfPages(total, limit)}
                totalPageButtons={limit}
            /> : null}

        </FlexColumn>
    )
}

const FotaHistoryItem = (props: { firmware: IFirmware }) => {

    const {
        firmware,
    } = props;

    const dispatch = useAppDispatch();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isPublished, setIsPublished] = useState(false);

    const onDeleteClick = () => {
        setShowDeleteModal(false);
        dispatch(deleteFirmwareStart({ firmwareId: firmware._id }));
    }

    const openDeletePopup = () => {
        setShowDeleteModal(true)
    }

    useEffect(() => {
        setIsPublished(firmware?.isPublished || false);
    }, [firmware]);

    const onPublishChange = useCallback((ev: any) => {
        setIsPublished(ev.currentTarget.checked);
        dispatch(publishFirmwareStart({ firmwareId: firmware._id || '', publish: ev.currentTarget.checked }))
    }, [setIsPublished, firmware]);


    return (
        <FlexColumn>
            <AlertPopup
                title='Are you sure you want to delete?'
                open={showDeleteModal}
                onOpenChange={setShowDeleteModal}
                onActionClick={onDeleteClick} />

            <FlexRowSpaced>
                <FlexColumn>
                    <FlexRow> <b style={{ marginRight: '7px' }}>Name: </b> {firmware.name} </FlexRow>
                    <FlexRow> <b style={{ marginRight: '7px' }}>Version: </b> {firmware.version} </FlexRow>
                    <FlexRow> <b style={{ marginRight: '7px' }}>Change Log: </b> {firmware.changelog} </FlexRow>
                </FlexColumn>

                <FlexRowAligned style={{ marginLeft: '7px' }}>
                    <PublishSwitch
                        checked={isPublished}
                        setChecked={setIsPublished}
                        withUnpublish={true}
                        withAlert={false}
                        onChange={onPublishChange}
                    />
                    <Delete24Regular onClick={openDeletePopup} style={{ cursor: 'pointer' }} />
                </FlexRowAligned>
            </FlexRowSpaced>
            <Divider style={{ margin: '20px 0' }} />
        </FlexColumn>
    )
}

