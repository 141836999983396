import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../interfaces/base';
import { IGetBreathingCyclesParams } from '../../../interfaces/api/breathing-cycles';
import { IBreathingCyclesState } from '../../../interfaces/store/breathing-cycles-interfaces';

const initialState: IBreathingCyclesState = {
    breathingCycles: [],
    metadata: {
        page: 1,
        total: 0
    },
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanBreathingCyclesState = (state: IBreathingCyclesState) => {
    return initialState;
};

const _fetchBreathingCyclesStart = (state: IBreathingCyclesState, { payload }: PayloadAction<{ params: IGetBreathingCyclesParams }>) => {
    state.metadata = {
        page: payload.params.page as number,
        total: state.metadata.total 
    };
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'read';
}

const _fetchBreathingCyclesSuccess = (state: IBreathingCyclesState, { payload }: PayloadAction<any>) => {
    state.breathingCycles = payload.data;
    state.metadata = { total: payload.metadata.total, page: state.metadata.page };
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _fetchBreathingCyclesFailed = (state: IBreathingCyclesState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const BreathingCyclesSlice = createSlice({
    name: 'breathingCycles',
    initialState,
    reducers: {
        cleanBreathingCyclesState: _cleanBreathingCyclesState,
        fetchBreathingCyclesStart: _fetchBreathingCyclesStart,
        fetchBreathingCyclesSuccess: _fetchBreathingCyclesSuccess,
        fetchBreathingCyclesFailed: _fetchBreathingCyclesFailed
    }
})

const { actions, reducer } = BreathingCyclesSlice;
export const {
    cleanBreathingCyclesState,
    fetchBreathingCyclesStart,
    fetchBreathingCyclesSuccess,
    fetchBreathingCyclesFailed
} = actions;

export default reducer;
