import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../interfaces/base';
import { IGetLevelsParams } from '../../../interfaces/api/levels';
import { ILevelsState } from '../../../interfaces/store/levels-interfaces';

const initialState: ILevelsState = {
    levels: [],
    metadata: {
        page: 1,
        total: 0
    },
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanLevelsState = (state: ILevelsState) => {
    return initialState;
};

const _fetchLevelsStart = (state: ILevelsState, { payload }: PayloadAction<{ params: IGetLevelsParams }>) => {
    state.metadata = {
        page: payload.params.page as number,
        total: state.metadata.total 
    };
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'read';
}

const _fetchLevelsSuccess = (state: ILevelsState, { payload }: PayloadAction<any>) => {
    state.levels = payload.data;
    state.metadata = { total: payload.metadata.total, page: state.metadata.page };
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _fetchLevelsFailed = (state: ILevelsState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const LevelsSlice = createSlice({
    name: 'levels',
    initialState,
    reducers: {
        cleanLevelsState: _cleanLevelsState,
        fetchLevelsStart: _fetchLevelsStart,
        fetchLevelsSuccess: _fetchLevelsSuccess,
        fetchLevelsFailed: _fetchLevelsFailed
    }
})

const { actions, reducer } = LevelsSlice;
export const {
    cleanLevelsState,
    fetchLevelsStart,
    fetchLevelsSuccess,
    fetchLevelsFailed
} = actions;

export default reducer;
