import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { ReactComponent as Icon } from '../../assets/icons/logo.svg';
import { useAppSelector } from '../../hooks/reduxHooks';
import Routes from '../../routes';
import OutsideClick from '../hoc/OutsideClick';
import NavRoutes from './NavRoutes';
import ProfileMenu from './ProfileMenu';
import Nav from './StyledComponents';

const TopNavigation = (props: any) => {
    const auth = useAppSelector(state => state.auth); 
    const [profileDropDownOpen, setProfileDropDownOpen] = useState(false);

    const openDropDown = () => {
        setProfileDropDownOpen(!profileDropDownOpen);
    }

    return (
        <Nav.TopNav>
            <Nav.LogoContainer>
                <NavLink to={Routes.Dashboard.home}>
                    <Icon height={45} />
                </NavLink>
            </Nav.LogoContainer>

            <NavRoutes  />
            
            <Nav.NavProfileButton
                onclick={openDropDown}
                user={auth.user}
            />
            {
                profileDropDownOpen ?
                    <OutsideClick callback={openDropDown}>
                        <ProfileMenu callback={openDropDown} user={auth.user} />
                    </OutsideClick>
                    : null
            }

        </Nav.TopNav>
    )
}

export default TopNavigation;
