import { IWithPagination, IWithSort } from "./common";
import { Option } from "../../components/ui/Select";

export type BreathingCyclesSort = 'createdAt';

export interface IGetBreathingCyclesParams extends IWithPagination, IWithSort<BreathingCyclesSort> {
    search?: string;
}

export interface IGetBreathingCycleParams {
    breathingCycleSessionId: string;
}

export interface IBreathingCyclesSortOption extends IWithSort<BreathingCyclesSort> { }


export const breathingCyclesSortOptions: Option<IBreathingCyclesSortOption>[] = [
    { id: '1', label: 'Created At - Ascending', value: { sortBy: 'createdAt', sortDir: 'asc' } },
    { id: '2', label: 'Created At - Descending', value: { sortBy: 'createdAt', sortDir: 'desc' } },
]
