import React, { useEffect } from 'react';
import { LargeTitle, Subtitle2, Label, tokens } from '@fluentui/react-components';
import { IosArrowLtr24Filled } from "@fluentui/react-icons";
import { useParams, useNavigate } from 'react-router-dom';
import LoaderContainer from '../ui/loaders/LoaderContainer';
import { useIconsStyles } from '../../hooks/useStyles';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import Routes from '../../routes';
import { FormContainer, FormInputContainer, TitleContainer, FlexColumn, FlexRowSpaced, FlexRowWrapped, FlexRow } from '../common/containers';
import { cleanEditBreathingCycleState, getBreathingCycleStart } from '../../store/features/edit-breathing-cycle/edit-breathing-cycle-slice';
import { IBreathingCycle } from '../../interfaces/models/breathing-cycle';
import { getInitialFormValues, getValidationsObj } from '../../utils/forms-utils';
import { useForm } from '../../hooks/useForm';
import BreathingCycleData from './breathing-cycle-data/BreathingCycleData';


const BreathingCyclePage = (props: { metadata: any }) => {

    const { id } = useParams();
    const currBreathingCycle = useAppSelector(state => state.editBreathingCycle.breathingCycle);
    const { loading } = useAppSelector(state => state.editBreathingCycle);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const iconsClasses = useIconsStyles();

    useEffect(() => {
        dispatch(cleanEditBreathingCycleState());
        dispatch(getBreathingCycleStart({ breathingCycleSessionId: id || '' }))
    }, []);

    return (
        <FlexColumn style={{ padding: ' 0 3rem' }}>
            {loading ? <LoaderContainer /> : null}
            <FlexRowSpaced>
                <TitleContainer>
                    <IosArrowLtr24Filled
                        onClick={() => {
                            navigate(Routes.Dashboard.breathingCycles);
                        }}
                        className={iconsClasses.loginArrow}
                    />
                    <LargeTitle>Breathing Cycle</LargeTitle>
                </TitleContainer>

            </FlexRowSpaced>
            {currBreathingCycle ?
                <BreathingCyclePageForm breathingCycle={currBreathingCycle} metadata={props.metadata.editForm} /> : null
            }

        </FlexColumn>
    )
}

const BreathingCyclePageForm = (props: { breathingCycle: IBreathingCycle | null, metadata: any }) => {

    const {
        breathingCycle,
        metadata
    } = props;

    const loading = useAppSelector(state => state.editBreathingCycle.loading);

    function formatDateString(input: string) {
        let formattedDateString;
        const date = new Date(input);
        formattedDateString = date.toLocaleDateString("en-GB");
        return formattedDateString;
    }

    const { data: editDailyReward } = useForm<any>({
        initialValues: getInitialFormValues(breathingCycle),
        validations: getValidationsObj(metadata),
    });

    return (
        <FlexColumn >
            <FormContainer style={{ opacity: loading ? '0.3' : '1' }}>
                {metadata != null ?
                    <>
                        <FlexRow>
                            <FlexRowWrapped style={{ gap: '40px' }}>
                                {Object.keys(metadata).map((key, index) => {
                                    return (
                                        metadata[key] ?
                                            <FormInputContainer key={index}>
                                                <FlexRow style={{ position: 'relative' }}>
                                                    <Label>
                                                        {metadata[key].fieldName}:
                                                        {metadata[key].required ?
                                                            <>&nbsp; <Subtitle2 style={{ color: tokens.colorPaletteCranberryBorderActive }}>*</Subtitle2> </>
                                                            : null}
                                                    </Label>
                                                </FlexRow>
                                                <FlexRow style={{ marginTop: '5px', fontWeight: '800' }} >
                                                    {metadata[key].type == 'dateString' ? formatDateString(editDailyReward[key]) : editDailyReward[key] === 0 ? '0' : editDailyReward[key] || ''}
                                                </FlexRow>
                                            </FormInputContainer> : null
                                    );
                                })}
                            </FlexRowWrapped>
                        </FlexRow>
                    </>
                    : null
                }
            </FormContainer>

            <BreathingCycleData />

        </FlexColumn >
    )
}

export default BreathingCyclePage;

