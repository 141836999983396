import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../interfaces/base';
import { IGetAccountsParams } from '../../../interfaces/api/accounts';
import { IAccountsState } from '../../../interfaces/store/accounts-interfaces';

const initialState: IAccountsState = {
    accounts: [],
    metadata: {
        page: 1,
        total: 0
    },
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanAccountsState = (state: IAccountsState) => {
    return initialState;
};

const _fetchAccountsStart = (state: IAccountsState, { payload }: PayloadAction<{ params: IGetAccountsParams }>) => {
    state.metadata = {
        page: payload.params.page as number,
        total: state.metadata.total
    };
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'read';
}

const _fetchAccountsSucess = (state: IAccountsState, { payload }: PayloadAction<any>) => {
    state.accounts = payload.data;
    state.metadata = payload.metadata;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _fetchAccountsFailed = (state: IAccountsState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const AccountsSlice = createSlice({
    name: 'accounts',
    initialState,
    reducers: {
        cleanAccountsState: _cleanAccountsState,
        fetchAccountsStart: _fetchAccountsStart,
        fetchAccountsSucess: _fetchAccountsSucess,
        fetchAccountsFailed: _fetchAccountsFailed
    }
})

const { actions, reducer } = AccountsSlice;
export const {
    cleanAccountsState,
    fetchAccountsStart,
    fetchAccountsSucess,
    fetchAccountsFailed
} = actions;

export default reducer;
