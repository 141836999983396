/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import LoadingLogo from '../../ui/LoadingLogo';
import { FlexColumnCentered } from '../../common/containers';


const PageLoader = (props: { containerStyles?: React.CSSProperties }) => {
    return (
        <FlexColumnCentered style={{ height: '100%', ...props.containerStyles }}>
            <LoadingLogo darkLogo={true} />
        </FlexColumnCentered>
    )
}

export default PageLoader;
