import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { alertError } from '../notifications-manager/notification-manager';
import {
    createDailyRewardFailed,
    createDailyRewardStart,
    createDailyRewardSuccess,
    getDailyRewardFailed,
    getDailyRewardStart,
    getDailyRewardSuccess,
    updateDailyRewardFailed,
    updateDailyRewardStart,
    updateDailyRewardSuccess
} from './edit-daily-reward-slice';
import { ICreateDailyRewardBody, IGetDailyRewardParams, IUpdateDailyRewardBody } from '../../../interfaces/api/daily-rewards';
import dailyRewardsApi from '../../api/daily-rewards-api';


function* createDailyReward(action: PayloadAction<ICreateDailyRewardBody>): any {
    try {
        const dailyRewardData = action.payload;
        const { data, status, error } = yield call(dailyRewardsApi.createDailyReward, dailyRewardData);
        if (error) {
            yield put(createDailyRewardFailed(error));
            return;
        }
        yield put(createDailyRewardSuccess(data));
    } catch (error: any) {
        yield put(createDailyRewardFailed(error));
        yield put(alertError(error.message))
    }
}


function* updateDailyReward(action: PayloadAction<IUpdateDailyRewardBody>): any {
    try {
        const params = action.payload;
        
        const { data, status, error } = yield call(dailyRewardsApi.updateDailyReward, params);
        if (error) {
            yield put(updateDailyRewardFailed(error));
            return;
        }
        yield put(updateDailyRewardSuccess(data));
    } catch (error: any) {
        yield put(updateDailyRewardFailed(error));
        yield put(alertError(error.message))

    }
}

function* getDailyReward(action: PayloadAction<IGetDailyRewardParams>): any {
    try {
        const { dailyRewardId } = action.payload;
        const { data, status, error } = yield call(dailyRewardsApi.getDailyReward, { dailyRewardId });
        if (error) {
            throw error;
        }
        yield put(getDailyRewardSuccess(data));
    } catch (error: any) {
        yield put(getDailyRewardFailed(error));
    }
}

function* editDailyRewardSaga() {
    yield takeLatest(updateDailyRewardStart.type, updateDailyReward);
    yield takeLatest(createDailyRewardStart.type, createDailyReward);
    yield takeLatest(getDailyRewardStart.type, getDailyReward);
}

export default editDailyRewardSaga;