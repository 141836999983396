import React from 'react';
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Button,
} from "@fluentui/react-components";
import { useButtonStyles, useModalStyles } from '../../hooks/useStyles';


export interface IAlertPopupProps {
    title: string;
    open: boolean;
    onOpenChange: any;
    onActionClick: any;
    actionTitle?: string;
    subText?:string;
};

const AlertPopup = (props: IAlertPopupProps) => {

    const buttonClasses = useButtonStyles();
    const modalClasses = useModalStyles();

    return (
        <Dialog open={props.open} onOpenChange={(event, data) => props.onOpenChange(data.open)}>
            <DialogSurface>
                <DialogBody className={modalClasses.container}>
                    <DialogTitle className={modalClasses.title}>{props.title}</DialogTitle>
                    <DialogContent className={modalClasses.title}>
                        {props.subText}
                    </DialogContent>
                    <DialogActions className={modalClasses.actions}>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Close</Button>
                        </DialogTrigger>
                        <Button appearance="primary" className={buttonClasses.delete} onClick={props.onActionClick}>{props.actionTitle || 'DELETE'}</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default AlertPopup;

