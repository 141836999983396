import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetLibraryParams } from '../../../interfaces/api/library';

import { IError } from '../../../interfaces/base';
import { ILibraryState } from '../../../interfaces/store/library-image-interfaces';

const initialState: ILibraryState = {
    library: [],
    metadata: {
        page: 1,
        total: 0
    },
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanLibraryState = (state: ILibraryState) => {
    return initialState;
};


const _fetchLibraryStart = (state: ILibraryState, { payload }: PayloadAction<{ params: IGetLibraryParams }>) => {
    state.metadata = {
        page: payload.params.page as number,
        total: state.metadata.total 
    };
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'read';
}

const _fetchLibrarySucess = (state: ILibraryState, { payload }: PayloadAction<any>) => {
    state.library = payload.data;
    state.metadata = { total: payload.metadata.total, page: state.metadata.page };
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _fetchLibraryFailed = (state: ILibraryState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const LibrarySlice = createSlice({
    name: 'library',
    initialState,
    reducers: {
        cleanLibraryState: _cleanLibraryState,
        fetchLibraryStart: _fetchLibraryStart,
        fetchLibrarySucess: _fetchLibrarySucess,
        fetchLibraryFailed: _fetchLibraryFailed
    }
})

const { actions, reducer } = LibrarySlice;
export const {
    cleanLibraryState,
    fetchLibraryStart,
    fetchLibrarySucess,
    fetchLibraryFailed
} = actions;

export default reducer;
