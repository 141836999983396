import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchBreathingCyclesFailed, fetchBreathingCyclesStart, fetchBreathingCyclesSuccess } from './breathing-cycles-slice';
import { IGetBreathingCyclesParams } from '../../../interfaces/api/breathing-cycles';
import breathingCyclesApi from '../../api/breathing-cycles-api';

function* fetchBreathingCycles(action: PayloadAction<{ params: IGetBreathingCyclesParams; }>): any {
    try {
        const { params } = action.payload;
        delete params.page;
        const { data, status, error } = yield call(breathingCyclesApi.getBreathingCycles, params); 
        
        if (error) {
            yield put(fetchBreathingCyclesFailed(error));
            return;
        }
        
        yield put(fetchBreathingCyclesSuccess(data));
    } catch (error: any) {
        yield put(fetchBreathingCyclesFailed(error));
    }
}

function* breathingCyclesSaga() {
    yield takeLatest(fetchBreathingCyclesStart.type, fetchBreathingCycles);
}

export default breathingCyclesSaga;