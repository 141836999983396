export const roles = {
	admin: 'Admin',
	write: 'Write',
	viewonly: 'View Only'
}

export const definedRoles: {[key: string]: {name: string, value: number}} = {
	'admin': { name: 'admin', value: 500 },
	'write': { name: 'standard', value: 200 },
	'viewonly': { name: 'viewonly', value: 100 }
}

const operators:{[key: string]: Function} = {
	'==': (expectedRole:string, userRole:{name: string, value: number}) => definedRoles[expectedRole].value === userRole.value,
	'<': (expectedRole:string, userRole:{name: string, value: number}) => definedRoles[expectedRole].value > userRole.value,
	'>': (expectedRole:string, userRole:{name: string, value: number}) => definedRoles[expectedRole].value < userRole.value,
	'<=': (expectedRole:string, userRole:{name: string, value: number}) => definedRoles[expectedRole].value >= userRole.value,
	'>=': (expectedRole:string, userRole:{name: string, value: number}) => definedRoles[expectedRole].value <= userRole.value,
}

/**
 * @param {String} permission its a string representation of permission in format of user role and compare operator
 * ex. 'admin:>=' the meaning of this is: all user that stronger or like admin. 
 */
const parsePermissions = (permission:string) => {
	if (permission.indexOf(':') < 0) {
		throw new Error('Wrong format of permissions provided');
	}
	const [role, operator] = permission.split(':');
	return (userRole:{name: string, value: number}) => {
		return operators[operator](role, userRole)
	}
}
/**
 * @param {Object} userRole current user role as an object {name: 'role', value: 10000}
 * @param {String} permission its a string representation of permission in format of user role and compare operator
 * ex. 'admin:>=' the meaning of this is: all user that stronger or like admin. 
 */
export const isAllowed = (userRole:{name: string, value: number}, permission:string) => {
	return parsePermissions(permission)(userRole);
}
