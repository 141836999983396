import React from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useForm } from '../../hooks/useForm';
import { usePasswordValidator } from '../../hooks/validators/useRegExValidator';
import { useButtonStyles, useTypographyStyles } from '../../hooks/useStyles';
import { IResetPassword } from '../../interfaces/components/forms/login';
import { resetPasswordStart } from '../../store/features/auth-user/auth-user-slice';
import { Button, LargeTitle, Subtitle2, Spinner, Subtitle2Stronger, Link, mergeClasses } from '@fluentui/react-components';
import FormInput from '../ui/inputs/FormInput';
import { Buttoncontainer, Container, Spinnercontainer, TitleContainer } from './StyledComponents';


const ResetPassword = () => {

    const auth = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    const buttonClasses = useButtonStyles();
    const typographyClasses = useTypographyStyles();

    const { handleSubmit, handleChange, data, errors } = useForm<IResetPassword>({
        validations: {
            password: {
                custom: {
                    isValid: () => passwordValidation,
                    message: 'Password should contain at least one number, one lower case letter , one capital letter and one special character',
                },
            },
            confirmPassword: {
                custom: {
                    isValid: () => confirmPasswordValidation(),
                    message: 'Passwords do not match',
                },
            },
        },
        initialValues: { password: '', confirmPassword: '', },

        onSubmit: () => {
            onResetPasswordClick();
        },
    });

    const passwordValidation = usePasswordValidator(data.password ? data.password : '');

    const confirmPasswordValidation = () => {
        let isValid = true;
        if (data.password != data.confirmPassword) isValid = false;
        return isValid;
    }

    const onResetPasswordClick = () => {
        const { password } = data
        dispatch(resetPasswordStart({ password }));
    }

    return (
        <Container style={{ margin: '30px auto' }}>
            <TitleContainer>
                <LargeTitle align='center'> Reset Password</LargeTitle>
            </TitleContainer>

            {auth.operation === 'resetPassword' && auth.requestState === 'finish' ?
                <>
                    <Subtitle2 style={{ marginBottom: '20px' }} >
                        Password changed successfully
                    </Subtitle2>

                    <Buttoncontainer style={{ width: '358px' }}>
                        <Link href="/login"  >
                            <Button
                                className={mergeClasses(buttonClasses.primary, buttonClasses.fullWidth)}
                                appearance="primary"
                                style={{ width: '358px' }} >
                                Login
                            </Button>
                        </Link>

                    </Buttoncontainer>
                </> :
                <>
                    <Subtitle2 style={{ marginBottom: '20px' }} >
                        Enter new password
                    </Subtitle2>

                    <FormInput
                        value={data.password}
                        placeholder={'Choose new password '}
                        onChange={handleChange('password')}
                        errorMessage={errors.password}
                        type={'password'}
                        varient={'underline'}
                    />
                    <FormInput
                        value={data.confirmPassword}
                        placeholder={'Confirm Password '}
                        onChange={handleChange('confirmPassword')}
                        errorMessage={errors.confirmPassword}
                        type={'password'}
                        varient={'underline'}
                    />

                    <Buttoncontainer>
                        <Button
                            className={mergeClasses(buttonClasses.primary, buttonClasses.fullWidth)}
                            appearance="primary"
                            onClick={handleSubmit}>
                            Reset
                        </Button>
                        {
                            auth.error ?
                                <Subtitle2Stronger
                                    className={typographyClasses.error}>
                                    {auth.error?.message}
                                </Subtitle2Stronger> : null
                        }
                    </Buttoncontainer>
                </>


            }
            <Spinnercontainer>
                {auth.loading ? <Spinner /> : null}
            </Spinnercontainer>
        </Container >
    )
}

export default ResetPassword;
