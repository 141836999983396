import React, { createRef, useRef } from 'react';
import styled from 'styled-components';

import { isNumber } from '../../../utils/utils';
import { RelativeContainer } from '../../common/containers';
import { StyledInput } from './Input';

const Container = styled(RelativeContainer)`
    display: grid;
`

interface IPinInput {
    value: string[];
    setValue: Function;
    disabled?: boolean;
    numOfDigits: number;
}
const PinInput = (props: IPinInput) => {
    const {
        value,
        setValue,
        disabled,
        numOfDigits
    } = props;
    const refs: any = useRef(value.map(() => createRef()))

    return (
        <Container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
            {
                Array.from(Array(numOfDigits)).map((x, index) => (
                    <StyledInput
                        ref={refs.current[index]}
                        key={index}
                        varient={'underline'}
                        style={{ width: '40px', textAlign: 'center', borderBottom:'1px solid black' }}
                        value={value[index]}
                        onChange={() => { }}
                        onKeyDown={(event) => {
                            if (event.key === 'Backspace') {
                                let update = [...value];
                                update[index] = '';
                                setValue(update);
                                if (index > 0) {
                                    refs.current[index - 1].current.focus()
                                }
                                return;
                            }
                            if (isNumber(event.key)) {
                                let update = [...value];
                                update[index] = event.key;
                                setValue(update);
                                if (index + 1 < numOfDigits) {
                                    refs.current[index + 1].current.focus()
                                }
                            }
                        }}
                        disabled={disabled}
                        placeholder={''}
                        type={'text'}
                        className={value[index] === '' ? 'invalid' : ''}
                    />
                ))
            }
        </Container>
    )
}

export default PinInput;