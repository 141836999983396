import { Switch } from "@fluentui/react-components";
import * as React from "react";
import usePermission from "../../hooks/usePermission";


interface ISwitchProps {
  disabledSwitch?: boolean,
  checked: boolean,
  setChecked: any,
  label: string,
  onChange?: any,
  permission?:string
}

export const SwitchInput = (props: ISwitchProps) => {

  const writeAndStronger = usePermission(props.permission || 'write:>=');

  const onChange = React.useCallback(
    (ev: any) => {
      props.setChecked(ev.currentTarget.checked);
    },
    [props.setChecked]
  );


  return (
    <div>
      <Switch
        checked={props.checked}
        label={props.label}
        onChange={props.onChange || onChange}
        disabled={!writeAndStronger || props.disabledSwitch}
      />
    </div>
  );
};
export default SwitchInput;