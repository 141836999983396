import React from 'react';
import { tokens } from "@fluentui/react-components";
import { ErrorCircle24Filled } from '@fluentui/react-icons';
import { FlexRow } from './containers';


const ErrorMsg = (props: { message: string, withIcon: boolean }) => {

    return (
        <FlexRow style={{ color: tokens.colorPaletteRedForeground1, fontSize: '12px', alignItems: 'center', }}>
            {props.withIcon ? <ErrorCircle24Filled style={{ height: '12px', marginRight: '5px', width: '12px' }} /> : null}
            {props.message}
        </FlexRow>
    )
}

export default ErrorMsg;

