import { IUnique } from "../base"
import { Option } from "../../components/ui/Select";

export interface Box extends IUnique {
    name: string,
    price: number,
    isPublished: boolean,
    boxType: IBoxesTypeOption
};

export interface BoxPrize extends IUnique {
    name: string,
    imagePath: string,
    amount: number,
    giftBoxId: string,
    prizeType: string,
    chance: number,
    timesCanBeWon: number
};

export type IBoxesTypeOption = 'common' | 'rare' | 'epic' | 'serenity';

export const boxesTypeOptions: Option<IBoxesTypeOption>[] = [
    { id: '1', label: 'Common', value: 'common' },
    { id: '2', label: 'Rare', value: 'rare' },
    { id: '3', label: 'Epic', value: 'epic' },
]


export type IBoxPrizeTypeOption =  'coin' | 'keySlot' | 'key' | 'fastDelivery' | 'freeMonth' | 'device';

export const boxPrizeTypeOptions: Option<IBoxPrizeTypeOption>[] = [
    { id: '1', label: 'Coin', value: 'coin' },
    { id: '2', label: 'Key Slot', value: 'keySlot' },
    { id: '3', label: 'Key', value: 'key' },
    { id: '4', label: 'Fast Delivery', value: 'fastDelivery' },
    { id: '5', label: 'Free Month', value: 'freeMonth' },
    { id: '6', label: 'Device', value: 'device' },
]
