import React, { useEffect, useMemo, useState } from 'react';
import Pagination from '../ui/Pagination';
import { FlexColumn, FlexRow, FlexRowCentered, FlexRowSpaced } from '../common/containers';
import { debounce } from 'lodash';
import {
    DataGridBody,
    DataGridRow,
    DataGrid,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridCell,
    TableCellLayout,
    createTableColumn,
    Input,
    useId,
    LargeTitle,
    Subtitle1,
    Button,
    Dropdown,
    Option,
    Image
} from "@fluentui/react-components";
import { Edit24Regular, Eye24Regular } from "@fluentui/react-icons";
import { useButtonStyles, useTableStyles } from '../../hooks/useStyles';
import { dateFormat } from '../../utils/time-utils';
import LoaderContainer from '../ui/loaders/LoaderContainer';
import { getImageSource } from '../../utils/files-utils';
import usePermission from '../../hooks/usePermission';

interface ITableProps<T> {
    list: T[];
    limit: number;
    columns: columnFields[];
    title: string;
    getItems: Function;
    total: number;
    onEditClick?: Function;
    withAddNew?: boolean;
    onAddNewClick?: Function;
    page?: number;
    loading: boolean;
    sortOptions?: any[];
    selectedSortOptions?: any;
    setSelectedSortOptions?: any;
    sortValue?: string;
    setSortValue?: any;
    withSearch?: boolean;
    disabledAddNew?: boolean;
    dropdownOptions?: any;
    withoutEditItems?: boolean;
    permission?: string;
    withViewRow?: boolean;
}

export type columnFields = {
    fieldName: string;
    columnName: string;
    type?: 'image' | 'date' | 'boolean' | 'dropdownVal';
};


export const getNumOfPages = (total: number, limit: number) => {
    try {
        if (!limit) return 0;
        return Math.ceil(total / limit);
    } catch (err) {
        return 0;
    }
}

const Table = <T extends object>(props: ITableProps<T>) => {

    const {
        list,
        limit,
        columns,
        title,
        getItems,
        total,
        onEditClick,
        withAddNew,
        onAddNewClick,
        page,
        loading,
        sortOptions,
        selectedSortOptions,
        setSelectedSortOptions,
        sortValue,
        setSortValue,
        dropdownOptions,
        withoutEditItems,
        permission,
        withViewRow
    } = props;

    const [search, setSearch] = useState<string>('');
    const searchId = useId("input-search");
    const dropdownId = useId("dropdown");

    const writeAndStronger = usePermission(permission || 'write:>=');

    const tableClasses = useTableStyles();
    const buttonClasses = useButtonStyles();

    useEffect(() => {
        debouncedGetItems(search);
    }, [search, sortValue]);

    const debouncedGetItems = useMemo(() =>
        debounce((query: string) => {
            getItems(1, query);
        }, 300), [getItems])


    const getColumnsArr = (columns: columnFields[]) => {
        let resultColumns: any = [];

        columns.forEach((column, index) => {
            resultColumns = [...resultColumns,

            createTableColumn<T>({
                columnId: column.fieldName,
                renderHeaderCell: () => {
                    return <b>{column.columnName}</b>;
                },
                renderCell: (item) => {
                    const fieldVal = item[column.fieldName as keyof typeof item]

                    if (typeof fieldVal === 'string' ||
                        typeof fieldVal === 'boolean' ||
                        typeof fieldVal === 'number' ||
                        fieldVal === null ||
                        fieldVal === 0) {
                        return (
                            <TableCellLayout>
                                <FlexRow style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    {fieldVal === null ? '-' : fieldVal === 0 ? '0' :
                                        column.type === 'image' ? <Image src={getImageSource(fieldVal as string) || undefined} height={100} /> :
                                            column.type === 'date' ? `${dateFormat(new Date(fieldVal as unknown as Date))}` :
                                                column.type === 'boolean' ? fieldVal ? column.columnName : '-' :
                                                    column.type === 'dropdownVal' && dropdownOptions[column.fieldName] ? dropdownOptions[column.fieldName].find((item: any) => item.value === fieldVal)?.label :
                                                        fieldVal
                                    }
                                    {index === (columns.length - 1) && !withoutEditItems && onEditClick ?
                                        <Edit24Regular onClick={() => onEditClick(item)} style={{ cursor: 'pointer', minWidth: '25px', marginLeft: '10px' }} />
                                        : null
                                    }
                                    {
                                        index === (columns.length - 1) && withViewRow && onEditClick ?
                                            <Eye24Regular onClick={() => onEditClick(item)} style={{ cursor: 'pointer', minWidth: '25px', marginLeft: '10px' }} />
                                            : null
                                    }
                                </FlexRow>
                            </TableCellLayout>
                        );
                    } else {
                        return (
                            <TableCellLayout>
                                <FlexRow style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row-reverse' }}>
                                    {index === (columns.length - 1) && !withoutEditItems && onEditClick ?
                                        <Edit24Regular onClick={() => onEditClick(item)} style={{ cursor: 'pointer', minWidth: '25px', marginLeft: '10px' }} />
                                        : null
                                    }
                                    {
                                        index === (columns.length - 1) && withViewRow && onEditClick ?
                                            <Eye24Regular onClick={() => onEditClick(item)} style={{ cursor: 'pointer', minWidth: '25px', marginLeft: '10px' }} />
                                            : null
                                    }
                                </FlexRow>
                            </TableCellLayout>)
                    }
                },
            }),
            ]
        });

        return resultColumns;
    }

    const onOptionSelect = (ev: any, data: any) => {
        setSelectedSortOptions(data.selectedOptions);
        setSortValue(data.optionText ?? "");
    };

    return (
        <FlexColumn>
            <FlexRowSpaced style={{ margin: ' 0 0 40px 0' }}>
                <FlexRowCentered style={{ gap: '40px' }}>
                    <LargeTitle >{title}</LargeTitle>
                    {writeAndStronger && withAddNew && onAddNewClick ?
                        <Button
                            className={buttonClasses.primary}
                            style={{ padding: "5px 10px" }}
                            appearance="primary"
                            onClick={() => onAddNewClick()}
                            disabled={props.disabledAddNew}
                        >
                            Add New
                        </Button>
                        : null}
                </FlexRowCentered>

                <FlexRow style={{ gap: '40px' }}>
                    {
                        props.withSearch ? <Input
                            value={search || ''}
                            onChange={(e) => { setSearch(e.currentTarget.value) }}
                            type='search'
                            placeholder='Search'
                            appearance="underline"
                            id={searchId}
                            style={{ width: '200px', height: 'fit-content', alignSelf: 'end' }} /> : null
                    }
                    {
                        sortOptions ?
                            <Dropdown
                                aria-labelledby={`${dropdownId}-underline`}
                                placeholder="Sort By"
                                appearance="underline"
                                value={sortValue}
                                selectedOptions={selectedSortOptions}
                                onOptionSelect={onOptionSelect}
                                style={{ position: 'relative', zIndex: '1' }} >
                                {
                                    sortOptions.map((option, index) => {
                                        return (<Option key={option.id} value={option.id} >{option.label}</Option>)
                                    })
                                }
                            </Dropdown>
                            : null
                    }
                </FlexRow>
            </FlexRowSpaced>
            {loading ? <LoaderContainer /> : null}

            <DataGrid
                items={list}
                columns={getColumnsArr(columns)}
                style={{ opacity: loading ? '0.3' : '1' }}>
                <DataGridHeader>
                    <DataGridRow >
                        {({ renderHeaderCell }) => (
                            <DataGridHeaderCell style={{ borderBottom: "solid 1px gray" }} className={tableClasses.tableHeader}>{renderHeaderCell()}</DataGridHeaderCell>
                        )}
                    </DataGridRow>
                </DataGridHeader>
                <DataGridBody<T>>
                    {({ item, rowId }) => (
                        <DataGridRow<T>
                            key={rowId}>
                            {({ renderCell }) => (
                                <DataGridCell>{renderCell(item)}</DataGridCell>
                            )}
                        </DataGridRow>
                    )}
                </DataGridBody>

                {
                    getNumOfPages(total, limit) > 1 ? <Pagination
                        onPageChange={(page: number) => { getItems(page) }}
                        pageIndex={page || 1}
                        pageCount={getNumOfPages(total, limit)}
                        totalPageButtons={limit}
                    /> : null
                }

            </DataGrid>
            {total === 0 ? <Subtitle1 style={{ margin: '30px' }} align='center'>no results</Subtitle1> : null}
        </FlexColumn >
    );
}

export default Table;
