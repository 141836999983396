import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchBoxesFailed, fetchBoxesStart, fetchBoxesSuccess } from './boxes-slice';
import { IGetBoxesParams } from '../../../interfaces/api/boxes';
import boxesApi from '../../api/boxes-api';

function* fetchBoxes(action: PayloadAction<{ params: IGetBoxesParams; }>): any {
    try {
        const { params } = action.payload;
        delete params.page;
        const { data, status, error } = yield call(boxesApi.getBoxes, params); 
        
        if (error) {
            yield put(fetchBoxesFailed(error));
            return;
        }
        
        yield put(fetchBoxesSuccess(data));
    } catch (error: any) {
        yield put(fetchBoxesFailed(error));
    }
}

function* boxesSaga() {
    yield takeLatest(fetchBoxesStart.type, fetchBoxes);
}

export default boxesSaga;