import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetScenesParams } from '../../../interfaces/api/scenes';
import { IError } from '../../../interfaces/base';
import { IScenesState } from '../../../interfaces/store/scenes-interfaces';

const initialState: IScenesState = {
    scenes: [],
    metadata: {
        page: 1,
        total: 0
    },
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanScenesState = (state: IScenesState) => {
    return initialState;
};

const _fetchScenesStart = (state: IScenesState, { payload }: PayloadAction<{ params: IGetScenesParams }>) => {
    state.metadata = {
        page: payload.params.page as number,
        total: state.metadata.total 
    };
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'read';
}

const _fetchScenesSuccess = (state: IScenesState, { payload }: PayloadAction<any>) => {
    state.scenes = payload.data;
    state.metadata = { total: payload.metadata.total, page: state.metadata.page };
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _fetchScenesFailed = (state: IScenesState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const ScenesSlice = createSlice({
    name: 'scenes',
    initialState,
    reducers: {
        cleanScenesState: _cleanScenesState,
        fetchScenesStart: _fetchScenesStart,
        fetchScenesSuccess: _fetchScenesSuccess,
        fetchScenesFailed: _fetchScenesFailed
    }
})

const { actions, reducer } = ScenesSlice;
export const {
    cleanScenesState,
    fetchScenesStart,
    fetchScenesSuccess,
    fetchScenesFailed
} = actions;

export default reducer;
