import React from 'react'
import styled from 'styled-components';

import { Input, useId, InputProps, Textarea, TextareaProps  } from '@fluentui/react-components';
import { Field } from "@fluentui/react-field";
import usePermission from '../../../hooks/usePermission';

const FormInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
`

interface IFormInputProps extends InputProps {
    errorMessage?: string,
    varient?: 'underline',
    style?: any,
    permission?: string
}

const FormInput = (props: IFormInputProps) => {

    const {
        varient,
        errorMessage,
        style,
        permission
    } = props;

    const writeAndStronger = usePermission(permission || 'write:>=');
    const inputId = useId("input");

    return (
        <FormInputContainer className='' style={{ ...style }}>
            <Field
                validationMessage={errorMessage} >
                <Input id={inputId} appearance={varient || 'outline'} {...props} disabled={!writeAndStronger}/>
            </Field>
        </FormInputContainer>
    )
}

export default FormInput;


interface IFormTextareaProps extends TextareaProps  {
    errorMessage?: string,
    varient?: 'underline',
    style?: any,
    permission?: string
}


export const FormTextArea = (props: IFormTextareaProps) => {

    const {
        errorMessage,
        style,
        permission
    } = props;
    const writeAndStronger = usePermission(permission || 'write:>=');

    return (
        <FormInputContainer className='' style={{ ...style }}>
            <Field validationMessage={errorMessage} >
                <Textarea {...props} disabled={!writeAndStronger}/>
            </Field>
        </FormInputContainer>
    )
}
