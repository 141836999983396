import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../interfaces/base';
import { IBoxesState } from '../../../interfaces/store/boxes-interfaces';
import { IGetBoxesParams } from '../../../interfaces/api/boxes';

const initialState: IBoxesState = {
    boxes: [],
    metadata: {
        page: 1,
        total: 0
    },
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanBoxesState = (state: IBoxesState) => {
    return initialState;
};

const _fetchBoxesStart = (state: IBoxesState, { payload }: PayloadAction<{ params: IGetBoxesParams }>) => {
    state.metadata = {
        page: payload.params.page as number,
        total: state.metadata.total 
    };
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'read';
}

const _fetchBoxesSuccess = (state: IBoxesState, { payload }: PayloadAction<any>) => {
    state.boxes = payload.data;
    state.metadata = { total: payload.metadata.total, page: state.metadata.page };
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _fetchBoxesFailed = (state: IBoxesState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const BoxesSlice = createSlice({
    name: 'boxes',
    initialState,
    reducers: {
        cleanBoxesState: _cleanBoxesState,
        fetchBoxesStart: _fetchBoxesStart,
        fetchBoxesSuccess: _fetchBoxesSuccess,
        fetchBoxesFailed: _fetchBoxesFailed
    }
})

const { actions, reducer } = BoxesSlice;
export const {
    cleanBoxesState,
    fetchBoxesStart,
    fetchBoxesSuccess,
    fetchBoxesFailed
} = actions;

export default reducer;
