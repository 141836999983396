import axiosInstance from '../../config/axios';
import ServerRoutes from './routes';
import { getParamsUrl, handleAxiosError } from './utils/common';
import {
    ICreateBoxBody,
    ICreateBoxPrizeBody,
    IGetBoxParams,
    IGetBoxPrizeParams,
    IGetBoxPrizesParams,
    IGetBoxesParams,
    IRemoveBoxPrizeParams,
    IUpdateBoxBody,
    IUpdateBoxPrizeBody
} from '../../interfaces/api/boxes';

const getBoxes = async (params: IGetBoxesParams) => {
    try {
        let url = getParamsUrl(ServerRoutes.boxes.boxes, params);
        const { status, data } = await axiosInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}


const createBox = async (boxInfo: ICreateBoxBody) => {
    try {
        const { status, data } = await axiosInstance.post(ServerRoutes.boxes.createBox, boxInfo);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const updateBox = async (boxInfo: IUpdateBoxBody) => {
    try {
        const { status, data } = await axiosInstance.put(ServerRoutes.boxes.updateBox, boxInfo);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const getBox = async (params: IGetBoxParams) => {
    try {
        let url = getParamsUrl(ServerRoutes.boxes.getBox, params);
        const { status, data } = await axiosInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}


const createBoxPrize = async (boxPrizeInfo: ICreateBoxPrizeBody) => {
    try {
        const { status, data } = await axiosInstance.put(ServerRoutes.boxes.addBoxPrize, boxPrizeInfo);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const getBoxPrizes = async (params: IGetBoxPrizesParams) => {
    try {
        let url = getParamsUrl(ServerRoutes.boxPrize.getBoxPrizesByBox, params);
        const { status, data } = await axiosInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const getBoxPrize = async (params: IGetBoxPrizeParams) => {
    try {
        let url = getParamsUrl(ServerRoutes.boxPrize.getBoxPrize, params);
        const { status, data } = await axiosInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const removeBoxPrize = async (boxPrizeInfo: IRemoveBoxPrizeParams) => {
    try {
        const { status, data } = await axiosInstance.put(ServerRoutes.boxes.removeBoxPrize, boxPrizeInfo);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const publishBox = async (params: { giftBoxId: string, publish: boolean }) => {
    try {
        if (params.publish) {
            const { status, data } = await axiosInstance.put(ServerRoutes.boxes.publishBox, { giftBoxId: params.giftBoxId });
            return { status, data };
        }
        else {
            const { status, data } = await axiosInstance.put(ServerRoutes.boxes.unpublishBox, { giftBoxId: params.giftBoxId });
            return { status, data };
        }
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}


const updateBoxPrize = async (boxPrizeInfo: IUpdateBoxPrizeBody) => {
    try {
        const { status, data } = await axiosInstance.put(ServerRoutes.boxPrize.updateBoxPrize, boxPrizeInfo);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const boxesApi = {
    getBoxes,
    createBox,
    getBox,
    updateBox,
    createBoxPrize,
    getBoxPrizes,
    getBoxPrize,
    removeBoxPrize,
    updateBoxPrize,
    publishBox

}

export default boxesApi;