import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Routes from '../../routes';
import { TopNavFullHeight } from './StyledComponents';
import {
    MenuList,
    MenuDivider,
    MenuItem,
    Text
} from "@fluentui/react-components";


interface IProfileMenuProps {
    user: any;
    callback: React.MouseEventHandler<HTMLDivElement>
};
export const ProfileMenu = React.memo(({ user, callback }: IProfileMenuProps) => {
    const navigate = useNavigate();

    return (
        <ProfileMenuContainer>
            <MenuList>
                <MenuItem>{user.fullName}</MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => { navigate(Routes.Auth.logout) }}>
                    <Text weight='bold'>Logout</Text>
                </MenuItem>
            </MenuList>
        </ProfileMenuContainer>
    );
});


const menuSlide = keyframes`
	from {
		opacity: 0.1;
	}
	to {
		opacity: 1;
	}
`;

const ProfileMenuContainer = styled.div`
	position: fixed;
	position: absolute;
	right: 10px;
	top: ${`${TopNavFullHeight}px`};
    min-width: 200px;
	background: #FFFFFF;
    padding: 5px 0;
	border: 1px solid #C6C6C6;
    border-radius: 4px;
	box-shadow: 0 3px 14px 4px rgba(0, 0, 0, 0.14);
	z-index: 5;
	overflow: hidden;
	animation: ${menuSlide} 0.2s linear;
`;


export default ProfileMenu