import React, { useEffect, useCallback, useState } from 'react';
import { FlexColumn } from '../../common/containers';
import Table, { columnFields } from '../../table/Table';
import { SceneItem } from '../../../interfaces/models/scene';
import { useNavigate } from 'react-router-dom';
import Routes from '../../../routes';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { IGetSceneItemsParams } from '../../../interfaces/api/scenes';
import { fetchSceneItemsStart } from '../../../store/features/edit-scene/edit-scene-slice';



const columns: columnFields[] = [
    {
        fieldName: "serialNumber",
        columnName: "Serial Number",
    },
    {
        fieldName: "name",
        columnName: "Name",
    },
    {
        fieldName: "price",
        columnName: "Price",
    },
    {
        fieldName: "deliveryTime",
        columnName: "Delivery time",
    },
    {
        fieldName: "dependsOn",
        columnName: "Depends",
    },
    {
        fieldName: "imagePath",
        columnName: "Image",
        type: "image",
    },
    {
        fieldName: "thumbnailPath",
        columnName: "Thumbnail Image",
        type: "image",
    },
    {
        fieldName: "createdAt",
        columnName: "Created At",
        type: "date",
    },
]

function compare(a: SceneItem, b: SceneItem) {
    if (a.serialNumber < b.serialNumber) {
        return -1;
    }
    if (a.serialNumber > b.serialNumber) {
        return 1;
    }
    return 0;
}

const ItemsTable = (props: { isEdit: boolean, sceneId: string }) => {

    const sceneItems = useAppSelector(state => state.editScene.sceneItems);
    const loading = useAppSelector(state => state.editScene.loading);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [sortedSceneItems, setSortedSceneItems] = useState<SceneItem[]>([]);

    const onEditClick = (item: SceneItem) => { navigate(Routes.Scene.sceneItemPage.replace(':id', item._id)) }
    const onAddNewClick = () => { navigate(Routes.Scene.sceneItemPage.replace(':id', 'add-new')) }

    const getSceneItems = useCallback(() => {

        let params: IGetSceneItemsParams = {
            sceneId: props.sceneId
        }

        if (props.sceneId) dispatch(fetchSceneItemsStart({ params }))
    }, [dispatch]);


    useEffect(() => {
        setSortedSceneItems([...sceneItems].sort(compare))
    }, [sceneItems]);


    return (
        <FlexColumn >
            <Table<SceneItem>
                list={sortedSceneItems}
                limit={100}
                columns={columns}
                title={'Items'}
                getItems={getSceneItems}
                total={sceneItems?.length}
                onEditClick={onEditClick}
                loading={loading}
                withAddNew={true}
                onAddNewClick={onAddNewClick}
                disabledAddNew={!props.isEdit}
                withSearch={false}
            />
        </FlexColumn >
    )
}

export default ItemsTable;

