import styled from 'styled-components';
import { FlexColumn, FlexRowWrapped } from '../common/containers';
import { tokens } from '@fluentui/react-components';


interface StyledGridLibraryProps {
    isChosen: boolean;
}


export const GridLibraryImgContainer = styled(FlexColumn) <StyledGridLibraryProps>`
    width: 150px;
    height: 150px;
    max-width: 150px;
    max-height: 150px;
    position: relative;
    border: ${p => p.isChosen ? '4px solid ' : 'none'};
    border-color: ${tokens.colorBrandBackground};
    border-radius: 4px;
    box-sizing: border-box;
`;

export const GridLibraryImgName = styled(FlexColumn)`
    position: absolute;
    color: white;
    text-shadow: 1px 1px #666666;
    background: ${tokens.colorNeutralForeground3};
    padding: 0 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(100% - 8px);
`;

export const GridLibraryWrapped = styled(FlexRowWrapped)`
    gap: 10px;
    justify-content: center;
    max-height: 50vh;
    overflow: auto
`;