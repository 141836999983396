import React, { useCallback, useEffect, useState } from 'react';
import { Button, LargeTitle, Subtitle2, Label, tokens, Image, Dropdown, useId, Option } from '@fluentui/react-components';
import { IosArrowLtr24Filled } from "@fluentui/react-icons";
import { useParams, useNavigate } from 'react-router-dom';
import FormInput from '../ui/inputs/FormInput';
import LoaderContainer from '../ui/loaders/LoaderContainer';
import { useForm } from '../../hooks/useForm';
import { useButtonStyles, useIconsStyles } from '../../hooks/useStyles';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import Routes from '../../routes';
import { FormContainer, FormInputContainer, TitleContainer, FlexColumn, FlexRowSpaced, FlexRowWrapped, FlexRow } from '../common/containers';
import ErrorMsg from '../common/ErrorMsg';
import ChooseLibraryImg from '../choose-library-img/ChooseLibraryImg';
import { getImageSource } from '../../utils/files-utils';
import { cleanEditLevelState, createLevelStart, getLevelStart, updateLevelStart } from '../../store/features/edit-level/edit-level-slice';
import { ICreateLevelBody, IUpdateLevelBody } from '../../interfaces/api/levels';
import { Level, levelTypeOptions } from '../../interfaces/models/level';
import SwitchInput from '../common/Switch';
import { getInitialFormValues, getValidationsObj } from '../../utils/forms-utils';
import usePermission from '../../hooks/usePermission';


const LevelPage = (props: { metadata: any }) => {

    const { id } = useParams();
    const currLevel = useAppSelector(state => state.editLevel.level);
    const { operation, requestState, loading } = useAppSelector(state => state.editLevel);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const iconsClasses = useIconsStyles();

    useEffect(() => {
        dispatch(cleanEditLevelState());
        if (id != 'add-new') dispatch(getLevelStart({ levelRewardId: id || '' }))
    }, []);


    useEffect(() => {
        if (requestState === 'finish' && (['delete', 'update', 'create'].includes(operation || ''))) {
            navigate(Routes.Dashboard.levels)
        }
    }, [operation, requestState]);


    return (
        <FlexColumn style={{ padding: ' 0 3rem' }}>
            {loading ? <LoaderContainer /> : null}
            <FlexRowSpaced>
                <TitleContainer>
                    <IosArrowLtr24Filled
                        onClick={() => {
                            navigate(Routes.Dashboard.levels);
                        }}
                        className={iconsClasses.loginArrow}
                    />
                    <LargeTitle>Level</LargeTitle>
                </TitleContainer>

            </FlexRowSpaced>
            {
                id === 'add-new' ?
                    <LevelPageForm isEdit={false} level={null} metadata={props.metadata.createForm} /> :
                    currLevel ?
                        <LevelPageForm isEdit={true} level={currLevel} metadata={props.metadata.editForm} /> : null
            }

        </FlexColumn>
    )
}

const LevelPageForm = (props: { level: Level | null, metadata: any, isEdit: boolean }) => {

    const {
        level,
        metadata,
        isEdit,
    } = props;

    const loading = useAppSelector(state => state.editLevel.loading);
    const [selectedSortOptions, setSelectedSortOptions] = useState<any[]>([]);
    const [dropdownValue, setDropdownValue] = useState(levelTypeOptions.find((item) => item.value === level?.other)?.label || null);
    const [showDropdown, setShowDropdown] = useState(level?.other ? true : false);
    const writeAndStronger = usePermission('write:>=');

    const dispatch = useAppDispatch();

    const buttonClasses = useButtonStyles();
    const dropdownId = useId("dropdown");

    const onEditSubmit = (data: Level) => {
        if (isEdit) {
            const params: IUpdateLevelBody = {
                id: data._id,
                data: {
                    level: data.level,
                    coins: data.coins,
                    other: data.other,
                    otherAmount: data.otherAmount
                }
            }
            dispatch(updateLevelStart(params))
        } else {
            dispatch(createLevelStart(editLevel as ICreateLevelBody));
        }
    };

    const { handleSubmit, handleChange, handleCustomChange, data: editLevel, errors } = useForm<any>({
        initialValues: getInitialFormValues(level),
        validations: getValidationsObj(metadata),
        onSubmit: () => {
            if (onEditSubmit) {
                onEditSubmit(editLevel)
            }
        },
    });

    const onEditClick = () => {
        handleSubmit();
    }

    const onOptionSelect = (ev: any, data: any, key: string) => {
        const typeVal = levelTypeOptions.find((item) => item.id === data.selectedOptions[0])?.value;
        handleCustomChange(key, typeVal || '')
        setSelectedSortOptions(data.selectedOptions);
        setDropdownValue(data.optionText ?? "");
    };

    const onSwitchChange = useCallback((ev: any, key: any) => {
        setShowDropdown(ev.currentTarget.checked);
        // handleCustomChange(key, '')
        if (!ev.currentTarget.checked) setDropdownValue("")
    }, [setShowDropdown]);

    return (
        <FlexColumn >
            <FormContainer style={{ opacity: loading ? '0.3' : '1' }}>
                {metadata != null ?
                    <>
                        <FlexRow>
                            <FlexRowWrapped style={{ gap: '40px' }}>
                                {Object.keys(metadata).map((key, index) => {
                                    return (
                                        metadata[key] && metadata[key].type !== 'image' ?
                                            <FormInputContainer key={index}>
                                                <FlexRow style={{ position: 'relative'}}>
                                                    <Label>
                                                        {metadata[key].fieldName}:
                                                        {metadata[key].required ?
                                                            <>&nbsp; <Subtitle2 style={{ color: tokens.colorPaletteCranberryBorderActive }}>*</Subtitle2> </>
                                                            : null}
                                                    </Label>
                                                    <FlexColumn style={{ position: 'absolute' , left: '45px', bottom:'-9px'}}>
                                                        {metadata[key].withSwitch ? <SwitchInput checked={showDropdown} setChecked={setShowDropdown} onChange={(ev: any) => onSwitchChange(ev, key)} label={''}></SwitchInput> : null}
                                                    </FlexColumn>
                                                </FlexRow>

                                                {metadata[key].type === 'dropdown' ?
                                                    <>
                                                        {
                                                            showDropdown ? <>
                                                                <Dropdown
                                                                    aria-labelledby={`${dropdownId}-underline`}
                                                                    placeholder={metadata[key].fieldName}
                                                                    value={dropdownValue || ""}
                                                                    selectedOptions={selectedSortOptions}
                                                                    onOptionSelect={(ev, data) => onOptionSelect(ev, data, key)}
                                                                    style={{ position: 'relative', zIndex: '1', marginTop: '10px', pointerEvents: !metadata[key].editable ? 'none' : 'unset' }} >
                                                                    {
                                                                        levelTypeOptions.map((option, index) => {
                                                                            return (<Option key={option.id} value={option.id} >{option.label}</Option>)
                                                                        })
                                                                    }
                                                                </Dropdown>
                                                                {errors[key] ? <ErrorMsg message={errors[key] || ''} withIcon={true} /> : null}
                                                            </> : null
                                                        }
                                                    </>
                                                    : <FormInput
                                                        value={editLevel[key] || ''}
                                                        style={{ marginTop: '5px', pointerEvents: !metadata[key].editable ? 'none' : 'unset' }}
                                                        placeholder={metadata[key].fieldName}
                                                        onChange={handleChange(key)}
                                                        errorMessage={errors[key]}
                                                        type={metadata[key].type || 'string'}
                                                    />}
                                            </FormInputContainer> : null
                                    );
                                })}

                            </FlexRowWrapped>
                        </FlexRow>


                        <FlexRowWrapped>
                            {Object.keys(metadata).map((key, index) => {
                                return (
                                    metadata[key] && metadata[key].type === 'image' ?
                                        <FormInputContainer style={{ width: 'unset' }} key={index}>
                                            <Label>
                                                {metadata[key].fieldName}:
                                                {metadata[key].required ?
                                                    <>&nbsp; <Subtitle2 style={{ color: tokens.colorPaletteCranberryBorderActive }}>*</Subtitle2> </>
                                                    : null}
                                            </Label>
                                            {
                                                !metadata[key].editable || editLevel[key] ?
                                                    <>
                                                        <Image width={150} src={getImageSource(editLevel[key]) || undefined} />
                                                        <ChooseLibraryImg onChosenImg={(img: string) => handleCustomChange(key, img)} btnTitle={'Change Image'} />
                                                    </>
                                                    :
                                                    <>
                                                        <ChooseLibraryImg onChosenImg={(img: string) => handleCustomChange(key, img)} />
                                                        {errors[key] ? <ErrorMsg message={errors[key] || ''} withIcon={true} /> : null}
                                                    </>
                                            }
                                        </FormInputContainer> : null
                                );
                            })}
                        </FlexRowWrapped>
                    </>
                    : null
                }
            </FormContainer>

            {writeAndStronger ? <Button
                className={buttonClasses.primary}
                appearance="primary"
                style={{ width: "250px", margin: '20px 0' }}
                onClick={onEditClick}>
                Save
            </Button> : null}
        </FlexColumn >
    )
}

export default LevelPage;

