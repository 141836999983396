import styled from 'styled-components';

const LayoutContainer = styled.div`
    width: 100%;
    padding: 1rem;
    overflow-x: auto;
    overflow-y: auto;
`;

const PageLayout = ({ children }: { children: any; }) => {
    return (
        <LayoutContainer>
            {children}
        </LayoutContainer>
    )
}

export default PageLayout;