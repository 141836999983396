import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICreateFirmwareBody, IDeleteFirmwareParams, IGetFirmwaresParams, IPublishFirmwareBody } from '../../../interfaces/api/fota';
import { IError } from '../../../interfaces/base';
import { IFotaState } from '../../../interfaces/store/fota-interfaces';

const initialState: IFotaState = {
    firmwares: {
        firmwaresArr: [],
        metadata: {
            page: 1,
            total: 0,
        }
    },
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanFotaState = (state: IFotaState) => {
    return initialState;
};

const _startLoader = (state: IFotaState) => {
    state.loading = true;
}

const _fetchFirmwaresStart = (state: IFotaState, { payload }: PayloadAction<{ params: IGetFirmwaresParams }>) => {
    state.firmwares.metadata = {
        page: payload.params.page as number,
        total: state.firmwares.metadata.total
    };
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'read';
}

const _fetchFirmwaresSuccess = (state: IFotaState, { payload }: PayloadAction<any>) => {
    state.firmwares.firmwaresArr = payload.data;
    state.firmwares.metadata = { total: payload.metadata.total, page: state.firmwares.metadata.page };
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _fetchFirmwaresFailed = (state: IFotaState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const _publishFirmwareStart = (state: IFotaState, { payload }: PayloadAction<IPublishFirmwareBody>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'update';
}

const _publishFirmwareSuccess = (state: IFotaState, { payload }: PayloadAction<any>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _publishFirmwareFailed = (state: IFotaState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const _createFirmwareStart = (state: IFotaState, { payload }: PayloadAction<ICreateFirmwareBody>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'create';
}

const _createFirmwareSuccess = (state: IFotaState, { payload }: PayloadAction<any>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _createFirmwareFailed = (state: IFotaState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const _deleteFirmwareStart = (state: IFotaState, { payload }: PayloadAction<IDeleteFirmwareParams>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'delete';
}

const _deleteFirmwareSuccess = (state: IFotaState, { payload }: PayloadAction<any>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _deleteFirmwareFailed = (state: IFotaState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}


const FotaSlice = createSlice({
    name: 'fota',
    initialState,
    reducers: {
        cleanFotaState: _cleanFotaState,
        publishFirmwareStart: _publishFirmwareStart,
        publishFirmwareSuccess: _publishFirmwareSuccess,
        publishFirmwareFailed: _publishFirmwareFailed,
        fetchFirmwaresStart: _fetchFirmwaresStart,
        fetchFirmwaresSuccess: _fetchFirmwaresSuccess,
        fetchFirmwaresFailed: _fetchFirmwaresFailed,
        createFirmwareStart: _createFirmwareStart, 
        createFirmwareSuccess: _createFirmwareSuccess, 
        createFirmwareFailed: _createFirmwareFailed, 
        deleteFirmwareStart: _deleteFirmwareStart, 
        deleteFirmwareSuccess: _deleteFirmwareSuccess,
        deleteFirmwareFailed: _deleteFirmwareFailed, 
        startLoader: _startLoader, 
    }
})

const { actions, reducer } = FotaSlice;
export const {
    cleanFotaState,
    publishFirmwareStart,
    publishFirmwareSuccess,
    publishFirmwareFailed,
    fetchFirmwaresStart,
    fetchFirmwaresSuccess,
    fetchFirmwaresFailed,
    createFirmwareStart,
    createFirmwareSuccess,
    createFirmwareFailed,
    deleteFirmwareStart,
    deleteFirmwareSuccess,
    deleteFirmwareFailed,
    startLoader

} = actions;

export default reducer;
