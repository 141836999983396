import React from 'react';
import LoadingLogo from '../../ui/LoadingLogo';
import { FlexColumnCentered } from '../../common/containers';


const LoaderContainer = (props: { containerStyles?: React.CSSProperties }) => {
    return (
        <FlexColumnCentered style={{ 
            position:'absolute',
            inset: 0,
            height: '100%', ...props.containerStyles }}>
            <LoadingLogo darkLogo={true} />
        </FlexColumnCentered>
    )
}

export default LoaderContainer;
