import React, { useCallback, useState } from "react";
import AlertPopup from "../common/AlertPopup";
import Switch from "../common/Switch";
import { FlexColumn } from "../common/containers";
import styled from "styled-components";


export interface ISwitchContainer {
  checked: boolean;
  withUnpublish: boolean;
}

export const SwitchContainer = styled(FlexColumn)`
    opacity: ${(props: ISwitchContainer) => props.checked && !props.withUnpublish ? 0.5 : 1} ;
    pointer-events: ${(props: ISwitchContainer) => props.checked && !props.withUnpublish ? "none" : "auto"} ;
`;


export const PublishSwitch = (props: {
  disabledSwitch?: boolean,
  checked: boolean,
  setChecked: any,
  withAlert: boolean,
  withUnpublish: boolean,
  onChange?: any, 
}) => {

  const [showPublishModal, setShowPublishModal] = useState(false);
  const checkedString = props.checked ? "Published" : "Publish";

  const openPublishPopup = () => {
    setShowPublishModal(true)
  }

  const onChange = useCallback((ev: any) => {
    props.setChecked(ev.currentTarget.checked);
    setShowPublishModal(false);
  }, [props.setChecked]);


  return (
    <SwitchContainer checked={props.checked} withUnpublish={props.withUnpublish}>
      {props.withAlert ?
        <AlertPopup
          title={"Are you sure you want to publish?"}
          subText={"Once you publish you can not undo it"}
          open={showPublishModal}
          onOpenChange={setShowPublishModal}
          onActionClick={props.onChange || onChange}
          actionTitle={"Publish"} /> : null}

      <Switch
        label={`${checkedString}`}
        checked={props.checked}
        disabledSwitch={props.disabledSwitch}
        setChecked={props.setChecked}
        onChange={props.withAlert ? openPublishPopup : props.onChange || onChange} />
    </SwitchContainer>
  );
};
export default PublishSwitch;